import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

type DragUploadFileProps = {
    handlerLeave: Function;
    handlerUpload: Function;
};

export default function DragUploadFile({ handlerLeave, handlerUpload }: DragUploadFileProps) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDragLeave: () => {
            handlerLeave();
        },
    });

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            handlerUpload(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <Box position="absolute" w="100%" h="100%">
            <div {...getRootProps({ className: 'dropzone', style: {height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "gray"} })}>
                <input {...getInputProps()} />
                <Box w="80%" py="5px" textAlign="center" borderColor="black" borderWidth="1px">
                    <p>Drag 'n' drop some files here</p>
                </Box>
            </div>
        </Box>
    );
}