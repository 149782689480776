import React, { useContext, useEffect, useState } from 'react';
import { Container, Text } from '@chakra-ui/layout';
import { HeaderLogo } from '../../components/header.logo';
import { Input, InputGroup } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useCounter } from '@chakra-ui/counter';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@chakra-ui/select';
import moment from 'moment';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { useRegisterTokenValidator } from './hooks';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '../../app/routes';
import { InvalidRegisterTokenSupportDialog } from '../../components/invalid-register-token-support.dialog';
import { useDisclosure } from '@chakra-ui/hooks';
import { Textarea } from '@chakra-ui/textarea';
import { useEffectOnce } from 'react-use';

export const Register = () => {

    const [ children, setChildren ] = useState<Array<any>>([]);
    const [ showDialog, setShowDialog ] = useState(false);

    const [ errors, setErrors ] = useState<any>({});
    const { register, handleSubmit, watch, setValue } = useForm();
    const { state, appService }: AppContextInterface = useContext<AppContextInterface>(AppContext);
    const history = useHistory();

    const query: any = new URLSearchParams(window.location.search);


    useRegisterTokenValidator( { appService, token: query.get('token'), showDialog: setShowDialog } );

    const setValueToField = (value: any) => {
        if(value === "1") return true; 
        if(value === "0") return false; 
        return value; 
    }
  
    const onSubmit = async (data: any) => {
        data.children = children;
        data.token =  query.get('token');
        let newData =  Object.assign({}, data);
        let arrFields: Array<string> = ["has_allergies", "has_meds", "has_past_medical_history", "has_past_surgical_history", "has_fmd", "has_pharma"];
        arrFields.map(item => {
            newData.patient[item] = setValueToField(newData.patient[item]); 
            newData.spouse[item] = setValueToField(newData.spouse[item]); 
            
        });
        newData.patient.has_spouse = !!parseInt(newData.patient.has_spouse);
   
        const registered = await appService.register(newData);
        if ( registered === true ) {
            history.push(ROUTE_PATH.REGISTER_COMPLETE);
        }     
    }
    
    const watchFields = watch(["patient.has_allergies", "patient.has_meds", "patient.has_past_medical_history", "patient.has_past_surgical_history", "spouse.has_meds", "spouse.has_allergies", "spouse.has_past_surgical_history", "spouse.has_past_medical_history"]);

    const watchSection = watch(["patient.has_fmd", "patient.has_pharma", "spouse.has_fmd", "spouse.has_pharma", "patient.has_spouse"]); 

    const spouseFields = ["hin", "hin_expiry", "last_name", "mobile_number", "type", "dob", "email", "first_name", "gender"]; 

    const provinces = {
        quebec: 'Quebec',
        ontario: 'Ontario',
        nunavut: 'Nunavut',
        alberta: 'Alberta',
        saskatchewan: 'Saskatchewan',
        manitoba: 'Manitoba',
        new_brunswick: 'New Brunswick',
        british_columbia: 'British Columbia',
        prince_edward_island: 'Prince Edward Island',
        nova_scotia: 'Nova Scotia',
        newfoundland_labrador: 'Newfoundland and Labrador',
        northwest_territories: 'Northwest Territories',
        yukon: 'Yukon'
    }
    const { onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const {t} = useTranslation();

    const arrFields = ["has_allergies", "has_meds", "has_past_medical_history", "has_past_surgical_history", "has_fmd", "has_pharma", "has_fmd", "has_pharma"];

    const removeChild = ( idx: number ) => {
        // const newArr = [];
        setChildren(children.filter((c,i) => i !== idx));
    }

    useEffectOnce(() => {
    // @ts-ignore
      spouseFields.forEach((item: string) => setValue(`spouse.${item}` as const, '')); 
    }); 



    return (
        <form onSubmit={handleSubmit(onSubmit, errors => setErrors(errors))}>
        <Container
            mt={2 * window.devicePixelRatio}
            mb="auto"
            position='relative'
            width="100%"
            height="100vh"
            maxWidth='auto'
            backgroundColor="#FFFFFF"
            display="flex"
            flexDirection="column"
            paddingBottom="30px"
            paddingTop="20px"
            justifyContent="flex-start"
            // alignItems="center"
            //overflow="scroll"
            >
           <InvalidRegisterTokenSupportDialog isOpen={showDialog} onClose={onClose} cancelRef={cancelRef} />   
           
            <HeaderLogo showLanguage={false} />
            <Text fontFamily={'Playfair Display'} fontSize="md" mt={3 * window.devicePixelRatio}>
                {t('registerForm.title')}
            </Text>
            <Text color={ 
                // state.app.isErrored === true ? 
                // '#E27878' : 
                'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                {t('registerForm.subTitle1')}
            </Text>
            <Text color={ 
                // state.app.isErrored === true ? 
                // '#E27878' : 
                'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                {t('registerForm.subTitle2')}
            </Text>
            <Text color={ 
                // state.app.isErrored === true ? 
                // '#E27878' : 
                'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                {t('registerForm.subTitle3')}
            </Text>
            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                1. {t('registerForm.ptSection.title')} 
            </Text>
            <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                <Input 
                    backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.company_name", { required: false })} />
                <Text color={ 
                    // state.app.isErrored === true ? 
                    // '#E27878' : 
                    'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                    {t('registerForm.ptSection.companyName')} 
                </Text>
            </InputGroup>
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input 
                        borderColor={ errors?.patient?.last_name && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.last_name", { required: true })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.lastName')} 
                    </Text>
                    { errors?.patient?.last_name && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient last name' })}</small> }
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input 
                        borderColor={ errors?.patient?.first_name && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.first_name", { required: true })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.firstName')} 
                    </Text>
                    { errors?.patient?.last_name && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient first name' })}</small> }
                </InputGroup>
            </Container>
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px">
                    <Input 
                        borderColor={ errors?.patient?.mobile_number && '#E27878!important' }
                        backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.mobile_number", { required: true })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.ptSection.mobileNumber')} 
                    </Text>
                    { errors?.patient?.last_name && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient mobile number' })}</small> }
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                {/* TODO address field. */}
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.address", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.ptSection.address')} 
                    </Text>
                </InputGroup>
            </Container>
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                {/* TODO city field. */}
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.city", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.ptSection.city')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.province && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.province')} {...register("patient.province", { required: true })}>

                        {Object.entries(provinces).map(item => <option value={`${item[1]}`}>{t(`registerForm.provinces.${item[0]}`)}</option> )}
                 
                    </Select>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.province')}
                    </Text>
                    { errors?.patient?.province && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient province' })}</small> }
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                {/* TODO postal code. */}
                   <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="text" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.postal_code", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.ptSection.postalCode')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="text" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.hin", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicareNumber')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="month" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.hin_expiry", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicareExpiration')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                {/* TODO dob. */}
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" type="email" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.email", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.ptSection.email')}
                    </Text>
                </InputGroup>
            </Container>
            
             <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.gender && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.sex')} {...register("patient.gender", { required: true })}>
                        <option value="male">{t('registerForm.gender.male')}</option>
                        <option value="female">{t('registerForm.gender.female')}</option>
                    </Select>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.sex')}
                    </Text>
                    { errors?.patient?.last_name && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient gender' })}</small> }
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input 
                        borderColor={ errors?.patient?.date_of_birth && '#E27878!important' }
                        placeholder={t('registerForm.dob')}
                        backgroundColor="#fafafa" type="date" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.date_of_birth", { required: true })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.dob')}
                    </Text>
                    { errors?.patient?.last_name && <small style={{ color: '#E27878' }}>*{t('validation.required', { field: 'Patient date of birth' })}</small> }
                </InputGroup>
            </Container>


            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.has_allergies && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.allergiesQuestion')} {...register("patient.has_allergies", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[0] === "1" && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.allergies", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.allergiesQuestion')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                  <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.has_meds && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.medicationsQuestion')} {...register("patient.has_meds", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[1] === "1" && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.meds", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicationsQuestion')}
                    </Text>
                </InputGroup>
            </Container>


            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                 <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                       borderColor={ errors?.patient?.has_past_medical_history && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.healthIssuesQuestion')} {...register("patient.has_past_medical_history", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[2] === '1' && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.past_medical_history", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.healthIssuesQuestion')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                  <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                      <Select 
                        borderColor={ errors?.patient?.has_past_surgical_history && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.proceduresQuestion')} {...register("patient.has_past_surgical_history", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[3] === "1"  && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.past_surgical_history", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.proceduresQuestion')}
                    </Text>
                </InputGroup>
            </Container>

            
            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                {t('registerForm.fdSection.title')}
            </Text>
           
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.has_fmd && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.fdSection.selectText')} {...register("patient.has_fmd", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.fdSection.subTitle')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select>
                    <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.fdSection.selectText')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container>

            {watchSection[0] === "1" && <> <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_last_name", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.lastName')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_first_name", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.firstName')} 
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_phone_name", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.phoneNumber')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_fax_number", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.faxNumber')} 
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_address", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.address')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.fmd_email", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.email2')} 
                    </Text>
                </InputGroup>
            </Container>
           </> }

            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                1.2. {t('registerForm.pharmacy.title')} 
            </Text>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                       borderColor={ errors?.patient?.has_pharma && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none"  borderRadius="0" placeholder={t('registerForm.pharmacy.selectText')}  {...register("patient.has_pharma", { required: true })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.fdSection.subTitle')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select>
                    <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.pharmacy.selectText')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container>

           {watchSection[1] === "1" && <><Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.pharma_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.name')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.pharma_phone_number", { required: false })} />
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.phoneNumber')} 
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.pharma_fax_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.faxNumber')} 
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.pharma_address", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.address')} 
                    </Text>
                </InputGroup>
            </Container>
            
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("patient.pharma_email", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.email2')} 
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container> </>}
            
            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                2. {t('registerForm.family.title')} 
            </Text>
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        borderColor={ errors?.patient?.has_spouse && '#E27878!important' }
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none"  borderRadius="0" placeholder={t('registerForm.textSpouse')}  {...register("patient.has_spouse", { required: true })}>
                        <option value="1">{
                          t('registerForm.fdSection.yes')
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select>
                    <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.textSpouse')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container>

           {watchSection[4] === "1" && <> <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.last_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.lastName')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.first_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.firstName')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.mobile_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.mobileNumber')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.email", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.email2')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="text" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.hin", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicareNumber')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="month" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.hin_expiry", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicareExpiration')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="date" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.dob", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.dob')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup  display='flex' flexDirection="column" mt="5px" >
                    <Select backgroundColor="#fafafa"  borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.sex')} {...register("spouse.gender", { required: false })}>
                        <option value="male">{t('registerForm.gender.male')}</option>
                        <option value="female">{t('registerForm.gender.female')}</option>
                    </Select>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.sex')}
                    </Text>
                </InputGroup>
            </Container>



            

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                 <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                     <Select 
                        
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.medicationsQuestion')} {...register("spouse.has_meds", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[4] === "1" && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.meds", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.medicationsQuestion')}
                    </Text>
                </InputGroup>



                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.allergiesQuestion')} {...register("spouse.has_allergies", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[5] === '1' && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.allergies", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.allergiesQuestion')}
                    </Text>
                </InputGroup>
            </Container>


            <Container display='flex' padding="0" margin="0" maxWidth="auto">
               <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.proceduresQuestion')} {...register("spouse.has_past_surgical_history", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[6] === "1" && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.past_surgical_history", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.proceduresQuestion')}
                    </Text>
                </InputGroup>


                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.healthIssuesQuestion')}  {...register("spouse.has_past_medical_history", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.ptRecomendation')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select> 
  
                  {watchFields[7] === "1" && <Textarea backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.past_medical_history", { required: false })} />}
                   <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.healthIssuesQuestion')}
                    </Text>
                </InputGroup>
            </Container></>}


       







            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                {t('registerForm.spouseFmd')}
            </Text>
            
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                        backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.textSpouseFamilyDoctor')} {...register("spouse.has_fmd", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.fdSection.subTitle')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select>
                    <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.textSpouseFamilyDoctor')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container>

           {watchSection[2] === "1" && <> <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.fmd_last_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.lastName')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.fmd_first_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.firstName')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.fmd_phone_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.phoneNumber')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.fmd_fax_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.faxNumber')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.fmd_address", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.address')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.fmd_email", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.email2')}
                    </Text>
                </InputGroup>
            </Container></>}
            
            <Text fontFamily={'Playfair Display'} backgroundColor="lightgray" fontSize="md" mt={3 * window.devicePixelRatio}>
                {t('registerForm.spousePharma')}
            </Text>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Select 
                     backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" placeholder={t('registerForm.textSpousePharmacy')} {...register("spouse.has_pharma", { required: false })}>
                        <option value="1">{
                         `${t('registerForm.fdSection.yes')} (${t('registerForm.fdSection.subTitle')})`
                        }</option>
                        <option value="0">{t('registerForm.fdSection.no')}</option>
                    </Select>
                    <Text color={ 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.textSpousePharmacy')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container>

           {watchSection[3] === "1" && <><Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.pharma_name", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.name')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.pharma_phone_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.phoneNumber')}
                    </Text>
                </InputGroup>
            </Container>

            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" mb="15px">
                    <Input backgroundColor="#fafafa" type="number" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.pharma_fax_number", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.faxNumber')}
                    </Text>
                </InputGroup>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0" {...register("spouse.pharma_address", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.address')}
                    </Text>
                </InputGroup>
            </Container>
            
            <Container display='flex' padding="0" margin="0" maxWidth="auto">
                <InputGroup display='flex' flexDirection="column" mt="5px" >
                    <Input backgroundColor="#fafafa" type="email" borderTop="none" borderLeft="none" borderRight="none" borderRadius="0"  {...register("spouse.pharma_email", { required: false })}/>
                    <Text color={ 
                        // state.app.isErrored === true ? 
                        // '#E27878' : 
                        'primary'} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                        {t('registerForm.email2')}
                    </Text>
                </InputGroup>
                <div style={{ width: '100%' }}>&nbsp;&nbsp;&nbsp;</div>
            </Container></>}

            <Button type="submit" mt="10px" width="100%" padding="20px" >{t('registerForm.submit')}</Button>
        </Container>
        </form>

    );
}
