import { extendTheme } from "@chakra-ui/react"
import Button from './Button';
import Input from './Input';

const theme = ( multiplier: number ) => extendTheme({
  components: {
    Button,
    Input
  },
  // fontSizes: {
    // xs: `${multiplier * 5 * window.devicePixelRatio}px`,
    // sm: `${multiplier * 7 * window.devicePixelRatio}px`,
    // md: `${multiplier * 8 * window.devicePixelRatio}px`,
    // lg: `${multiplier * 9 * window.devicePixelRatio}px`,
    // xl: `${multiplier * 10 * window.devicePixelRatio}px`,
    // "2xl": `${multiplier * 12 * window.devicePixelRatio}px`,
  //   "3xl": "1.875rem",
  //   "4xl": "2.25rem",
  //   "5xl": "3rem",
  //   "6xl": "3.75rem",
  //   "7xl": "4.5rem",
  //   "8xl": "6rem",
  //   "9xl": "8rem",
  // },
  // sizes: {
  //   sm: {
  //     fontSize: `${multiplier * 4 * window.devicePixelRatio}rem`,
  //     padding: `${multiplier * 1 * window.devicePixelRatio}px`,
  //   },
  //   md: {
  //     fontSize: `${multiplier * 8 * window.devicePixelRatio}px`,
  //     padding: `${multiplier * 2 * window.devicePixelRatio}px`,
  //   },
  // }
});

export default theme;