import { AppType } from "./app.type";
import { AppActions } from './app.action';
import { ActionInterface } from "../utils";

export const appReducer = ( state: AppType, action: ActionInterface ) => {
    switch( action.type ) {
        case AppActions.APP_ERROR:
        case AppActions.APP_SUCCESS:
            return { ...state, ...action.payload };
        case AppActions.APP_EP_TYPE:
            return { ...state, episodeType: action.payload };
        case AppActions.APP_MULTIPLIER:
            return { ...state, multiplier: action.payload };
        case AppActions.APP_ACCEPT: 
            return {...state, isAccept: action.payload };    
        case AppActions.APP_UPLOAD_FILE: 
            return { ...state, questionnaireFile: action.payload };  
        case AppActions.APP_CLICK_UPLOAD: 
            return { ...state, clickUploadFile: action.payload };     
        case AppActions.APP_CLEAR_FILE: 
            return { ...state, clearFile: action.payload };           
        default:
            return state;
    }
}