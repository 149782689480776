import { Box, Button, Flex, position, Text } from "@chakra-ui/react";
import React from "react";

type BannerReloadProps = {
    isAbsolute: boolean; 
    text: string; 
    handler?: () => void; 
    textBtn?: string; 
    top?: string | null; 
}

function BannerBase({isAbsolute, text, handler, textBtn, top = null}: BannerReloadProps) {
  return (
    <div
      style={{
        position: isAbsolute ? "absolute" : "relative",
        zIndex: 5,
        top: top ? top : "0px", 
        width: "100%",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Flex
        style={{
          backgroundColor: "#F8B509",
          width: "100%",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        top={{ base: "76px", lg: "86px" }}
        py={{ base: "10px", lg: "14px"}}
        px={{ base: "7px", md: "15x", lg: "18px"}}
      >
        <Box>
          <b>
            <Text
              fontSize={{ base: "11px", md: "14px"}}
            >
              {text}
            </Text>
          </b>
        </Box>
       {handler && textBtn && <Box>
          <Button
            backgroundColor="white"
            padding="10px"
            textTransform="none"
            borderRadius="16px"
            cursor="pointer"
            onClick={handler}
          >
            {textBtn}
          </Button>
        </Box>}
      </Flex>
    </div>
  );
}

export default BannerBase;