import { useEffect } from "react";
import { ROUTE_PATH } from "../../app/routes";


export function useRegisterTokenValidator( { appService, token, showDialog }: any ){
    useEffect(() => {
        appService.checkRegisterToken(token || '')
            .catch((e: any) => {
                let error = 'errors.misc';
                if ( e.response?.status === 401 )
                    error = 'errors.auth';
                else if ( e.response?.status === 422 )
                    error = 'errors.token';  
                
                // appService.error(e?.response?.data?.message);
                showDialog(true);
            });
    },[]);
}