import { MessageType } from "./message.type";

export const messageInitialState: MessageType = {
    messages: [],
    sendingMessage: false,
    sentCount: 0,
    connectedOn: null,
    history: [],
    messageMeta: null,
    isQuestionnaire: false, 
    encounter: null,
    questionnaire_completed: false, 
    clinic_open: false, 
};
