import React, { FC, useContext, useEffect, useState } from 'react';
import {
    Container,
    Text,
    Button
} from '@chakra-ui/react';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { useTranslation } from 'react-i18next';
import { AhPwdInput } from '../../components/input';

import { Formik, Form } from 'formik';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { initialValue } from './initial-value.form';
import { DisplayingErrorMessagesSchema } from './validation.form';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../app/routes';
import { HeaderLogo } from '../../components/header.logo';
import { useFormHandler, usePasswordResetTokenValidator } from './hooks';
import {FiArrowRight} from 'react-icons/fi';
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';

export const CreateNewPassword: FC<any> = () => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { state, appService }: AppContextInterface = useContext<AppContextInterface>(AppContext);

    usePasswordResetTokenValidator( {appService, token: window.location.pathname.split('/').pop(), history} );

    const [ onResetPasswordFormSubmit ] = useFormHandler({ appService, token: window.location.pathname.split('/').pop(), history });


    return (
        <Container
            mt={'5%'}
            mb="auto"
            width="100%"
            height="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="scroll">
            <HeaderLogo backLink={ROUTE_PATH.HOME} showLanguage={false} />
            <Text fontFamily={'Playfair Display'} fontWeight="bold" fontSize="2xl" mt="10%" ml={5}>
                {t('createNewPassword.pageTitle')}
            </Text>
            <Formik
                initialValues={initialValue}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={onResetPasswordFormSubmit}>
                { ({ errors, touched }) => (
                    <Form style={{ width: '100%', marginTop: '30px' }}>
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} ml={5} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                            {t('createNewPassword.newPwdInpLabel')}
                        </Text>
                        <AhPwdInput showPasswordRules={true} showPassordRules={true} fontSize={['sm', 'md', 'md']} error={state.app.isErrored} fontFamily={'Mulish'} name="password" ml={5} width="90%" type="email" placeholder={t('createNewPassword.newPwdInpLabel')} pt={2} pb={2} />
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} fontSize="sm" ml={5} mt={2}  fontFamily={'Mulish'}>
                            {t('createNewPassword.cnfNewPwdInpLabel')}
                        </Text>
                        <AhPwdInput fontSize={['sm', 'md', 'md']} error={state.app.isErrored} fontFamily={'Mulish'} name="password_confirmation" ml={5} width="90%" type="email" placeholder={t('createNewPassword.cnfNewPwdInpLabel')} pt={2} pb={2}/>
                        <Button
                            variant="solid"
                            size="md"
                            width="90%"
                            mt={5}
                            rightIcon={<FiArrowRight />}
                            backgroundColor="#FFDB00"
                            borderRadius="10pt"
                            ml={5}
                            fontFamily={'Mulish'}
                            type="submit">
                            <Text fontSize={['sm' , 'md', 'md']}>
                                {t('createNewPassword.resetPwdBtn')}
                            </Text>
                        </Button>
                    </Form>
                ) }
            </Formik>
        </Container>
    );
}
