import { Box, Flex, Text } from "@chakra-ui/layout";
import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import RegisterLaoyut from "./RegisterLaoyut";
import { useTranslation } from "react-i18next";
import { FormLabel, HStack, InputGroup, Select, VStack } from "@chakra-ui/react";
import { handlerLocaleStorage } from "../../utils/helpers";
import { useHistory } from "react-router";
import { RegisterContext } from "../../app/navigation";
import TextField from "../../components/textField";
import { Formik } from "formik";

function Step2(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const formRef = useRef<any>();

  const ValidationSchema = Yup.object().shape({
    address: Yup.string().required("Field is required").max(200),
    city: Yup.string().required("Field is required").max(200),
    postal_code: Yup.string()
    .required('Field is required')
    .matches(/^([a-zA-Z]{1}\d{1}[a-zA-Z]{1}) {0,1}(\d{1}[a-zA-Z]{1}\d{1})$/, 'Postal code is not valid')
    .max(200),
    province: Yup.string().required("Field is required"),
  });

  const provinces = {
    quebec: 'Quebec',
    ontario: 'Ontario',
    nunavut: 'Nunavut',
    alberta: 'Alberta',
    saskatchewan: 'Saskatchewan',
    manitoba: 'Manitoba',
    new_brunswick: 'New Brunswick',
    british_columbia: 'British Columbia',
    prince_edward_island: 'Prince Edward Island',
    nova_scotia: 'Nova Scotia',
    newfoundland_labrador: 'Newfoundland and Labrador',
    northwest_territories: 'Northwest Territories',
    yukon: 'Yukon'
}

  let registerValues = JSON.parse(sessionStorage.getItem("register") || "{}");
  const initialValuesBase = {
    address: "",
    city: "",
    postal_code: "",
    province: "",
  };

  let initialValues = registerValues["step_3"]
    ? registerValues["step_3"]
    : initialValuesBase;
  const slug = useContext(RegisterContext);

  const saveData = () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <RegisterLaoyut
      step={3}
      previous_btn={t("new_register.previous_question")}
      handlerSubmit={saveData}
      title_btn={t("new_register.next_question")}
      goBack={() => history.push(`/register/${2}${slug}`)}
    >
      <Box pt={{ base: "40px"}}>
        <Text color="#40B89C" fontWeight="700">
          {t(`new_register.step_2_location_pharmacy`)}
        </Text>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
        >
          {t("new_register.current_step", { step1: 1, step2: 2 })}{" "}
          {t(`new_register.your_Location`)}
        </Text>
      </Box>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          handlerLocaleStorage(values, "step_3");
          history.push(`/register/${4}${slug}`);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <VStack
              spacing={{ base: "unset", md: "20px" }}
              mt={{ base: "32px", "2xl": "48px" }}
              h="100%"
              width="100%"
            >
              <Flex w="100%">
                <Text color="#304358" fontSize="20px" fontWeight="700">
                  {t(`new_register.step_2_text`)}
                </Text>
              </Flex>

              <Flex w="100%">
                <TextField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t(`new_register.address`)}
                  error={errors.address && touched.address}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  label={t(`new_register.address`)}
                />
              </Flex>

              <Flex w="100%">
                <TextField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t(`new_register.city`)}
                  error={errors.city && touched.city}
                  onChange={handleChange}
                  value={values.city}
                  name="city"
                  label={t(`new_register.city`)}
                />
              </Flex>

              <HStack
                width="100%"
                spacing={{ md: "20px", lg: "34px" }}
                mb={'40px'}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <InputGroup display='flex' flexDirection="column" mb={{ base: "24px", md: "unset" }}>
                <FormLabel 
                    fontSize="12px"
                    fontFamily="Mulish"
                    color={errors.province && touched.province ?  "#F44970" : "#304358"}
                  >{t('registerForm.province')}</FormLabel>
                    <Select 
                        border={"1px"}
                        outline="none"
                        defaultValue={initialValues.province}
                        onChange={(e: any) => setFieldValue("province", e.target.value)}
                        h="56px"
                        isInvalid={errors.province && touched.province ? true : false}
                        focusBorderColor={!errors.province ? "#A3B2B1" : "#F44970"}
                        errorBorderColor={"#F44970"}
                        borderColor="#A3B2B1"
                        borderRadius="16px" 
                        placeholder={t('registerForm.province')}>
                        {Object.entries(provinces).map(item => <option selected={initialValues.province === item[0]} value={`${item[1]}`}>{t(`registerForm.provinces.${item[0]}`)}</option> )}
                    </Select>
                </InputGroup>

                <TextField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t(`registerForm.ptSection.postalCode`)}
                  name="postal_code"
                  data-private
                  onChange={handleChange}
                  error={errors.postal_code && touched.postal_code}
                  value={values.postal_code}
                  label={t(`registerForm.ptSection.postalCode`)}
                />
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </RegisterLaoyut>
  );
}

export default Step2;
