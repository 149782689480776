import { IconButton } from '@chakra-ui/button';
import { DownloadIcon } from '@chakra-ui/icons';
import { Container, Heading, Text, Link } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContextInterface } from '../app/dto/app-context.dto';
import { AppContext } from '../app/modules';

export const VCard: React.FC<any> = () => {
    const { t } = useTranslation();
    const { state }: AppContextInterface = useContext(AppContext);
    return (
        <Container
            mt={`${state.app.multiplier * 2 * window.devicePixelRatio}rem`}
            mb={5 * window.devicePixelRatio}
            backgroundColor="#fff"
            borderRadius="10px"
            boxShadow="0pt 4pt 12pt #30435829"
            display="flex"
            flexDirection="column"
            pl="16px"
            pt="14px"
            pr="16px"
            pb="10px"
            width='90%'
            >
            <Container>
                <Heading size="sm" fontFamily={'Mulish'}>{t('vCardDialog.title')}</Heading>
            </Container>
            <Container display="flex" p={0} mt="5px">
                <Container flex={2}>
                    <Text fontFamily={'Mulish'} fontSize={'sm'}>{t('vCardDialog.subInfo1')}</Text>
                    {/* <Text fontFamily={'Mulish'} fontSize={'sm'}>{t('vCardDialog.subInfo2')}</Text> */}
                    <Text fontFamily={'Mulish'} fontSize={'sm'}>{t('vCardDialog.subInfo3')}</Text>
                </Container>
                <Container
                    flex={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    >
                <Link href={t('lang') === 'en' ? "/vcard.vcf" : '/vcard-fr.vcf'}> 
                    <IconButton
                        aria-label="icon"
                        icon={<DownloadIcon />}
                        size="lg"
                        backgroundColor="#EFF5F3"
                        borderRadius="50%"
                        p="5px"
                    />
                </Link>
                </Container>
            </Container>
        </Container>
    );
}
