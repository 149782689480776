import React, { useContext, useEffect, useRef, useState } from 'react';
import { Center, Container, HStack, Text, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Link } from '@chakra-ui/react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { Divider, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { ROUTE_PATH } from '../app/routes';
import logo_icon from '../assets/icons/logo_icon_register.svg';
import logo_icon_fr from '../assets/icons/logo_icon_register_fr.svg';
import { AppContextInterface } from '../app/dto/app-context.dto';
import { AppContext } from '../app/modules';
import { WTTooltip } from './WTTooltip';
import { useLanguageObserver } from '../utils/hooks';
import { changeLanguage } from '../utils/helpers';

function LayoutComponent(props:any) {
    const [ language, setLanguage ] = useState<'en' | 'fr'>('fr');

    const { t, i18n } = useTranslation();
    const { state, sessionService }: AppContextInterface = useContext(AppContext);

    const {pathname} = useLocation();
    const display = useBreakpointValue({base: "none", lg: 'block'});
    const base = ROUTE_PATH.REGISTER_NEW;
    const options = ["Female", "Male"];
    const history = useHistory(); 
    const [isHeigherThan470] = useMediaQuery("(min-height: 470px)");
    const [isHigherThan700] = useMediaQuery("(min-height: 650px)");

  const handleChangeLanguage = (lang: 'en' | 'fr') => {
    changeLanguage(lang, language, setLanguage, i18n); 
  }

  const handlerClick = () => {
    if(!state.session) {
      window.location.href = "https://tapmedical.ca/en/allhealth";
    } else {
      window.location.href = ROUTE_PATH.CHAT_TYPE;
    }
  }

    const stylesProp = {
        size_text:'16px',
        circle_mg:'16px',
        display_hide: useBreakpointValue({base: 'inline-block', lg: "none"}),
        display_hide_lg: useBreakpointValue({base: 'flex', lg: "none"}),
    }

    useLanguageObserver(i18n, language, setLanguage); 
    
    return (
        <Container
        position='relative'
        width="100vw"
        minH={(!isHigherThan700 && pathname === '/') ? "800px" : "unset"}
        height={{base: isHeigherThan470 ? '100vh' : '100%'}}
        maxWidth='auto'
        backgroundColor="#FFFFFF"
        display="flex"
        pl="0"
        pr="0"
        flexDirection="column"
        justifyContent="flex-start"
        overflowX="hidden"
        >
            { pathname === '/' && <WTTooltip /> }
          <Container
            maxW='100%'
            display="flex"
            flexDirection={{base: 'column', lg: "row"}}
            mt={{base: "22px", md: '32px'}}
            mb={{base: "30px", lg: '30px', "2xl": '30px'}}
            width="100%"
            px={{base: "8px", md: "40px", "2xl": "80px"}}
            justifyContent="space-between">
            <Box
                display="flex"
                justifyContent="space-between"
                ps={{base: "16px", '2xl': 'unset'}}
                flexWrap={{base:'wrap', sm: 'nowrap'}}
                >

              <Image
                alt="all-health"
                style={{ cursor: 'pointer' }}
                onClick={handlerClick}
                // src={logo_icon}
                src={`${t('lang') === 'en' ? logo_icon : logo_icon_fr}`}
              />
              <Box display={stylesProp.display_hide_lg}
                   width={{base: '100%', sm: 'auto'}}
                   mt={{base: '7px', sm: 'unset'}}
                   justifyContent={{base: 'space-between', sm: 'unset'}}

              >
                <Link href={`mailto:${t(`new_register.top_bar_email_address`)}`} color="#8194AA" fontSize={stylesProp.size_text}>
                 {t(`new_register.top_bar_question`)}
                </Link>
                <Text cursor={'pointer'} onClick={() => handleChangeLanguage(language === 'en' ? 'fr' : 'en')} as="span" color="#8194AA"> | {t('lang') === 'en' ? 'FR':'EN'}</Text>
               </Box>
            </Box>



            <Box display={display}>
                <Text color="#8194AA" fontSize={stylesProp.size_text}>
                {t(`new_register.top_bar_email`)}:
                <Text onClick={() => window.open(`mailto:${t(`new_register.top_bar_email_address`)}`, '_blank')} as="span" color="#40B89C"> {t(`new_register.top_bar_email_address`)}</Text>
                <Text cursor="pointer" onClick={() => handleChangeLanguage(language === 'en' ? 'fr' : 'en')} as="span" color="#8194AA"> | {t('lang') === 'en' ? 'FR':'EN'} </Text>
                </Text>
            </Box>
            </Container>


            <Divider />
            <Flex w='100%' h="100%" justify="center" align="center">

              <Box minW={{base: "unset", lg: "1020px" }} h="100%" w={{base: '100%', xl: '55%'}}   display="flex" alignItems="center" position="relative">
               {props.children}
              </Box>

                <Box
                    w="45%"
                    height="100%"
                    display={display}
                    bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)"
                    >
                 </Box>
            </Flex>
       </Container>
    )
}

export default LayoutComponent
