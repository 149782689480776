import { PhoneIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/input'
import { Box, Text } from '@chakra-ui/layout'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Image,
    Fade,
    useOutsideClick,
  } from "@chakra-ui/react"
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useEffectOnce } from 'react-use'
import search_icon from '../assets/icons/search.svg';

function SearchInput(props: any) {

const ref = useRef<any>();
const boxRef = useRef<any>();
const [value, setValue] = useState({search: props.defaultValue});
const [open, setOpen] =useState<boolean>(false);   
const [error, setError] = useState(props.error);
const [test, setTest] = useState(0);

const activeIdx = useRef(0);
const listOptions = useRef(props.options);
const lengthOptions = useRef(props.options.length);

useEffect(() => {
  lengthOptions.current = props.options.length;
  listOptions.current = props.options;
}, [props.options])


useOutsideClick({
    ref: ref,
    handler: () => {
      setOpen(false);
      activeIdx.current = 0;
    }
})
 const handleChange = (e: any) => {
   if(error) {
     setError(false)
   }
   if(e.target.value.length > 0 && !open) {
       setOpen(true)
   }  
   if(e.target.value.length === 0 && open) {
    setOpen(false)
   } 
   setValue({search: e.target.value}); 
   props.handleType(e.target.value); 
 }   

 const handlerSelect = (index: number) => {
   let selectedItem = listOptions.current[index];
   props.handler(selectedItem); 
   setValue({search: selectedItem}); 
   activeIdx.current = 0;
   setOpen(false);
 }

  const useForceUpdate = () => {
    const [, setState] = useState<any>();
    return () => setState({});
  }
  const forceUpdate = useForceUpdate();

  const handleMouseEnter = (idx: number) => {
    activeIdx.current = idx;
    forceUpdate();
  }

  const handleSelectOption = (e: any) => {
    switch (e.keyCode) {
      case 13:
      handlerSelect(activeIdx.current - 1);
      break;
      case 38:
        if(activeIdx.current > 0 && lengthOptions.current > 0) {
          activeIdx.current--;
          forceUpdate();
        }
          break;
      case 40:
        if(activeIdx.current < lengthOptions.current) {
          activeIdx.current++;
          forceUpdate();
        }
          break;
   }
 };



 useEffect(() => {
  window.addEventListener('keydown',  handleSelectOption);

  return () => {
    window.removeEventListener('keydown', handleSelectOption);
  }
 }, []);


    return (
        <Box width="100%">
          <FormControl ref={ref}  width="100%" position="relative">
             {props?.label && <FormLabel 
              fontSize="12px"
              fontFamily="Mulish"
              color="#304358"
             >{props.label}</FormLabel>}
            <InputGroup alignItems="center">
              <InputRightElement
               top={{base: "8px", md: "unset"}}
               right="8px"
               cursor="pointer"
               children={!props.noIcon ? <Image  
                 boxSize="24px" 
                 alt="" 
                 src={search_icon}/> : "" }
              />
              <Input 
                borderStyle="solid"
                borderWidth="1px"
                borderColor={!props.error ? "#304358" : "#F44970"}
                errorBorderColor="#F44970"
                borderRadius="16px"  
                width="100%"   
                height="56px"
                isTruncated
                isInvalid={error}
                onChange={handleChange}
                value={value.search}
                _hover={{
                  borderColor: !props.error ? "#304358" : "#F44970",
                 }}
                 _focus={{
                  borderColor: !props.error ? "#304358" : "#F44970",
                }}
                {...props}/>

            </InputGroup>
           {open && <Fade in={open}>
             <Box
              boxShadow="0px 6px 94px rgba(172, 186, 202, 0.32), 0px 0.751293px 11.7703px rgba(172, 186, 202, 0.16)"
              borderRadius="8px" 
              top={{base: "60px", xl: "unset"}}
              overflow="hidden"
              maxH="30vh"
              overflowY="scroll"
              zIndex={20}
              position="absolute" 
              width="100%">
                {props.options.map((item: any, idx: number) => <Box
                  cursor="pointer"   
                  key={idx}
                  backgroundColor={activeIdx.current === idx + 1 ? "#FFF19A" : "#FFFFFF"}
                  onClick={() => handlerSelect(idx)}
                  onMouseEnter={() => handleMouseEnter(idx + 1)}
                >
                    <Text p="8px" color="#304358" fontSize="16px" noOfLines={4} width="90%">{item}</Text>
                </Box>)}
                </Box>
           </Fade>}
            
           </FormControl>
        </Box>
    )
};

export default SearchInput; 
