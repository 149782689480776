import { ViewIcon, ViewOffIcon, CloseIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { validationRules } from './validation-rules';

type RuleValidationMessageProps = {
    condition: boolean
}

const RuleValidationIcon: React.FC<RuleValidationMessageProps> = ( { condition }: RuleValidationMessageProps) => {
    return  condition === false ?
    (
        <ListIcon as={FiCheckCircle} color={"#60CBB2"} />
    ) :  (
        <ListIcon as={ FiXCircle } color={ "#E27878" } />
    );
}


export function AhPwdInput(props: any) {
    // this will return field exactly like <Field>{({ field }) => ... }</Field>
    const [field, meta] = useField(props);
    const { t } = useTranslation();
    
    const [ showPwd, setShowPwd ] = useState(false);


    return (
        <>
            <InputGroup width="90%" display="block">
                <Input 
                    data-testid='password-input'
                    {...field}
                    {...props} 
                    errorBorderColor="#E27878"
                    borderRadius='10pt'
                    width="100%"
                    borderColor='#A3B2B1'
                    isInvalid={ (meta.touched === true && meta.error) || props.error === true}
                    type={ showPwd === true ? 'text' : 'password' } />
                <InputRightElement width={0} p={0} onClick={() => setShowPwd(!showPwd)}>
                    {
                        showPwd === true ?
                        <ViewOffIcon mr={1} p={0}/> :
                        <ViewIcon mr={1} p={0}/>
                    }
                </InputRightElement>
            </InputGroup>
            { meta.touched === true && meta.error !== undefined && meta.error !== 'validation.pwdInvalid' &&
                <small data-testid='default-error' style={{ marginLeft: '6%', color: '#E27878', fontFamily: 'Mulish', display: 'block', width: '90%' }}>
                    {t(meta.error, { field: props.name.replace('_', ' ').split(' ').map( (s:any) => s[0].toUpperCase() + s.slice(1, s.length) ).join(' ') })}
                </small> }
            { 
                props.showPasswordRules === true &&
                (
                    <List spacing={1} marginLeft='6%'>
                        {
                            validationRules(field.value).map( (rule) => (
                                <ListItem  fontSize='sm' color='grey' fontFamily='Mulish'>
                                    <RuleValidationIcon condition={rule.condition} />
                                    <Text display='inline' fontSize='xs'>
                                        {t(rule.label, { field: props.name.replace('_', ' ').split(' ').map( (s:any) => s[0].toUpperCase() + s.slice(1, s.length) ).join(' '), characters: 8 })}
                                    </Text>
                                </ListItem>
                            ) )
                        }
                    </List>
                )
            }
        </>
    );
}
