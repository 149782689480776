import { Box, Flex, Text } from "@chakra-ui/layout";
import { useContext } from "react";
import RegisterLaoyut from "../RegisterNew/RegisterLaoyut";
import { useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/button";
import { useHistory } from "react-router";
import { RegisterContext } from "../../app/navigation";
import FamilyRegisterLaoyut from "./FamilyRegisterLayout";
import { ArrowBackIcon } from "@chakra-ui/icons"; 
import { ROUTE_PATH } from "../../app/routes";

function InviteMembers(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isHigherThan800] = useMediaQuery("(min-height: 800px)");
  const slug = useContext(RegisterContext);

  const skipNextStep = () => {
    history.push(`/register/${8}${slug}`);
  };

  return (
    <FamilyRegisterLaoyut
      step={1}
      handlerSubmit={skipNextStep}
      title_btn={t("new_register.skip_step")}
    >
      <Flex alignItems="center" height={{ base: "40px", md: "100px", lg: "112px" }} cursor="pointer" onClick={() => history.push(ROUTE_PATH.CHAT_TYPE)}>
        <ArrowBackIcon/>
        <Text fontSize="16px" fontWeight="bold" ml="10px">{t("new_register.home")}</Text>
      </Flex>
      <Box>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
          py="8px"
          lineHeight="48px"
        >
          {t(`new_register.family_members`)}
      
        </Text>
      </Box>
      <Box>
        <Text pb="16px" color="#8194AA" fontSize="16px">
          {t("new_register.fmd_members_text_1")}
        </Text>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        mt={isHigherThan800 ? "40px" : "10px"}
        mb={{base: "40px", md: "unset"}}
      >
        <Box
          mr={{ md: "30px" }}
          mb={{ base: "30px", md: "0" }}
          maxW="344px"
          h="288px"
          w={{ base: "100%", md: "50%", lg: "40%" }}
          p="32px"
          border="1px solid #A3B2B1"
          borderRadius="16px"
          position="relative"
        >
          <Text color="#304358" fontSize="20px" fontWeight="800">
            {t("new_register.members_card_title_1")}
          </Text>
          <Text color="#304358" fontSize="16px">
            {t("new_register.members_card_text_1")}
          </Text>
          <Button
            fontSize="16px"
            textTransform="none"
            onClick={() => history.push(`/family/${2}${slug}`)}
            textDecoration="underline"
            fontFamily="Mulish"
            color="#304358"
            fontWeight="700"
            borderRadius="none"
            px="8px"
            py="4px"
            cursor="pointer"
            height="auto"
            position="absolute"
            bottom="50px"
            mt="16px"
            backgroundColor="#FFF19A"
            variant="solid"
          >
            {t("new_register.members_btn")}
          </Button>
        </Box>
        <Box
          maxW="344px"
          w={{ base: "100%", md: "50%", lg: "40%" }}
          p="32px"
          h="288px"
          border="1px solid #A3B2B1"
          borderRadius="16px"
          position="relative"
          mb={{base: "20px", md: "unset"}}
        >
          <>
            <Text color="#304358" fontSize="20px" fontWeight="800">
              {t("new_register.members_card_title_2")}
            </Text>
            <Text color="#304358" fontSize="16px">
              {t("new_register.members_card_text_2")}
            </Text>
          </>
          <Button
            fontSize="16px"
            textTransform="none"
            position="absolute"
            bottom="50px"
            onClick={() => history.push(`/family/${3}${slug}`)}
            textDecoration="underline"
            fontFamily="Mulish"
            color="#304358"
            fontWeight="700"
            borderRadius="none"
            px="8px"
            py="4px"
            cursor="pointer"
            height="auto"
            mt="16px"
            backgroundColor="#FFF19A"
            variant="solid"
          >
            {t("new_register.btn_add")}
          </Button>
        </Box>
      </Flex>
    </FamilyRegisterLaoyut>
  );
}

export default InviteMembers;
