import moment from "moment";
import * as Yup from "yup";

export const ValidationSchemaStep1 = Yup.object().shape({
    first_name: Yup.string().required("Field is required").max(200),
    last_name: Yup.string().required("Field is required").max(200),
    company_name: Yup.string()
    .required('Field is required'), 
    hcn: Yup.string(),
    email: Yup.string().email().required("Field is required"),
    mobile_number: Yup.string()
     .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/)
     .required("Field is required"),
    day: Yup.number().max(31),
    month: Yup.number().max(12),
    year: Yup.date(),
    birth_day: Yup.number().max(31).required("Field is required"),
    birth_month: Yup.number().max(12).required("Field is required"),
    birth_year: Yup.date()
      .max(moment().format("YYYY"), "Future date not allowed")
      .required("Field is required"),
    gender: Yup.string().required("Field is required"),
  });

  export const ValidationSchemaStep1Updated = Yup.object().shape({
    first_name: Yup.string().required("Field is required").max(200),
    last_name: Yup.string().required("Field is required").max(200),
    company_name: Yup.string()
    .required('Field is required'), 
    hcn: Yup.string().required("Field is required"),
    email: Yup.string().email().required("Field is required"),
    mobile_number: Yup.string()
    .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/)
    .required("Field is required"),
    day: Yup.number().max(31).required("Field is required"),
    month: Yup.number().max(12).required("Field is required"),
    year: Yup.date()
      .min(moment().format("YYYY"), "Past date not allowed")
      .required("Field is required"),
    birth_day: Yup.number().max(31).required("Field is required"),
    birth_month: Yup.number().max(12).required("Field is required"),
    birth_year: Yup.date()
      .max(moment().format("YYYY"), "Future date not allowed")
      .required("Field is required"),
    gender: Yup.string().required("Field is required"),
  });


  export const initialValuesBaseStep1 = {
    first_name: "",
    last_name: "",
    company_name: "",
    hcn: "",
    mobile_number: "",
    email: "",
    month: "",
    year: "",
    day: "",
    birth_day: "",
    birth_month: "",
    birth_year: "",
    gender: "",
  };

