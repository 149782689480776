import React, { MutableRefObject, useRef, useState } from 'react';
import { Box, Container, SkeletonCircle, SkeletonText, Text } from '@chakra-ui/react';
import { useEffectOnce } from 'react-use';

export interface PlaceholderMessageComponentProps {
    belongsToUser: boolean;  
};

export const PlaceholderMessage: React.FC<PlaceholderMessageComponentProps> = ({ belongsToUser }: PlaceholderMessageComponentProps) => {

  const ref: MutableRefObject<any> = useRef<any>(null);
  
  useEffectOnce(() => {
      ref.current?.scrollIntoView({ behaviour: 'smooth' });
  });

  return (
    <Container ref={ref} display="flex" flexDirection={"column"} alignItems={belongsToUser === false ? 'flex-start' : 'flex-end'}>
      <Container
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-end"
            flexDirection={ belongsToUser === false ? "row" : "row-reverse" }
            mt="1%"
          >
              {/* <Avatar width="25px" height="25px" src={avatar} /> */}
              <SkeletonCircle size="7" />
              <Box
                backgroundColor={ belongsToUser === false ? "whiteAlpha.500" : "#E3FFF8" } 
                ml="1%"
                mr="1%"
                maxWidth="70%"
                border={`1px solid ${ belongsToUser === false ? '#D5DFDE' : '#9DE6D5'}`}
                p="2%"
                borderTopRightRadius="20px"
                borderTopLeftRadius="20px"
                borderBottomRightRadius={belongsToUser === false ? "20px" : "0px"}
                borderBottomLeftRadius={belongsToUser === false ? "0px" : "20px"}
                box-shadow="0px 4px 8px #00000014"
                width={'150px'}
              >
                <SkeletonText m="4" noOfLines={2} spacing="2" />
              </Box>
          </Container>
    </Container>
  );
}