import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Navigation } from './navigation';
import './i18n';
import { Capacitor } from '@capacitor/core';
import { AppProvider } from './modules';

export const App: React.FC<any> = () => {

    return (
        <BrowserRouter basename={Capacitor.getPlatform() === 'web' ? process.env.PUBLIC_URL : ''}>
            <AppProvider>
                <Navigation />
            </AppProvider>            
        </BrowserRouter>
    );
}
