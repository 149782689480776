// import { AppService } from "../app";
import Axios from 'axios';
import Uris from '../../api/uris';
import { SessionActions } from './session.action';
import { AppActions } from '../app';
import LogRocket from 'logrocket';

export class SessionService {
    
    constructor ( 
        private readonly dispatch: any 
    ){}

    async ping (token: string) {
        await Axios.post( Uris.ping(), {}, { headers: { Authorization: `Bearer ${token}` } } );
    }

    async loginToken( token: string ){
        this.dispatch({ type: AppActions.APP_ERROR, payload: { errorMessage: null, isErrored: false, successMessage: null } });
        let response: any = await Axios.post( Uris.loginToken(), { token }, { headers: { 'Accept': 'application/json', 'Content-Type' : 'application/json' } } );
        this.dispatch({ type: SessionActions.LOGIN, payload: { token: response.data.token, episode: response.data.episode, pusher: response.data.pusher } });
    }

    async login( email: string, password: string, selectedLanguage: string = 'fr' ) {
        try {
            this.dispatch({ type: AppActions.APP_ERROR, payload: { errorMessage: null, isErrored: false, successMessage: null } });
            let response: any = await Axios.post( Uris.login(), { email, password, selectedLanguage }, { headers: { 'Accept': 'application/json', 'Content-Type' : 'application/json' } } );
            const lastDeploy = localStorage.getItem("FE_last_deploy"); 
            if(response.data?.FE_last_deploy && lastDeploy && response.data?.FE_last_deploy !== lastDeploy) {
                localStorage.setItem("FE_last_deploy", response.data?.FE_last_deploy); 
                window.location.reload(); 
                return;
            } else if(!lastDeploy && response.data?.FE_last_deploy) {
                localStorage.setItem("FE_last_deploy", response.data?.FE_last_deploy); 
            }
            this.dispatch({ type: SessionActions.LOGIN, payload: { token: response.data.token, episode: response.data.episode, pusher: response.data.pusher, onesignal: response.data?.onesignal } });
            return { token: response.data.token, episode: response.data.episode, first_login: response.data.first_login };
        } catch ( e: any ) {
            let error = 'errors.misc';
            if ( e.response?.status === 401 || e.response?.status === 403)
                error = 'errors.auth'
            this.dispatch({ type: AppActions.APP_ERROR, payload: { errorMessage: error, isErrored: true } });
            return null;
        }
    }

    async logout() {
        LogRocket.startNewSession(); 
        //this.dispatch({ type: SessionActions.LOGOUT });
        window.location.reload();
    }

    async updatePatient ( user: any, token: string ) {
        await Axios.patch(Uris.patient(), user, { headers: { Authorization: `Bearer ${token}` } } );
    }

    async updateLang ( preferred_language: string, payload: any ) {
        const user = payload.episode.patient;
        user.preferred_language = preferred_language;
        await Axios.patch(Uris.patient(), user, { headers: { Authorization: `Bearer ${payload.token}` } } );
    }

}