import React, { useContext, useEffect, useRef, useState } from 'react';
import { Center, Container, HStack, Text, VStack } from '@chakra-ui/layout';
import { Input, InputGroup } from '@chakra-ui/input';
import { Image } from '@chakra-ui/image';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { Divider, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../app/routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/textField';
import logo_icon from '../../assets/icons/logo_icon_register.svg';
import PasswordField from '../../components/passwordField';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import logo_icon_fr from "../../assets/icons/logo_icon_register_fr.svg";
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';



function ResetLinkSentNew() {

    const { t, i18n } = useTranslation();
    const {pathname} = useLocation();
    const display = useBreakpointValue({base: "none", lg: 'block'});
    const base = ROUTE_PATH.REGISTER_NEW;

    
    const history = useHistory(); 

    const [isLargerThan1400] = useMediaQuery("(min-width: 1400px)")
    const [isLargerThan1560] = useMediaQuery("(min-width: 1560px)")
    const [isLargerThan760] = useMediaQuery("(min-width: 760px)")

    const { state }: AppContextInterface = useContext(AppContext);
    
    const stylesProp = {
        size_text:'16px', 
        circle_mg:'16px', 
        display_hide: useBreakpointValue({base: 'inline-block', lg: "none"}),
        display_hide_md: useBreakpointValue({base: 'flex', md: "none"}),
    }

    const handlerSearch = (value: any) => {
       
    }

    const handlerRedirect = () => {
      if(!state.session) {
        window.location.href = "https://tapmedical.ca/en/allhealth";
      } else {
        window.location.href = ROUTE_PATH.CHAT_TYPE; 
      }      
    }



    
    return (
        <Container
        position='relative'
        width="100vw"
        height={{base: '100%', md: "100vh"}}
        maxWidth='auto'
        backgroundColor="#FFFFFF"
        display="flex"
        pl="0"
        pr="0"
        flexDirection="column"
        justifyContent="flex-start"
        overflowX="hidden"
        >
          <Container 
            maxW='100%' 
            display="flex" 
            flexDirection={{base: 'column', lg: "row"}}
            mt={{base: "22px", md: '32px'}}
            mb={{base: "30px", lg: '30px', "2xl": '30px'}}
            width="100%"
            px={{base: "16px", md: "40px", "2xl": "80px"}}
            justifyContent="space-between">   
            <Box 
                display="flex" 
                justifyContent="space-between" 
                ps={{base: "16px", '2xl': 'unset'}} 
                //pl={{base: "16px", md:'80px', lg:'unset'}}
                //pb={{base: "16px", lg: "unset"}}
                >
              <Image
                alt="all-health" 
                style={{ cursor: 'pointer' }} 
                onClick={handlerRedirect}
                // src={logo_icon}
                src={`${t('lang') === 'en' ? logo_icon : logo_icon_fr}`}
              />
              <Box display={stylesProp.display_hide_md}>
                <Text onClick={() => window.open(`mailto:${t(`new_register.top_bar_email_address`)}`)} color="#8194AA" fontSize={stylesProp.size_text}>
                 {t(`new_register.top_bar_question`)}
                </Text> 
               </Box>
            </Box>

  
            <Box display={display}>
                <Text color="#8194AA" fontSize={stylesProp.size_text}>
                {t(`new_register.top_bar_email`)}:
                <Text onClick={() => window.open(`mailto:${t(`new_register.top_bar_email_address`)}`)}  as="span" color="#40B89C"> {t(`new_register.top_bar_email_address`)}</Text>
                </Text> 
            </Box>
            </Container>


            <Divider />
            <Flex w='100%' h="100%" justify="center" align="center">

                 <Box minW={{base: "unset", lg: "1020px" }} h="100%" w={{base: '100%', xl: '55%'}}   display="flex" alignItems="center">
                 <Box h="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                    <Flex  pb={{md: "60px", lg:'unset'}} style={{height: `calc(100% - 105px - 90px)`}} direction="column" justify="center">
                      <Box px={{base: "16px", md: "40px"}} pt={{base: '112px', md: '70px', lg: '80px', xl: '90px', '2xl': '250px'}}>
                       <Text fontWeight="700" fontSize={{base: "32px", md: "40px"}} color="#304358">{t('resetPwdLinkSent.pageTitle')}</Text>
                       <Text mb={{base:"16px"}}  fontSize="16px" color="#8194AA" width={{base:"100%", "md": "80%"}}>
                       {t('resetPwdLinkSent.text1')}<br/>
                       {t('resetPwdLinkSent.text2')}      
                       </Text>

                     </Box>

                     <HStack width="100%"  wrap={{base: "wrap", md: "nowrap"}}>
                        <Box display="flex" alignItems="center"  width={{base: "100%", md: "50%"}}  px={{base: "16px", md: "40px"}} pt="15px" pr={{md: "20px", lg:"34px"}} pb={{base: '40px', md: '100px', '2xl': '270px'}}>
                        <Button 
                            onClick={()=> history.push("/login")}
                            rightIcon={<ArrowForwardIcon />} 
                            fontSize="16px"
                            textTransform="none"
                            fontFamily="Mulish"
                            color="#304358"
                            fontWeight="700"
                            borderRadius="10pt"
                            backgroundColor="#FFDB00" 
                            variant="solid">
                            {t('resetPwdLinkSent.login')}
                            </Button>
                        </Box>
                    </HStack>
       

                    </Flex>               
                   </Box> 
                  </Box>
   
                   <Box 
                    w="45%" 
                    height="100%"   
                    display={display}
                    bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)" 
                    >
                 </Box>
             
            </Flex>
       </Container>
    )
}

export default ResetLinkSentNew