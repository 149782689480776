export const AppActions = {
    APP_ERROR:      'APP_01',
    APP_SUCCESS:    'APP_02',
    APP_EP_TYPE:    'APP_03',
    APP_MULTIPLIER: 'APP_04',
    APP_ACCEPT:     'APPP_05',
    APP_UPLOAD_FILE: 'APP_06', 
    APP_CLICK_UPLOAD: 'APP_07', 
    APP_CLEAR_FILE: 'APP_08', 
};
