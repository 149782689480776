import { Box, Flex, Text } from "@chakra-ui/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import TextField from "../../components/textField";
import RegisterLaoyut from "./RegisterLaoyut";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  HStack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PinFields from "../../components/pinFields";
import RadioFields from "../../components/radioFields";
import InputDropdown from "../../components/inputDropdown";
import { useHistory } from "react-router";
import { handlerLocaleStorage } from "../../utils/helpers";
import { RegisterContext } from "../../app/navigation";
import { initialValuesBaseStep1, ValidationSchemaStep1, ValidationSchemaStep1Updated } from "./Validation";
import { useUpdateEffect } from "react-use";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { internalRoutes } from "../../app/routes";
import { useRefreshErrorMessage } from "../../utils/hooks";

function Step1(props: any) {
  const { appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  let registerValues = JSON.parse(sessionStorage.getItem("register") || "{}");
  const employer_name = registerValues["step_1"]?.employer_name || "";
  const [schemaValidation, setSchemaValidation] = useState(ValidationSchemaStep1);
  const [emailError, setEmailError] = useState(false); 
  const [errorText, setErrorText] = useState(""); 
  const [isUpdatedSchema, setIsUpdatedSchema] = useState(false); 
  const [isLoading, setIsLoading] = useState(false); 
  const toast = useToast();

  const setInintialState = (values: any) => {
    let [year, month, day] = values.hcn_expiry_date.split("-");
    let [birth_year, birth_month, birth_day] = values.date_of_birth.split("-");
    let {
      first_name,
      last_name,
      hcn,
      email,
      mobile_number,
      gender,
    } = values;
    return {
      first_name,
      last_name,
      company_name: employer_name,
      hcn,
      mobile_number,
      email,
      month,
      day,
      year,
      birth_month,
      birth_day,
      birth_year,
      gender,
    };
  };


  let initialValues = registerValues["step_2"]
    ? setInintialState(registerValues["step_2"])
    : initialValuesBaseStep1;

  const formRef = useRef<any>();
  const history = useHistory();
  const { t } = useTranslation();

  const options = [
    t(`new_register.gender.female`),
    t(`new_register.gender.male`),
  ];
  let gender = registerValues["step_2"]?.gender || "";
 
  if(employer_name) {
    formRef?.current?.setFieldValue(`company_name`, employer_name);
    initialValuesBaseStep1.company_name = employer_name;
  }
  const slug = useContext(RegisterContext);

  const [radioValue, setRadioValue] = useState(gender);

  const handleSendData = async(email: string, handler: Function) => {
    setIsLoading(true); 
    let response = await appService.checkEmail(email); 
    setIsLoading(false); 
    if(response) {
      setEmailError(true); 
      setErrorText(t('validation_code.email_error')); 
      return
    } 
    handler(); 
  }

  const saveData = async () => {
    formRef?.current?.handleSubmit();
  };

  useRefreshErrorMessage(!!errorText, formRef?.current?.handleSubmit);


  const handler = (value: any) => {
    setRadioValue(value);
    if (value === options[1]) {
      formRef?.current?.setFieldValue(`gender`, false);
    } else {
      formRef?.current?.setFieldValue(`gender`, true);
    }
  };

  return (
    <RegisterLaoyut
      title_btn={t("new_register.next_question")}
      step={2}
      isLoad={isLoading}
      goBack={() => history.push(`/register/${internalRoutes.ACCEPT}${slug}`)}
      handlerSubmit={saveData}
    >
      <Box pt={{ base: "40px"}}>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
        >
          {t(`new_register.main_page_title`)}
        </Text>
        <Text fontSize="16px" color="#8194AA">
          {t(`new_register.main_page_text`)}
        </Text>
      </Box>

      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        enableReinitialize
        validationSchema={schemaValidation}
        onSubmit={(values) => {
          const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            hcn: values.hcn,
            mobile_number: values.mobile_number,
            email: values.email,
            gender: radioValue,
            hcn_expiry_date: `${values.year}-${values.month}-${values.day}`, 
            date_of_birth: `${values.birth_year}-${values.birth_month}-${values.birth_day}`,
          };
          handleSendData(values.email, () => {
            handlerLocaleStorage(data, "step_2");
            history.push(`/register/${3}${slug}`);
          })
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldError,
          handleBlur,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <VStack
              spacing={{ base: "unset", md: "20px" }}
              mt={{ base: "32px", lg: "48px" }}
              //mb={{ lg: "40px", "2xl": "120px" }}
              width="100%"
            >
              <HStack
                width="100%"
                justifyContent="flex-start"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <Box
                  w={{ base: "100%", md: "50%" }}
                  pr={{ md: "10px", lg: "17px" }}
                >
                  <TextField
                    mb={{ base: "24px", md: "unset" }}
                    placeholder={t(`new_register.companyName`)}
                    error={errors.company_name && touched.company_name}
                    onChange={handleChange}
                    value={values.company_name}
                    isReadOnly
                    name="company_name"
                    label={t(`new_register.your_employer`)}
                  />
                </Box>
              </HStack>

              <HStack
                width="100%"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <TextField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t(`new_register.firstName`)}
                  name="first_name"
                  error={errors.first_name && touched.first_name}
                  onChange={handleChange}
                  value={values.first_name}
                  label={`${t(`new_register.firstName`)} (${t('new_register.name_warning')})`}
                />
                <TextField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t(`new_register.lastName`)}
                  name="last_name"
                  data-private
                  onChange={handleChange}
                  error={errors.last_name && touched.last_name}
                  value={values.last_name}
                  label={`${t(`new_register.lastName`)} (${t('new_register.name_warning')})`}
                />

              </HStack>

              <HStack
                width="100%"
                spacing={{ md: "20px", lg: "34px" }}
                display="flex"
                alignItems="flex-start"
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <Flex
                  w={{ base: "100%", md: "50%" }}
                  justify="space-between"
                  wrap={{ base: "wrap", md: "nowrap" }}
                >
                  <Box w={{ base: "100%", md: "38%", lg: "46%" }}>
                    <TextField
                      data-private
                      mb={{ base: "24px", md: "unset" }}
                      label={t(`new_register.medicareNumber`)}
                      name="hcn"
                      error={errors.hcn && touched.hcn}
                      onChange={handleChange}
                      value={values.hcn}
                      placeholder={t(`new_register.medicareNumber`)}
                    />
                  </Box>
                  <Box
                    mb={{ base: "24px", md: "unset" }}
                    w={{ base: "100%", md: "60%", lg: "50%" }}
                    display="flex"
                    justifyContent="right"
                  >
                    <PinFields
                      error_day={errors.day && touched.day }
                      error_month={errors.month && touched.month}
                      error_year={errors.year && touched.year}
                      value_day={values.day}
                      value_month={values.month}
                      value_year={values.year}
                      customChange={(name: string, value: any) => {
                          setFieldValue(name, value);
                        } 
                      }
                      label={t(`new_register.medicareExpirationBase`)}
                    />
                  </Box>
                </Flex>
                <Box
                  w={{ base: "100%", md: "50%" }}
                  mb={{ base: "24px", md: "unset" }}
                >
                  <RadioFields
                    label={t(`new_register.sex_of_birth`)}
                    isHelperText
                    error={errors.gender && touched.gender}
                    options={options}
                    name="gender"
                    handler={(value: any) => handler(value)}
                    defaultValue={radioValue}
                  />
                </Box>
              </HStack>

              <HStack
                width="100%"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <Box
                  w={{ base: "100%", md: "50%" }}
                  mb={{ base: "24px", md: "unset" }}
                >
                  <PinFields
                    customChange={(name: string, value: any) =>
                      setFieldValue(`birth_${name}`, value)
                    }
                    value_day={values.birth_day}
                    value_month={values.birth_month}
                    value_year={values.birth_year}
                    error_day={errors.birth_day && touched.birth_day}
                    error_month={errors.birth_month && touched.birth_month}
                    error_year={errors.birth_year && touched.birth_year}
                    label={t(`new_register.date_of_birth`)}
                  />
                </Box>
              </HStack>

              <HStack
                width="100%"
                h={{lg: "115px"}}
                display="flex"
                alignItems="flex-start"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                 <TextField
                  data-private
                  mb={{ base: !!errorText ? "unset" : "24px", md: "unset" }}
                  placeholder={t(`new_register.emailAddress`)}
                  name="email"
                  error={errors.email ? (errors.email && touched.email) : emailError}
                  onChange={(e: any) => {
                    setFieldValue("email", e.target.value); 
                    if(emailError) {
                      setEmailError(false); 
                      setErrorText(""); 
                    }
                  }}
                  helpertext={errorText}
                  value={values.email}
                  label={t(`new_register.emailAddress`)}
                />
                <InputDropdown
                  data-private
                  //mb={{ base: "unset", md: "unset" }}
                  placeholder={t(`new_register.phoneNumberUser`)}
                  name="mobile_number"
                  error={errors.mobile_number && touched.mobile_number}
                  showHelperText={true}
                  onChange={handleChange}
                  value={values.mobile_number}
                  label={t(`new_register.phoneNumberUser`)}
                  helperText={t(`new_register.phoneNumberErrorText`)}
                />
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </RegisterLaoyut>
  );
}

export default Step1;
