import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, position, Text, useDisclosure } from "@chakra-ui/react";
import { EllipsisCustom, InstallIconCustom } from "./icons";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EmailIcon } from "@chakra-ui/icons";
import { checkIsIOS } from "../utils/helpers";
import ios_fr_gif from '../assets/icons/ios-fr.gif';
import ios_en_gif from '../assets/icons/ios-en.gif';
import android_fr_gif from '../assets/icons/Android-fr.gif';
import android_en_gif from '../assets/icons/Android-en.gif'; 

type PropsT = {
  handler: () => void;
};

export type SupportDialogProps = {
  cancelRef: any;
  onClose: () => void;
  isOpen: boolean;
};

const AddToHomeScreenBanner = ({ cancelRef, onClose, isOpen }: SupportDialogProps) => {
  const {t, i18n} = useTranslation();

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const remindMeHanler = () => {
    localStorage.setItem('remind', 'true'); 
    onClose(); 
  }

  const cancelHandler = () => {
    localStorage.setItem('remind', 'false'); 
    onClose(); 
  }

  const isIOSFlow = () => {
    if(checkIsIOS() && isSafari()) {
      return true; 
    }
    return false; 
  }

  const ShowInfo = () => {
    if(checkIsIOS() && isSafari()) {
        return <Text fontSize={{ base: "16px", md: "14px" }}>
          {t('onesignal.text_banner_1')}
          <InstallIconCustom height='15px' width='15px' /> 
          {t('onesignal.text_banner_2')}
        </Text>
    } /* else if(checkIsIOS() && !isSafari()) {
      return  <Text fontSize={{ base: "18px", md: "14px" }}>
          {t('onesignal.text_banner_ios_chrome')}
        </Text>
    }  */else {
      return <Text fontSize={{ base: "16px", md: "14px" }}>
          {t('onesignal.text_banner_1_android')}
          <EllipsisCustom height='15px' width='15px'/>
           {t('onesignal.text_banner_3')}
        </Text>
    }
  }

  return (
    <AlertDialog
    motionPreset="slideInBottom"
    leastDestructiveRef={cancelRef}
    onClose={onClose}
    isOpen={isOpen}
    isCentered>
    <AlertDialogOverlay opacity={0.5} style={{ backdropFilter: 'blur(10px)', backgroundColor: 'transparent!important' }} />

    <AlertDialogContent borderRadius={'20px'} boxShadow={'0px 4pt 12pt #30435829'} width={'90%'}>
        <AlertDialogHeader textAlign='center' fontSize='16px' fontWeight='bold' color='#304358' fontFamily={'Mulish'}>{t('onesignal.title_banner')}</AlertDialogHeader>
        <AlertDialogBody>
          <Flex justifyContent={'center'}>
            {isIOSFlow() ? <img src={i18n.language === 'fr' ? ios_fr_gif : ios_en_gif} alt='gif' style={{height: '320px'}}/> : <img src={i18n.language === 'fr' ? android_fr_gif : android_en_gif} alt='gif' style={{height: '320px'}}/>}
            
          </Flex>
        <Flex textAlign={'center'} py={'10px'}>
           <ShowInfo/>
        </Flex>
          <Box>
        
            <Button mb="12px"  width={'100%'} fontSize="12px" color='#304358' fontFamily={'Mulish'} onClick={remindMeHanler}>
                 {t('onesignal.remind_btn')}
              </Button>
            <br />
            <Button width={'100%'} fontSize={'12px'} color='#304358' fontFamily={'Mulish'} onClick={cancelHandler}>
                 {t('onesignal.cancel_btn')}
              </Button>
          </Box>
        </AlertDialogBody>
        <br />
    </AlertDialogContent>
</AlertDialog>
  );
};

export default AddToHomeScreenBanner;
