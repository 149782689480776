import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  ActiveQuestionMessage,
  AnswersItem,
} from "../../app/dto/active_question.dto";
import { MessageShortType } from "../../app/dto";
import { useTranslation } from "react-i18next";
import { CloseIconCustom } from "../../components/icons";

export interface QuestionnaireMessageProps {
  activeQuestion: ActiveQuestionMessage;
  isLastMessage: boolean;
  setLoadingMessages: Function;
  setInitialScroll: Function; 
}

export const QuestionnaireMessage: React.FC<QuestionnaireMessageProps> = ({
  activeQuestion,
  isLastMessage,
  setLoadingMessages, 
  setInitialScroll,
}: QuestionnaireMessageProps) => {
  const { state, messageService, appService }: AppContextInterface = useContext(AppContext);
  const [sendMessage, setSendMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const lang = i18n.language === "en" ? "text_en" : "text_fr";
  const noteLang = i18n.language === "en" ? "note_en" : "note_fr";

  const file = state.app.questionnaireFile; 

  const handleErrorNotification = (title: string, description?: string) => {
    toast({
      title,
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const handlerClick = async (answer: AnswersItem | null) => {
    if (state.session.episode.patient?.id) {
      if (activeQuestion.type === "upload" && !file) {
        handleErrorNotification(t("questionnaire.error"), t("questionnaire.not_upload_file"));
        return;
      }

      setLoading(true);
      let text: any = "";
      let answerId = 0;
      if (answer) {
        text = answer[lang];
        answerId = answer.id;
      }
      const message: MessageShortType = {
        author: state.session.episode.patient,
        contents: text,
        created_at: new Date().toISOString(),
        sender: "patient",
      };

      if (activeQuestion.type === "upload" && file) {
        message.documents = file;
      }
      const currentFile = file ? file : null;

      const result: any = await messageService.answerQuestionnaire(
        state.session.episode.patient.id,
        state.session.episode.id,
        +activeQuestion.id,
        answerId,
        text,
        currentFile,
        state?.session?.token
      );
      appService.uploadQuestionnaireFile(null); 
      appService.clearUploadFile(true); 
      setLoading(false);
      if (result.status < 300) {
        await messageService.addMessageToState(message);
        setSendMessage(true);
      } else {
        handleErrorNotification(t("questionnaire.something_wrong"));
      }
    }
  };

  const removeMessage = async () => {
    setLoading(true);
    let res = await messageService.answerUndo(
      state?.session?.token,
      state?.session?.episode?.id
    );
    setLoading(false);
    if(state.message.messages?.length < 8) {
      setLoadingMessages(true); 
      messageService.clearMessages(); 
      await messageService.getMessages(state.session.token, 1); 
      setLoadingMessages(false); 
      setInitialScroll(true); 
    }
  };

  useEffect(() => {
    if (isLastMessage && sendMessage) {
      setSendMessage(false);
    }
    if (!isLastMessage && !sendMessage) {
      setSendMessage(true);
    }
  }, [isLastMessage]);

  const clearUploadFile = () => {
    appService.uploadQuestionnaireFile(null); 
  }

  const clickUploadFile = () => {
    appService.clickUploadFile(true); 
  }

  return (
    <>
      {!sendMessage && (
        <Box>
          {activeQuestion.type === "select" && (
            <Text marginTop="5px" fontSize="12px" color="#304358" opacity={0.8}>
              {`(${t("questionnaire.click_answer")})`}
            </Text>
          )}

          {activeQuestion.type === "text" && (
            <Text marginTop="5px" fontSize="12px" color="#304358" opacity={0.8}>
              {`(${t("questionnaire.type_text")})`}
            </Text>
          )}

          {!loading ? (
            <>
            <Flex
              mt="7px"
              flexDirection={
                activeQuestion.type === "upload" ? "column" : "row"
              }
              wrap={activeQuestion.type === "upload" ? "nowrap" : "wrap"}
            >
              <>
                {activeQuestion.answers.length ? (
                  activeQuestion.answers.map((answer) => (
                    <Box
                      my="3px"
                      mr="10px"
                      key={answer.id}
                      padding="10px"
                      position="relative"
                      borderColor="#D5DFDE"
                      borderWidth="1px"
                      borderRadius="lg"
                      cursor="pointer"
                      backgroundColor="#A3B2B1"
                      onClick={() => handlerClick(answer)}
                      _hover={{ backgroundColor: "#40B89C" }}
                    >
                      <Text
                        color="white"
                        fontSize="14px"
                        fontFamily={"Mulish"}
                      >
                        {answer[lang]}
                      </Text>
                      {`${answer.id}` === "someone_else" && activeQuestion[noteLang] && <Box position="absolute" top={"8px"} right={"4px"} color="white" fontSize="14px">*</Box>}
                    </Box>
                  ))
                ) : (
                  <Box></Box>
                )}

                {activeQuestion[noteLang] && <Box>
                    <Text fontSize="12px" color="#304358" opacity={0.8}>{`*${activeQuestion[noteLang]}`}</Text>
                  </Box>}
                {activeQuestion.type === "upload" && (
                  <UploadFileComponent
                    file={file}
                    clearUploadFile={clearUploadFile}
                    clickUploadFile={clickUploadFile}
                    handlerClick={handlerClick}
                    notUploadText={t("questionnaire.not_upload_file")}
                  />
                )}
              </>
            </Flex>
              <Box
                  w={activeQuestion.type === "upload" ? "100%" : "auto"}
                  display="flex"
                >
                {!activeQuestion.is_first_question &&
                  activeQuestion.type !== "info" && (
                    <Button
                      my="3px"
                      mr="5px"
                      padding="10px"
                      display="flex"
                      justifyContent="center"
                      borderColor="#D5DFDE"
                      borderWidth="1px"
                      borderRadius="lg"
                      cursor="pointer"
                      textTransform="none"
                      onClick={() => removeMessage()}
                      backgroundColor="#A3B2B1"
                      _hover={{ backgroundColor: "#40B89C" }}
                    >
                      <Text
                        color="white"
                        display="flex"
                        alignItems="center"
                        fontWeight="normal"
                        fontSize={{base: "12px", md:"14px"}}
                        fontFamily={"Mulish"}
                      >
                        {" "}
                        <ArrowBackIcon /> {t("chatScreen.back_btn")}
                      </Text>
                    </Button>
                  )}
              </Box>
            </>
          ) : (
            <Box
              padding="10px"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

type UploadFileProps = {
  file: Array<any> | null;
  clickUploadFile: Function;
  notUploadText: string; 
  clearUploadFile: Function;
  handlerClick: Function;
};

const UploadFileComponent = ({
  file,
  clickUploadFile,
  clearUploadFile,
  handlerClick,
  notUploadText, 
}: UploadFileProps) => {
  const { t } = useTranslation();

  const getFileName = (name: string) => {
    if (name?.length < 25) {
      return name;
    } else {
      return name?.split(".")[0];
    }
  };

  const handlerUploadClick = () => {
    if(!file) {
      clickUploadFile(); 
    } else {
      clearUploadFile(); 
    }
  }
  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex justifyContent="space-between" width="100%">
          <Flex
            width={file ? "70%" : "100%"}
            flexGrow={1}
            padding="10px"
            fontSize="14px"
            justifyContent="center"
            alignItems="center"
          >
            <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              {file && file[0]?.name
                ? getFileName(file[0]?.name)
                : notUploadText}
            </Text>
            {file && file[0]?.name?.length > 25 && (
              <Text as="span" ml="-5px">{`.${
                file[0]?.name?.split(".")[1]
              }`}</Text>
            )}
          </Flex>
        </Flex>
      </Box>
      <Flex>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="3px"
          mr="5px"
          padding="10px"
          onClick={handlerUploadClick}
          borderColor="#D5DFDE"
          borderWidth="1px"
          borderRadius="lg"
          flexGrow={1}
          cursor="pointer"
          color="white"
          fontSize="14px"
          backgroundColor={!file ? "#A3B2B1" : "#d55252"}
          _hover={{ backgroundColor: !file ? "#40B89C" : "#d55252" }}
        >
         {!file ? <>
              <img
                alt="camera"
                style={{ height: "16px", marginRight: "5px" }}
                src={`${process.env.PUBLIC_URL}/camera.svg`}
              />
           </> 
           : <CloseIconCustom width="14px" height="14px" />} 
        </Box>

        <Box
          backgroundColor="#40B89C"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          my="3px"
          borderRadius="lg"
          color="white"
          fontSize="14px"
          cursor="pointer"
          mr="5px"
          onClick={() => handlerClick(null)}
        >
          {t("questionnaire.send")}
        </Box>
      </Flex>
    </Box>
  );
};
