import { Box, Center, Divider, Flex, Text } from '@chakra-ui/layout'
import React, { useContext, useRef, useState, useEffect } from 'react'
import RegisterLaoyut from './RegisterLaoyut'
import {Formik} from 'formik'
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Stack, HStack, VStack, PinInput, PinInputField, useMediaQuery } from "@chakra-ui/react"
import RadioFields from '../../components/radioFields'
import { handlerLocaleStorage } from '../../utils/helpers'
import { useHistory } from 'react-router'
import { RegisterContext } from '../../app/navigation'
import GoogleMapComponent from '../../components/googleMapComponets'
import { useRegisterFlowContext } from '..';


function Step2_2(props: any) {

  const formRef = useRef<any>(); 
  const history = useHistory();
  const {t} = useTranslation();
  const [isHigerThan760] = useMediaQuery("(min-heigth: 760px)")

  const resultLocation = sessionStorage.getItem("location") || "{}"; 
  const locationStorage = JSON.parse(`${resultLocation}`);
  const [location, setLocation] = useState<any>(locationStorage?.step_2 ? locationStorage.step_2 : locationStorage); 
  const optionsRadio = [t(`registerForm.fdSection.yes`), t(`registerForm.fdSection.no`)]
  const {setIsHideContent, isHideContent} = useRegisterFlowContext();

   const ValidationSchema = Yup.object().shape({ 
     has_pharma: Yup.boolean()
      .required('Field is required'),
    });
      
    let registerValues = JSON.parse(sessionStorage.getItem('register') || '{}');
    const initialValuesBase = {
       has_pharma: "", 
       pharma_name: "",
       pharma_phone_number: "",
       pharma_fax_number: "",
       pharma_address: "",
       pharma_email: ""
    };
    
    let initialValues = registerValues['step_4'] ? registerValues['step_4']  : initialValuesBase; 
    let has_pharma = initialValues.has_pharma;
    let result = JSON.parse(sessionStorage.getItem("name_map") || '{}');
   
    const getPreviusValue = (value: any, options: any) => {
      if(value === true) {
        return options[0]
      }
      if(value === false) {
        return options[1]
      }
      return ""; 
    }

    const setValueHide = (value: any) => {
      if(value === "") {
        return false
      }
      if (value === true) {
        return false
      }
      return true; 
    }

    const[radioValue, setRadioValue]=useState(getPreviusValue(has_pharma, optionsRadio));
    
    const [isHideMap, setIsHideMap] = useState(setValueHide(has_pharma)); 
    const [value, setValues] = useState(initialValues); 
  
    const slug = useContext(RegisterContext); 

    useEffect(() => {
      setIsHideContent(setValueHide(has_pharma));
    }, []);
    

    const handler = (value:any) => {
      setRadioValue(value); 
      if(value === optionsRadio[1]) {
        setIsHideMap(true); 
        setIsHideContent(true); 
        formRef?.current?.setFieldValue(`has_pharma`, false); 
        setValues(initialValuesBase);
        let newMapConfig = JSON.parse(`${sessionStorage.getItem("name_map")}`);
        if(newMapConfig?.patient_pharma) {
          delete newMapConfig?.patient_pharma; 
          sessionStorage.setItem("name_map", JSON.stringify(newMapConfig));
        }
      } else {
        setIsHideMap(false); 
        setIsHideContent(false); 
        formRef?.current?.setFieldValue(`has_pharma`, true); 
      }
    }

    const saveData = () => {
      formRef?.current?.handleSubmit(); 
      locationStorage.step_2 = location; 
      sessionStorage.setItem("location", JSON.stringify(locationStorage)); 
    }

    const handlerGoogleMap = (values: any) => {
      let result = {} as any; 
      formRef?.current?.setFieldValue(`has_pharma`, true);
      result.pharma_name = values.name || ""; 
      result.pharma_phone_number = values.formatted_phone_number || ""; 
      result.pharma_fax_number = "";
      result.pharma_address = values.formatted_address || ""; 
      result.pharma_email = ""; 
      setValues(result); 
      sessionStorage.setItem("name_map", JSON.stringify({patient_pharma: values.formatted_address}));
    }

    function onKeyDown(keyEvent: any) {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
      }
    }

    const bottomRange = window.innerHeight - 450;  
    return (
       <RegisterLaoyut step={4} handlerSubmit={saveData} previous_btn={t('new_register.previous_question')} goBack={() => history.push(`/register/${3}${slug}`)}>
           <Box pt={{base: '40px'}} >
             <Text color="#40B89C" fontWeight="700">{t(`new_register.step_2_location_pharmacy`)}</Text>
             <Text fontWeight="700" fontSize={{base: "32px", '2xl': "40px"}}  color="#304358">{t('new_register.current_step', {step1: 2, step2: 2})} {t(`new_register.your_pharmacy`)}</Text>
           </Box>
  
           <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              let result = {
                ...value, 
                has_pharma: values.has_pharma,
              }
              handlerLocaleStorage(result, 'step_4'); 
              history.push(`/register/${5}${slug}`)
            }} >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
            setTouched,
            isSubmitting,
            touched,
            values,
          }) => (
          <form onKeyDown={onKeyDown} onSubmit={handleSubmit} style={{height: "100%"}}>

             <VStack spacing={{base: 'unset', md:"20px"}} mt={{base:"56px", md: "16px"}}  width="100%"  >
              <Flex w="100%" mb={{lg: (isHideMap && !isHigerThan760) ? `${bottomRange}px` : "0px"  }}>
                 <RadioFields 
                    label={t(`new_register.pharmacy_label`)}
                    isHelperText
                    options={optionsRadio} 
                    error={errors.has_pharma && touched.has_pharma}
                    name="has_pharma" 
                    handler={(value:any)=> handler(value)}
                    defaultValue={radioValue}/>
              </Flex>

              {!isHideMap && <HStack width="100%" display="flex" flexDirection="column">
                  <Box w="100%" h={{base: "200px", md:"250px", "2xl": "460px" }}   position="relative" mt={{base: '25px', md: "unset"}} mb={{base: '40px', md: "unset"}}>
                    <GoogleMapComponent 
                      isEstablishment={true} 
                      setLocation={setLocation}
                      location={location}
                      label={t('new_register.pharmacy_search')}
                      name_map={result?.patient_pharma} 
                      handler={handlerGoogleMap}/>
                  </Box>
                </HStack>}
              </VStack> 
         </form>
         )}
        </Formik>  
       </RegisterLaoyut>

    )
}

export default Step2_2
