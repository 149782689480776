import { Box, Center, Divider, Flex, Text } from '@chakra-ui/layout'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '../../components/textField'
import RegisterLaoyut from './RegisterLaoyut'
import {Formik} from 'formik'
import * as Yup from 'yup';
import { Stack, HStack, VStack, PinInput, PinInputField, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import PinFields from '../../components/pinFields'
import RadioFields from '../../components/radioFields'
import InputDropdown from '../../components/inputDropdown'
import SelectComponent from '../../components/select'
import PasswordField from '../../components/passwordField'
import { handlerLocaleStorage } from '../../utils/helpers'
import { useHistory } from 'react-router'
import { RegisterContext } from '../../app/navigation'
import CheckboxField from '../../components/checkboxField'
import TextAreaField from '../../components/textAreaField'
import { useRegisterFlowContext } from '.'



function Step7(props: any) {

  const formRef = useRef<any>(); 
  const history = useHistory();
  const {t} = useTranslation();

  const [isHigherThan800] = useMediaQuery("(min-height: 800px)")

   const ValidationSchema = Yup.object().shape({ 
      has_allergies: Yup.boolean()
      .required('Field is required'),
      has_meds: Yup.boolean()
      .required('Field is required'),
      allergies: Yup.string()
      .max(200),
      meds: Yup.string()
      .max(200)
    });
 
    let registerValues = JSON.parse(sessionStorage.getItem('register') || '{}');
    const initialValuesBase = {
        has_allergies: "",
        has_meds: "",
        allergies: "",
        meds: "",
    };

    let initialValues = registerValues['step_6'] ? registerValues['step_6']  : initialValuesBase; 
    let has_allergies = initialValues.has_allergies;
    let has_meds = initialValues.has_meds;


    const slug = useContext(RegisterContext); 
    const {setIsHideContent, isHideContent} = useRegisterFlowContext();

    const optionsRadio = [t(`registerForm.fdSection.yes`), t(`registerForm.fdSection.no`)]; 

    const getPreviusValue = (value: any, options: any) => {
      if(value === true) {
        return options[0]
      }
      if(value === false) {
        return options[1]
      }
      return ""; 
    }

    const setValueHide = (value: any) => {
      if(value === "") {
        return true
      }
      if (value === true) {
        return true
      }
      return false; 
    }

    const [radioValue, setRadioValue] = useState(getPreviusValue(has_allergies, optionsRadio)); 
    const [radioMeds, setRadioMeds] = useState(getPreviusValue(has_meds, optionsRadio)); 
    const [isHideSection, setIsHideSection] = useState(setValueHide(has_allergies)); 
    const [isHideMedicale, setIsHideMedicale] = useState(setValueHide(has_meds)); 

    const handler = (value:any) => {
        if(value === optionsRadio[0]) {
            setIsHideSection(true); 
            formRef?.current?.setFieldValue(`has_allergies`, true); 
        } 
        if(value === optionsRadio[1]){
            setIsHideSection(false); 
            formRef?.current?.setFieldValue(`has_allergies`, false); 
        }
    }

    const handlerMedicale = (value: any) => {
        if(value === optionsRadio[0]) {
            setIsHideMedicale(true); 
            formRef?.current?.setFieldValue(`has_meds`, true); 
        } 
        if(value === optionsRadio[1]){
            setIsHideMedicale(false); 
            formRef?.current?.setFieldValue(`has_meds`, false); 
        }
        
    }

    useEffect(() => {
      if(!setValueHide(has_allergies) && !setValueHide(has_meds)) {
        setIsHideContent(true);
      } else {
        setIsHideContent(false);
      }
    }, []);

    useEffect(() => {

      if(!isHideContent && !isHideMedicale && !isHideSection) {
        setIsHideContent(true); 
      } else if((isHideContent && isHideMedicale) || (isHideContent && isHideSection)) {
        setIsHideContent(false); 
      }
    }, [isHideMedicale, isHideSection]); 

    const saveData = async () => {
      formRef?.current?.handleSubmit(); 
    }
   
    return (
       <RegisterLaoyut step={6} handlerSubmit={saveData} goBack={() => history.push(`/register/${5}${slug}`)}>
           <Box pt={{base: '40px'}}>
             <Text color="#40B89C" fontWeight="700">{t(`new_register.step_3_medical_profile`)}</Text>
             <Text fontWeight="700" fontSize={{base: "32px", "2xl": "40px"}}  color="#304358">{t('new_register.current_step', {step1: 2, step2: 3})} {t(`new_register.step_2_3_title`)}
             </Text>
           </Box>
  
           <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              handlerLocaleStorage(values, 'step_6'); 
              history.push(`/register/${7}${slug}`) 

            }} >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
          <form onSubmit={handleSubmit}>

            <VStack spacing="20px" mt={{base:"32px", '2xl': "48px" }} width="100%"  mb={{base: "52px", lg: "50px"}}>
                <Flex w="100%" >
                  <Text color="#304358" fontSize="20px" fontWeight="700">
                    {t(`registerForm.allergiesQuestion`)}
                  </Text>
                  </Flex>
                <Flex w="100%">
                 <RadioFields 
                    label={""}
                    isHelperText
                    options={optionsRadio} 
                    error={errors.has_allergies && touched.has_allergies}
                    name="has_allergies" 
                    handler={(value:any)=> handler(value)}
                    defaultValue={radioValue}/>
              </Flex>

            {isHideSection && <HStack width={{base: "100%"}} display="flex"  justifyContent="flex-start"  spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                 <Flex w={{base: "100%", lg: "50%"}}>
                 <TextAreaField 
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t(`new_register.allergies_list`)}
                  onChange={handleChange}
                  value={values.allergies}
                  name="allergies"
                  label={t(`new_register.list_your_allergies`)}/>
                 </Flex>
              </HStack>}

           
                 <Flex w="100%">
                  <Text color="#304358" fontSize="20px" fontWeight="700">
                    {t('registerForm.medicationsQuestion')}
                    </Text>
                 </Flex>
                 <Flex w="100%">
                  <RadioFields 
                      label={''}
                      isHelperText
                      options={optionsRadio} 
                      error={errors.has_meds && touched.has_meds}
                      name="has_meds" 
                      handler={(value:any)=> handlerMedicale(value)}
                      defaultValue={radioMeds}/>
                 </Flex>   
      

         { isHideMedicale &&  <HStack width={{base: "100%"}} display="flex"  justifyContent="flex-start"  spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                 <Flex w={{base: "100%", lg: "50%"}}>
                 <TextAreaField 
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t(`new_register.medication_list`)}
                  onChange={handleChange}
                  value={values.meds}
                  name="meds"
                  label={t(`new_register.list_all_your_medications`)}/>
                 </Flex>
              </HStack>}
              
             </VStack> 

         </form>
         )}
        </Formik>  
       </RegisterLaoyut>

    )
}

export default Step7
