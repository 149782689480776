import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { TopRegister } from "../../components/topRegister";
import RegisterLaoyut from "../RegisterNew/RegisterLaoyut";
import { Button } from "@chakra-ui/button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import congrats_icon from "../../assets/icons/illus_congrats.svg";
import { ROUTE_PATH } from "../../app/routes";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { useContext } from "react";

export const SuccessRegister = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state, appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);

  const handleRedirect = async () => {
    const isPrimaryMember =
      state.session?.episode?.patient?.is_primary_member === 1;
    await appService.setFirstLogin(state.session?.token);   
    if (isPrimaryMember) {
      history.push(ROUTE_PATH.FAMILY_REGISTER);
    } else {
      history.push(ROUTE_PATH.CHAT_TYPE);
    }
  };
  return (
    <Container
      position="relative"
      //width="100vw"
      // height={{base: '100%', lg: "100vh"}}
      height={`${window.innerHeight}px`}
      maxWidth="auto"
      backgroundColor="#FFFFFF"
      display="flex"
      pl="0"
      pr="0"
      flexDirection="column"
      justifyContent="flex-start"
      overflowX="hidden"
      // alignItems="center"
    >
      <TopRegister showProgress={false} />
      <Divider />
      <Flex height="100%" justify="center" align="center">
        <Box
          height="100%"
          minW={{ base: "unset", lg: "1020px" }}
          w={{ base: "100%", xl: "55%" }}
          display="flex"
          alignItems="center"
        >
          <RegisterLaoyut step={9}>
            <Box pt={{ base: "40px", lg: "50px" }}>
              <Image
                src={congrats_icon}
                alt="congrats"
                mb={{ base: "46px", lg: "56px" }}
              />
              <Text
                mb={{ base: "16px" }}
                fontWeight="700"
                fontSize={{ base: "32px", "2xl": "40px" }}
                color="#304358"
              >
                {t(`new_register.success_create`)}
              </Text>
              <Text
                mb={{ base: "16px" }}
                fontSize="16px"
                color="#8194AA"
                width={{ base: "100%", md: "80%", lg: "60%" }}
              >
                {t(`new_register.finish_text`)}
              </Text>
              <Text
                mb={{ base: "16px" }}
                fontSize="16px"
                color="#8194AA"
                width={{ base: "100%", md: "80%", lg: "60%" }}
              >
                {t(`new_register.finish_text_2`)}
              </Text>
              <Text
                mb={{ base: "16px" }}
                fontSize="16px"
                color="#8194AA"
                width={{ base: "100%", md: "80%", lg: "60%" }}
              >
                {t(`new_register.finish_text_3`)}{" "}
                <Text
                  as="span"
                  fontSize="16px"
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      `mailto:${t("invalidTokenSupportDialog.helpContact")}`,
                      "_blank"
                    )
                  }
                  color="#40B89C"
                  fontFamily={"Mulish"}
                >
                  {t("invalidTokenSupportDialog.helpContact")}.
                </Text>{" "}
              </Text>
              <Text
                mb={{ base: "16px" }}
                fontSize="16px"
                color="#8194AA"
                width={{ base: "100%", md: "80%", lg: "60%" }}
              >
                {t(`new_register.finish_text_4`)}
                <Text as="span" color="#304358">
                  {t(`new_register.finish_text_5`)}
                </Text>
              </Text>
            </Box>
            <HStack width="100%" wrap={{ base: "wrap", md: "nowrap" }}>
              <Box
                display="flex"
                alignItems="center"
                width={{ base: "100%", md: "50%" }}
                pt="15px"
                pr={{ md: "20px", lg: "34px" }}
                //pb={{ base: "40px", md: "100px" }}
              >
                <Button
                  onClick={handleRedirect}
                  rightIcon={<ArrowForwardIcon />}
                  fontSize="16px"
                  textTransform="none"
                  fontFamily="Mulish"
                  color="#304358"
                  fontWeight="700"
                  borderRadius="10pt"
                  backgroundColor="#FFDB00"
                  variant="solid"
                >
                  {t(`new_register.btn_success`)}
                </Button>
              </Box>
            </HStack>
          </RegisterLaoyut>
        </Box>
        <Box
          w="45%"
          height="100%"
          display={{ base: "none", lg: "block" }}
          bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)"
        ></Box>
      </Flex>
    </Container>
  );
};
