import React, { useContext, useEffect, useRef, useState } from 'react';
import { Center, Container, HStack, Text, VStack } from '@chakra-ui/layout';
import { Input, InputGroup } from '@chakra-ui/input';
import { Image } from '@chakra-ui/image';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import { Box, Flex} from '@chakra-ui/react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { Divider, useBreakpointValue, useMediaQuery, useDisclosure, } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../app/routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/textField';
import logo_icon from '../../assets/icons/logo_icon_register.svg';
import PasswordField from '../../components/passwordField';
import { ArrowForwardIcon, RepeatIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { SupportDialog } from '../../components/support.dialog';
import logo_icon_fr from "../../assets/icons/logo_icon_register_fr.svg";
import {useEffectOnce, useUnmount} from 'react-use';
import LogRocket from 'logrocket';
import { EmailDisklaimerDialog } from '../../components/emailDisklaimerDialog';



function ForgotPasswordNew() {
    const [ language, setLanguage ] = useState<'en' | 'fr'>('fr');
    const [isLogin, setIsLogin] = useState(false); 

    const { t, i18n } = useTranslation();

    const { state,  appService  }: AppContextInterface = useContext(AppContext);

    const {pathname} = useLocation();
    const display = useBreakpointValue({base: "none", lg: 'block'});
    const base = ROUTE_PATH.REGISTER_NEW;
    const options = ["Female", "Male"];

    const [key, setKey] = useState();


    const ValidationSchema = Yup.object().shape({
      email: Yup.string()
      .email()
      .required('Field is required'),
      captcha: Yup.string().required('Field is required')
    });

    let initialValues = {
        email: "",
        captcha: "",
      };

    const history = useHistory();

    const [isLargerThan1400] = useMediaQuery("(min-width: 1400px)")
    const [isLargerThan1560] = useMediaQuery("(min-width: 1560px)")
    const [isLargerThan760] = useMediaQuery("(min-width: 760px)")

    const changeLanguage = ( lang: 'en' | 'fr' ) => {
      if ( language !== 'en' && lang === 'en' ) {
          setLanguage('en');
          i18n.changeLanguage('en')
      }
      else if ( language !== 'fr' && lang === 'fr' ) {
          setLanguage('fr');
          i18n.changeLanguage('fr');
      }
  }

  const handlerRedirect = () => {
    if(!state.session) {
      window.location.href = "https://tapmedical.ca/en/allhealth";
    } else {
      window.location.href = ROUTE_PATH.CHAT_TYPE;
    }
  }
    const stylesProp = {
        size_text:'16px', 
        circle_mg:'16px', 
        display_hide: useBreakpointValue({base: 'inline-block', lg: "none"}),
        display_hide_lg: useBreakpointValue({base: 'flex', lg: "none"}),
    }

    const cancelRef = React.useRef();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleOpenDisclaimer = (isLoginCurrent: boolean) => {
      setIsLogin(isLoginCurrent); 
      onOpen(); 
    }


    const captchaRef: any = useRef(null);

    useEffect(() => {
        refreshCaptcha();
    }, []);

    const refreshCaptcha = () => appService.captcha().then(
        (rs :any) => {
            if ( captchaRef.current && rs?.data ) {
                captchaRef.current.src = rs?.data?.img;
                setKey(rs?.data?.key);
            }
        }
    );

    useEffect(() => {
        if(i18n.language && i18n.language !== language) {
            let lang = i18n?.language;
            if(lang === "en") setLanguage("en");
            if(lang === "fr") setLanguage("fr");
        }
    })
    useUnmount(() => {
      appService.clearError();
    })

    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    const setSizeWrap = () => {
        const step2Resize = window.innerWidth >= 1024 && window.innerHeight >= 650;
        const step3Resize = window.innerWidth >= 1560 && window.innerHeight >= 650;
        if(step2Resize || step3Resize){
            return "100vh"
        }
        return '100%';
    }

    const handlerClick = async (values: any) => {
         const sent: boolean = await appService.sendResetLink( values, key );
        if ( sent === true ){
          history.push(ROUTE_PATH.RESET_LINK_SENT_NEW);
        }

        refreshCaptcha();
    }

    
    return (
      <>
      {/*  <SupportDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} /> */}
       <EmailDisklaimerDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} isLogin={isLogin}/>
        <Container
        position='relative'
        width="100vw"
        // height={{base: '100%', md: "100vh"}}
        height={setSizeWrap()}
        minH={{base: "100vh", lg: "100%"}}
        maxWidth='auto'
        backgroundColor="#FFFFFF"
        display="flex"
        pl="0"
        pr="0"
        flexDirection="column"
        justifyContent="flex-start"
        overflowX="hidden"
        >
          <Container
            maxW='100%'
            display="flex"
            flexDirection={{base: 'column', lg: "row"}}
            mt={{base: "22px", md: '32px'}}
            mb={{base: "30px", lg: '30px', "2xl": '30px'}}
            width="100%"
            px={{base: "16px", md: "40px", "2xl": "80px"}}
            justifyContent="space-between"
            zIndex="10">
            <Box
                display="flex"
                justifyContent="space-between"
                ps={{base: "16px", '2xl': 'unset'}}
                flexWrap={{base:'wrap', sm: 'nowrap'}}
                //pl={{base: "16px", md:'80px', lg:'unset'}}
                //pb={{base: "16px", lg: "unset"}}
                >
              <Image
                alt="all-health"
                style={{ cursor: 'pointer' }}
                onClick={handlerRedirect}
                // src={logo_icon}
                src={`${t('lang') === 'en' ? logo_icon : logo_icon_fr}`}
              />
              <Box display={stylesProp.display_hide_lg}
                   width={{base: '100%', sm: 'auto'}}
                   mt={{base: '15px', sm: 'unset'}}
                   justifyContent={{base: 'space-between', sm: 'unset'}}>
                  <Text color="#8194AA">{t('new_register.top_bar_question')}               <Text as="span" color="#40B89C" cursor="pointer" fontSize={stylesProp.size_text} onClick={() => handleOpenDisclaimer(false)}>
                 {t(`new_register.email_us`)}
                </Text></Text>
                <Text onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')} as="span" color="#8194AA"> | {t('lang') === 'en' ? 'FR':'EN'}</Text>
               </Box>
            </Box>


            <Box display={display}>
                <Text color="#8194AA" fontSize={stylesProp.size_text}>
                  {t(`new_register.top_bar_question`)}
                  <Text as="span" color="#40B89C" cursor="pointer" onClick={() => handleOpenDisclaimer(false)}>&nbsp;{t('new_register.email_us')}&nbsp;</Text>
                <Text cursor="pointer" onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')} as="span" color="#8194AA"> | {t('lang') === 'en' ? 'FR':'EN'}</Text>
                </Text>
            </Box>
            </Container>


            <Divider />
            <Flex w='100%' h="100%" justify="center" align="center">

                 <Box minW={{base: "unset", lg: "1020px" }} h="100%" w={{base: '100%', xl: '55%'}}   display="flex" alignItems="center">
                 <Box h="100%" width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                    <Flex  pb={{md: "60px", lg:'unset'}} style={{height: `calc(100% - 105px - 90px)`}} direction="column" justify="center">
                      <Box px={{base: "16px", md: "40px"}} pt={{base: '10px', md: '70px', lg: '80px', xl: '90px', '2xl': '50px'}}>
                       <Text fontWeight="700" fontSize={{base: "32px", md: "40px"}} color="#304358">{t('forgotPasswordScreen.pageTitle')}</Text>
                       <Text fontSize="16px" color="#8194AA">
                            {t('forgotPasswordScreen.subTitle')}
                       </Text>
                     </Box>

                        { state.app.isErrored === true &&
                        <VStack px={{base: "16px", md: "40px"}} spacing={{base: 'unset', md:"20px"}} mt={{base:"32px", lg: "40px" }} align="start" width="100%">
                            <HStack width={{base: "100%", lg: "50%"}} justifyContent="flex-start" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                                <Container width={'100%'} maxWidth={'100%'} ml={'0'} background={'#FFE2E8'} borderRadius={'13px'}>
                                    <Text mt={'8px'} mb={'6px'} color={'#F44970'} textAlign={'center'} fontFamily={'Mulish'} fontSize={'18px'}>
                                        { t(state.app.errorMessage || '') }
                                    </Text>
                                </Container>
                            </HStack>
                        </VStack>}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={ValidationSchema}
                        onSubmit={(values) => {

                          handlerClick(values);
                        }} >
                        {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        setValues,
                        setTouched,
                        isSubmitting,
                        touched,
                        values,
                        }) => (
                        <form onSubmit={handleSubmit}>

                        <VStack px={{base: "16px", md: "40px"}} spacing={{base: 'unset', md:"20px"}} mt={{base:"32px", lg: "40px" }} align="start" mb={{ base:"unset", lg: "30px", xl: "50px", '2xl': '50px'}} width="100%">

                         <HStack width={{base: "100%", lg: "50%"}} justifyContent="flex-start" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                            <TextField
                                mb={{base: '24px', md:'unset'}}
                                placeholder= {t('forgotPasswordScreen.labelEmailInputText')}
                                data-private
                                error={errors.email}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                label= {t('forgotPasswordScreen.labelEmailInputText')}/>
                          </HStack>

                          <Text fontSize="12px"
                              fontFamily="Mulish"
                              color= "#304358">
                              {t('forgotPasswordScreen.labelCaptchaInputText')}</Text>

                          <Container ml={3} mt="10px !important" mb="10px !important" display='flex'>
                            <Image ref={captchaRef} />
                            <Button background='transparent' onClick={refreshCaptcha}>
                                <RepeatIcon />
                            </Button>
                           </Container>

                          <HStack mt="10px !important"  width={{base: "100%", lg: "50%"}} spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                             <TextField
                                mb={{base: '24px', md:'unset'}}
                                placeholder={`${t('forgotPasswordScreen.captchaInputTextPlaceholder')}`}
                                error={errors.captcha}
                                onChange={handleChange}
                                value={values.captcha}
                                name="captcha"
                                label=""/>

                          </HStack>

                          <HStack width={{base: "100%", lg: "100%"}} justifyContent={{base: "center", md: "flex-start"}} spacing={{base: "0px", md: "20px", lg: "32px"}}  wrap={{base: "wrap", md: "nowrap"}} pt={{base: "32px"}}>
                           {isLargerThan760 && <Button
                                type="submit"
                                rightIcon={<ArrowForwardIcon />}
                                fontSize="16px"
                                textTransform="none"
                                fontFamily="Mulish"
                                color="#304358"
                                fontWeight="700"
                                borderRadius="10pt"
                                backgroundColor="#FFDB00"
                                variant="solid">
                                    {t('forgotPasswordScreen.btnResetPasswordText')}
                                </Button>}

                                <Button
                                onClick={() => handleOpenDisclaimer(true)}
                                fontSize="16px"
                                textTransform="none"
                                textDecoration="underline"
                                fontFamily="Mulish"
                                color="#304358"
                                fontWeight="700"
                                borderRadius="none"
                                px="8px"
                                py="4px"
                                cursor="pointer"
                                height="auto"
                                backgroundColor="#FFF19A"
                                variant="solid">
                                   {t('forgotPasswordScreen.linkForgotCredsText')}
                                </Button>
                          </HStack>

                        </VStack>
                       {!isLargerThan760 &&  <Flex align="center" h={{base: "auto", md: "105px"}} pt={{base: '25px', lg:"inset"}} px={{base: "0px", md: "20px", lg: '40px', '2xl': "80px"}} pb={{base: "35px", md: "0px"}} justify={{base: 'center', md:"space-between"}}  direction={{base: 'column', md: 'row'}}>
                          <Text color="#8194AA" textAlign="center" fontSize="16px" width={{base: '280px', md: 'unset'}} my={{base: '24px', md: 'unset'}}>
                              { t(`new_register.bottom_questionForgotPass`)}
                          <Text as="span" color="#40B89C">
                            <Link to="/"> { t(`new_register.bottom_text_link`)}</Link>
                            </Text>
                          </Text>

                          <Flex width={{base: '100%', md: 'unset'}}></Flex>
                        </Flex>}
                        {!isLargerThan760 && <Button
                            type="submit"
                            onClick={handlerClick}
                            rightIcon={<ArrowForwardIcon />}
                            fontSize="16px"
                            textTransform="none"
                            fontFamily="Mulish"
                            color="#304358"
                            fontWeight="700"
                            width="100%"
                            borderRadius="none"
                            backgroundColor="#FFDB00"
                            position="absolute"
                            bottom="0"
                            variant="solid">
                              {t('forgotPasswordScreen.btnResetPasswordText')}
                            </Button>}


                        </form>
                        )}
                        </Formik>
                    </Flex>
                   {/*  {isLargerThan760 && <Divider/>} */}

                    { isLargerThan760 &&  <Flex align="center" h={{base: "auto", md: "105px"}} px={{base: "0px", md: "20px", lg: '40px', '2xl': "80px"}} justify={{base: 'center', md:"space-between"}} direction={{base: 'column', md: 'row'}} borderTop="1px solid #D3D9E0">

                        <Text color="#8194AA" textAlign="center" fontSize="16px" width={{base: '280px', md: 'unset'}} my={{base: '24px', md: 'unset'}}>
                          { t(`new_register.bottom_questionForgotPass`)}
                        <Text as="span" color="#40B89C">
                          <Link to="/"> { t(`new_register.bottom_text_link`)}</Link>
                          </Text>
                        </Text>

                    <Flex width={{base: '100%', md: 'unset'}}></Flex>
                    </Flex>}
                   </Box>


                  </Box>

                   <Box
                    w="45%"
                    height="100%"
                    display={display}
                    bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)"
                    >
                 </Box>

            </Flex>
       </Container>
       </>
    )
}

export default ForgotPasswordNew
