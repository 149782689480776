import { MessageShortType } from "../app/dto";

export function mimeFromDataUrl(dataurl: string){
    let base64ContentArray: any = dataurl.split(",");   
    // base64 content cannot contain whitespaces but nevertheless skip if there are!
    let mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
    return mimeType;
}

export function handlerLocaleStorage (values: Object, nameStep: string) {
  let registerValues = JSON.parse(sessionStorage.getItem('register') || '{}');
  registerValues[nameStep] = values; 
  sessionStorage.setItem('register', JSON.stringify(registerValues));  
}

export function createDateHandler (name: string, value: string, formRef: any) {
  const { day, month, year } = formRef?.current?.values; 
  const newDate = {
    day: day,
    month: month,
    year: year, 
  } as any; 
  newDate[name] = value;
  if(newDate.day && newDate.month && newDate.year) {
    let expiration_date = `${newDate.year}-${newDate.month}-${newDate.day}`;
    formRef?.current?.setFieldValue("expiration_date", expiration_date); 
  }
} 

export function changeLanguage ( lang: 'en' | 'fr', language: string, handler: Function, i18n: any ) {
  if ( language !== 'en' && lang === 'en' ) {
      handler('en');
      i18n.changeLanguage('en')
  }
  else if ( language !== 'fr' && lang === 'fr' ) {
      handler('fr');
      i18n.changeLanguage('fr');
  }
}


export function createMessage (value: string, state: any):MessageShortType {
  return {
    contents: value,
    author: state.session?.episode?.patient,
    created_at: new Date().toISOString(),
    sender: "patient",
  }
}

export const checkIsIOS = (): Boolean => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.userAgent === 'MacIntel' && navigator.maxTouchPoints > 1); 
  return isIOS; 
};