import React, { FC } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EmailIcon } from '@chakra-ui/icons';

export type InvalidTokenSupportDialogProps = {
    cancelRef: any;
    onClose: () => void;
    isOpen: boolean;
    defaultSupportMessage?: string;
};

export const InvalidTokenSupportDialog: FC<InvalidTokenSupportDialogProps> = ({
    cancelRef, onClose, isOpen, defaultSupportMessage
}: InvalidTokenSupportDialogProps) => {

    const { t } = useTranslation();

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered>
            <AlertDialogOverlay opacity={0.5} style={{ backdropFilter: 'blur(10px)', backgroundColor: 'transparent!important' }} />

            <AlertDialogContent borderRadius={'20px'} boxShadow={'0px 4pt 12pt #30435829'} width={'90%'}>
                <AlertDialogHeader textAlign='center' fontSize='18px' color='#304358' fontFamily={'Mulish'}>{t('invalidTokenSupportDialog.title')}</AlertDialogHeader>
                <br />
                <AlertDialogBody>
                    <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                        { defaultSupportMessage !== undefined ? defaultSupportMessage : t('invalidTokenSupportDialog.helpLabel')}
                    </Text>
                    <br />
                    <Text color='#304358' fontFamily={'Mulish'} fontWeight='bold' >
                        {t('invalidTokenSupportDialog.helpTitle')}
                    </Text>
                    <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                        {t('invalidTokenSupportDialog.helpContact')}
                    </Text>
                    <br />
                    <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                         {t('invalidTokenSupportDialog.helpText')}
                         <br/>
                        <a style={{color: "blue"}} href={`${t('invalidTokenSupportDialog.textLink')}`}>
                         {t('invalidTokenSupportDialog.textLink')}
                        </a>
                    </Text>
                    <br />
                </AlertDialogBody>
                <br />
                <AlertDialogFooter p={0}>
                    <Button borderBottomRadius={'20px'} borderTopRadius={0} color={'#304358'} fontFamily={'Mulish'} height='55px' ref={cancelRef} width='100%' onClick={() => window.open(`mailto:${t('invalidTokenSupportDialog.helpContact')}`, '_blank')} leftIcon={<EmailIcon />} >
                        <a href={`mailto:${t('invalidTokenSupportDialog.helpContact')}`}>
                        {t('invalidTokenSupportDialog.callBtnText')}
                        </a>
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
