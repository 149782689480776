export const MessageActions = {
    SENT:                   'MSG_01',
    ADD_AI_MESSAGE:         'MSG_02',
    DOCTOR_MESSAGE:         'MSG_03',
    SENT_COUNT:             'MSG_04',
    INCREMENT_SENT_COUNT:   'MSG_05',
    CONNECTED_TO_DOC:       'MSG_06',
    GET_MESSAGES:           'MSG_07',
    LOAD_MORE_MSG:          'MSG_08',
    CLEAR_ALL:              'MSG_09',
    REMOVE_MESSAGE:         'MSG_10', 
    UPDATE_QUESTIONNAIRE:   'MSG_11',
    SET_ENCOUNTER:          'MSG_12', 
    QUESTIONNIRE_COMPLETED: 'MSG_13', 
};
