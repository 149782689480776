import { useEffect } from "react";
import { AppService } from "../../app/modules/app";
import { ROUTE_PATH } from "../../app/routes";

type PasswordResetTokenValidatorType = {
    appService: AppService;
    token?: string;
    onErrorCallback: Function
};

export function usePasswordSetTokenValidator( { appService, token, onErrorCallback }: PasswordResetTokenValidatorType ){
    useEffect(() => {
        appService.checkSetToken(token || '')
            .catch((e: any) => {
                onErrorCallback()
            });
    },[]);
}

type FormHandlerType = {
    appService: AppService;
    token?: string;
    history: any;
    t: any;
};

export function useFormHandler({ appService, token, history, t }:FormHandlerType) {
    const onSetPasswordFormSubmit = async ( values: any ) => {
        const reset: boolean =  await appService.setPassword(values, token, t('lang'));
        if ( reset === true ) {
            appService.success('success.pwdSet');
            history.push(ROUTE_PATH.HOME);
        }
    };

    return [onSetPasswordFormSubmit];
}