import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { Box, Flex, List, ListItem, Text, ListIcon } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Image,
  Fade,
  useOutsideClick,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import show_password from "../assets/icons/show_password.svg";
import hide_password from "../assets/icons/hide_password.svg";
import { FiCheck, FiAlertCircle } from "react-icons/fi";
import { validationRules } from "./input/password/validation-rules";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

function PasswordField(props: any) {
  const ref = useRef<any>();
  const [open, setOpen] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

/*   useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  }); */

  const { t } = useTranslation();

  type RuleValidationMessageProps = {
    condition: boolean;
  };

  const RuleValidationIcon: React.FC<RuleValidationMessageProps> = ({
    condition,
  }: RuleValidationMessageProps) => {
    return condition === false ? (
      <ListIcon as={FiCheck} color={"#60CBB2"} />
    ) : (
      <ListIcon
        as={FiAlertCircle}
        color={!props.error ? "#ABB0BC" : "#F44970"}
      />
    );
  };

  const [field, meta] = useField(props);

/*   useEffect(() => {
    let isValidated = validationRules(field.value).filter(
      (rule) => rule.condition
    );
    if (!isValidated.length && open) {
      setOpen(false);
    }
    if (isValidated.length && !open && field.value) {
      setOpen(true);
    }
  }, [field.value]); */
  

  return (
    <Box width="100%">
      <FormControl ref={ref} width="100%" position="relative">
        {open && props.isDropdown && (
          <Fade in={open}>
            <Box
              boxShadow="0px 6px 94px rgba(172, 186, 202, 0.32), 0px 0.751293px 11.7703px rgba(172, 186, 202, 0.16)"
              borderRadius="8px"
              overflow="hidden"
              maxH="300px"
              backgroundColor="#FFFFFF"
              py="10px"
              width="100%"
              mb={{"2xl": "32px", base: "20px"}}
            >
              <List>
              {validationRules(field.value).map((rule, idx) => (
                <ListItem
                  color="grey"
                  display="flex"
                  alignItems="center"
                  fontFamily="Mulish"
                  cursor="pointer"
                  pl="11px"
                  key={idx}
                  backgroundColor="#FFFFFF"
                >
                  <RuleValidationIcon condition={rule.condition} />
                  <Text
                    display="inline"
                    p={{ base: "6px"}}
                    fontSize={{ base: "12px", md: "16px" }}
                    lineHeight={1}
                  >
                    {t(rule.label, {
                      field: props.name
                        .replace("_", " ")
                        .split(" ")
                        .map(
                          (s: any) => s[0].toUpperCase() + s.slice(1, s.length)
                        )
                        .join(" "),
                      characters: 8,
                    })}
                  </Text>
                </ListItem>
              ))}
              </List>
            </Box>
          </Fade>
        )}
        {props?.label && (
          <FormLabel
            fontSize="12px"
            fontFamily="Mulish"
            color={!props.error ? "#304358" : "#F44970"}
          >
            {props.label}
          </FormLabel>
        )}
        <InputGroup alignItems="center">
          <InputRightElement
            top={{ base: "8px", md: "unset" }}
            right="8px"
            cursor="pointer"
            children={
              <Image
                onClick={() => setShow(!show)}
                boxSize="24px"
                alt=""
                src={!show ? show_password : hide_password}
              />
            }
          />
          <Input
            //border="1px solid #A3B2B1"
            borderColor="#A3B2B1"
            border="1px"
            borderRadius="16px"
            width="100%"
            height={{base: "46px", md: "56px"}}
            isTruncated
            onFocus={() => setOpen(true)}
            type={show ? "text" : "password"}
            value={props.value}
            isInvalid={props.error}
            errorBorderColor="#F44970"
            _hover={{
              borderColor: "#304358",
            }}
            _focus={{
              borderColor: !props.error ? "#304358" : "#F44970",
            }}
            {...props}
          />
          
        </InputGroup>
       {props.error && props.helpertext && <FormLabel
            fontSize="12px"
            mt="10px"
            fontFamily="Mulish"
            color={!props.error ? "#304358" : "#F44970"}
          >
            {props.helpertext}
          </FormLabel>}
      </FormControl>
    </Box>
  );
}

export default PasswordField;
