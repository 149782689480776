import React from 'react'
import { Box, Flex, HStack } from "@chakra-ui/layout"
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Text,
  } from "@chakra-ui/react"
import { Checkbox, useCheckbox } from "@chakra-ui/checkbox"


  function CheckboxField(props: any) {
    const { getInputProps, getCheckboxProps } = useCheckbox(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    //@ts-ignore
    let isChecked = input.checked; 

    const setColor=()=> {
      if(props.error) {
        return "#F44970"; 
      }
      if(props.color && !props.error) {
        return props.color; 
      }
      return "#A3B2B1"; 
    }

    return (
   <FormControl >
       {props?.label && <FormLabel 
         fontSize="12px"
         fontFamily="Mulish"
         color={!props.error ? "#304358" : "#F44970"}
        >{props.label}</FormLabel>}
     <Flex alignItems="center">
        <Box as="label">
          
          <input {...input}/>
          <Box
            {...checkbox}
            //value= {props.value}
            cursor="pointer"
            borderWidth="1px"
            borderRadius="8px"
            borderColor={setColor()}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w={props.w ? props.w : "32px"}
            h={props.h ? props.h : "32px"}
            _checked={{
              bg: "white",
              color: "white",
              borderColor: "#40B89C",
            }}
          >  
           <Box 
            display={isChecked ? "block" : "none"}
            style={{
               backgroundColor: "#40B89C",
               borderRadius: '4px',
               width: "60%",
               height: "60%",
               content: '""',
          }}>
          {props.children}
          </Box>
          </Box>
        </Box>
        <Box ml="8px">
          <Text 
           fontSize="12px" 
           decoration="underline"
           color={!props.error ? "#304358" : "#F44970"}>
             {props.custom ? props.custom() : props.value}
          </Text> 
        </Box>
      </Flex>
     </FormControl> 

    )
  }
  
  export default CheckboxField