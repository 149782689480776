import { AppType } from "./app.type";
import defaultTheme from '../../themes/default';

export const appInitialState: AppType = {
    errorMessage: null,
    isErrored: false,
    theme: (multiplier: number) => defaultTheme(multiplier),
    successMessage: null,
    episodeType: null,
    multiplier: 1,
    isAccept: false, 
    questionnaireFile: null, 
    clickUploadFile: false, 
    clearFile: false, 
};
