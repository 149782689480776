import { Box, Text } from "@chakra-ui/layout";
import { useContext, useRef, useState } from "react";
import RegisterLaoyut from "./RegisterLaoyut";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { HStack, VStack, useMediaQuery } from "@chakra-ui/react";
import { handlerLocaleStorage } from "../../utils/helpers";
import { useHistory } from "react-router";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { RegisterContext } from "../../app/navigation";
import PasswordField from "../../components/passwordField";
import { useFormHandler } from "../CreatePasswordNew/hooks";
import { useRefreshErrorMessage } from "../../utils/hooks";

function Step5(props: any) {
  const formRef = useRef<any>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); 

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("validation.required"))
      .matches(
        /^.*(?=.{8,})(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~]).*)$/,
        t("validation.pwdInvalid")
      ),
    password_confirmation: Yup.string()
      .required(t("validation.required", {field: "Password"}))
      .oneOf([Yup.ref("password"), null], t("validation.cnfPwdInvalid")),
  });

  let registerValues = JSON.parse(sessionStorage.getItem("register") || "{}");
  const initialValuesBase = {
    password: "",
    password_confirmation: "",
  };

  const { state, appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const query: any = new URLSearchParams(window.location.search);
  let currentToken = query.get("token");
  const slug = useContext(RegisterContext);
  const optionsRadio = [t(`new_register.my_spouse_family_doctor`)];

  let initialValues = registerValues["step_8"]
    ? registerValues["step_8"]
    : initialValuesBase;

  let has_fmd = initialValues.has_fmd;

  const [isChecked, setIsChecked] = useState(has_fmd ? false : true);
  const [isHideSection, setIsHideSection] = useState(isChecked);
  const optionsGender = [
    t(`new_register.gender.female`),
    t(`new_register.gender.male`),
  ];

  const saveData = async () => {
    formRef?.current?.handleSubmit();
  };

  const handler = (e: any) => {
    let value = e.target.checked;
    if (value) {
      setIsChecked(true);
      setIsHideSection(true);
      formRef?.current?.setFieldValue(`has_fmd`, true);
    } else {
      setIsChecked(false);
      setIsHideSection(false);
      formRef?.current?.setFieldValue(`has_fmd`, false);
    }
  };

  const mapperRegisterData = (data: any) => {
    const mapper = (val: any, key: string) =>
      Object.entries(val).reduce((acc, item) => {
        return {
          ...acc,
          [!item[0].includes("has") ? `${key}_${item[0]}` : `${item[0]}`]:
            item[1],
        };
      }, {});
    let fmd_data = mapper(data.step_5, "fmd");
    let address = {
      address: data.step_3.address,
      city: data.step_3.city,
      postal_code: data.step_3.postal_code,
      province: data.step_3.province,
    };

    let parsedData = {
      token: currentToken,
      preferred_language: i18n.language,
      ...data.step_2,
      ...address,
      ...data.step_4,
      ...fmd_data,
      ...data.step_6,
      ...data.step_7,
      ...data.step_8,
    };

    if (data.step_1?.code) {
      parsedData.code = data.step_1.code;
    }

    parsedData.gender =
      parsedData?.gender === optionsGender[0] ? "2" : "1";

    return parsedData;
  };

  const sendData = async () => {
    let data = JSON.parse(sessionStorage.getItem("register") || "{}");

    if (data) {
      setIsLoading(true); 
      let parsedData = mapperRegisterData(data);
      let typeRegister = parsedData.code ? "submit" : "invite";
      const registered = await appService.register(parsedData, typeRegister);
      setIsLoading(false); 
    
       if (registered === true) {
        //history.push(`/register/${9}${slug}`);
        sessionStorage.removeItem("register");
        sessionStorage.removeItem("name_map");
        sessionStorage.removeItem("location"); 
      } 
    }
  };

  const goBack = () => {
    let values = formRef?.current?.values;
    handlerLocaleStorage(values, "step_8");
    history.push(`/register/${7}${slug}`);
  };

  const [onSetPasswordFormSubmit] = useFormHandler({
    appService,
    token: query.get("token"),
    history,
    t,
  });

 useRefreshErrorMessage(!!formRef?.current?.errors?.password_confirmation, formRef?.current?.handleSubmit); 

  return (
    <RegisterLaoyut isLoad={isLoading} step={8} goBack={goBack} handlerSubmit={saveData}>
      <Box pt={{ base: "40px"}}>
        <Text color="#40B89C" fontWeight="700">
          {t(`new_register.step5_password`)}
        </Text>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
        >
          {t(`new_register.step5_password_title`)}
        </Text>
      </Box>

      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          //history.push(`/register/${9}${slug}`);
          // onSetPasswordFormSubmit(values);
          handlerLocaleStorage(values, "step_8");
          sendData();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
          setTouched,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <VStack
              spacing={{ base: "unset", md: "20px" }}
              align="start"
              width="100%"
              mt={{"2xl": "32px", lg: "20px"}}
              mb="10px"
            >
              <HStack
                width={{ base: "100%", lg: "50%" }}
                justifyContent="flex-start"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <PasswordField
                  mb={{ base: "24px", md: "unset" }}
                  placeholder={t("createNewPassword.newPwdInpLabel")}
                  name="password"
                  isDropdown
                  error={errors.password && touched.password}
                  value={values.password}
                  onChange={handleChange}
                  handler={() => {}}
                  label={t("createNewPassword.newPwdInpLabel")}
                />
              </HStack>

              <HStack
                width={{ base: "100%", lg: "50%" }}
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <PasswordField
                  mb={{ base: (errors.password_confirmation &&
                    touched.password_confirmation) ? "unset" : "24px", md: "unset" }}
                  placeholder={t("createNewPassword.cnfNewPwdInpLabel")}
                  name="password_confirmation"
                  error={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                  value={values.password_confirmation}
                  onChange={handleChange}
                  handler={() => {}}
                  helpertext={errors.password_confirmation}
                  label={t("createNewPassword.cnfNewPwdInpLabel")}
                />
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </RegisterLaoyut>
  );
}

export default Step5;
