import React, { createContext, useContext, useEffect, useState } from "react";
import { Container } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@chakra-ui/hooks";
import { TopRegister } from "../../components/topRegister";
import { Box, Flex } from "@chakra-ui/react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { Divider, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { internalRoutes, ROUTE_PATH } from "../../app/routes";
import Step3 from "./Step3";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { InvalidRegisterTokenSupportDialog } from "../../components/invalid-register-token-support.dialog";
import { useEffectOnce, useUnmount } from "react-use";
import { RegisterContext } from "../../app/navigation";
import Step7 from "./Step7";
import Step2_2 from "./Step2_2";
import Step3_3 from "./Step3_3";
import LogRocket from "logrocket";
import EmployerCoverage from "./EmployerCoverage";
import { handlerLocaleStorage } from "../../utils/helpers";
import AcceptTerms from "../AcceptTerms";
import { useHistory } from "react-router-dom"; 

type RegisterFlowType = {
  loading: boolean; 
  isHideContent: boolean; 
  setLoading: Function;
  setIsHideContent: Function;  
}

const RegisterFlowContextValue = createContext<RegisterFlowType>({
  loading: false,
  isHideContent: false, 
  setLoading: () => null, 
  setIsHideContent: () => null, 
});

export function useRegisterFlowContext() {
  return useContext(RegisterFlowContextValue);
}

function RegisterNew () {
  const [loading, setLoading] = useState(false); 
  const [isHideContent, setIsHideContent] = useState(false); 

  const value: RegisterFlowType  = {
    loading, 
    setLoading, 
    isHideContent, 
    setIsHideContent, 
  }; 
  return (
    <RegisterFlowContextValue.Provider value={value}>
       <RegisterNewItem/>
    </RegisterFlowContextValue.Provider>
  )
}
 

function RegisterNewItem() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const [isCheckedUser, setIsCheckedUser] = useState(false); 
  const [isChangedSwitcherLang, setIsChangedSwitcherLang] = useState(false); 
  const history = useHistory(); 

  const useForceUpdate = () => {
    const [, setState] = useState<any>();
    return () => setState({});
  }
  const forceUpdate = useForceUpdate();

  const { state, appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const query: any = new URLSearchParams(window.location.search);
  const tokenInvite = query.get("token");
  const codeInvite = query.get("code");

  const { onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const display = useBreakpointValue({ base: "none", lg: "block" });

  const base = ROUTE_PATH.REGISTER_NEW;
  const currentStep = window.location.pathname.split("/").pop();
  const {isHideContent} = useRegisterFlowContext(); 
  const slug = window.location.search;

  async function validateUserCredentials(
    code: string,
    typeRequest: string
  ){
    let result: any = typeRequest === "token" ? await appService.checkRegisterToken(code) : await appService.checkEmployerCode(code);
    setIsCheckedUser(true); 

     if(result === 409) {
      history.push(ROUTE_PATH.REGISTER_NEW); 
      return
     }

    if (result) {

      let data: any = {
        employer_name: tokenInvite
          ? result.data?.data?.employer?.name
          : result.data?.data?.name,
      };
      if (!!codeInvite) {
        data.code = codeInvite;
      }
      handlerLocaleStorage(data, "step_1");
      forceUpdate(); 
    } else {
      setShowDialog(true); 
    }
  };

  useEffectOnce(() => {
    LogRocket.init("1w7kvz/allhealthpoc-pt-registration", {
      network: {
        isEnabled: false,
      },
    });
    let token = query.get("token");

    LogRocket.identify(`${token}`);

    if (!!tokenInvite) {
      validateUserCredentials(tokenInvite, "token");
    }

    if (!!codeInvite) {
      validateUserCredentials(
        codeInvite,
        "code"
      );
    }

    if(!tokenInvite && !codeInvite) {
      setIsCheckedUser(true); 
    }
  });
  let lang = query.get("lang");

  const routes = [
    { path: `${base}/${internalRoutes.EMPLOYER}`, component: EmployerCoverage },
    { path: `${base}/${internalRoutes.ACCEPT}`, component: AcceptTerms },
    { path: `${base}/2`, component: Step1 },
    { path: `${base}/3`, component: Step2 },
    { path: `${base}/4`, component: Step2_2 },
    { path: `${base}/5`, component: Step3 },
    { path: `${base}/6`, component: Step7 },
    { path: `${base}/7`, component: Step3_3 },
    { path: `${base}/8`, component: Step5 },
    { path: `${base}/9`, component: Step6 },
  ];

  useEffect(() => {
    if (i18n.language !== lang && !isChangedSwitcherLang) {
      i18n.changeLanguage(lang);
    }
  });

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const setSizeWrap = () => {
    const step2Resize = window.innerWidth >= 1024 && window.innerHeight >= 650;
    if(currentStep === "2" && window.innerHeight >= 900) {
      return `${window.innerHeight}px`;
    }
    if(currentStep === "4" && isHideContent) {
      return `${window.innerHeight}px`;
    }
    if (step2Resize || currentStep === internalRoutes.EMPLOYER) {
      return "100vh";
    }
    if(currentStep === internalRoutes.ACCEPT) {
      return window.innerWidth >= 768 ? "100vh" : `${window.innerHeight}px`
    }

    return "100%";
  };

  const setSizeBase = () => {
    if(  currentStep === internalRoutes.EMPLOYER) {
      return `${window.innerHeight}px`;
    }  
    if(currentStep === "4" && isHideContent) {
      return `${window.innerHeight}px`;
    }
    if(currentStep === "5" && isHideContent) {
      return `${window.innerHeight}px`;
    }
    if(currentStep === "6" && isHideContent) {
      return `${window.innerHeight}px`;
    }
    if(currentStep === "7" && isHideContent) {
      return `${window.innerHeight}px`;
    }
    const step2Resize = window.innerWidth >= 1024 && window.innerHeight >= 650;
    if (step2Resize) {
      return "100vh";
    }
    if(currentStep === internalRoutes.ACCEPT) {
      return `${window.innerHeight}px`
    }
    return "100%";
  }

  const setSize = () => {
    const step1Resize =
      (currentStep === "2") &&
      window.innerWidth >= 768 &&
      window.innerHeight <= 900;
      const step7Resize =
      ( currentStep === "7") &&
      window.innerWidth >= 768 &&
      window.innerHeight <= 800; 
    if (step1Resize || (step7Resize && !isHideContent)) {
      return "unset";
    }
    if(currentStep === "5" && isHideContent) {
      return "100%";
    }
    if(currentStep === "5" && !isHideContent) {
      return `unset`;
    }
    if(currentStep === "7" && isHideContent) {
      return "100%";
    }
    return "100%";
  };

  const getShowProgress = () => {
    if(currentStep === internalRoutes.ACCEPT || currentStep === internalRoutes.EMPLOYER || currentStep === "9") {
      return false
    }
    return true; 
  }


  return (
     <Container
      position="relative"
      width="100vw"
      height={{base: setSizeBase(), md: setSizeWrap()}}
      //h={`${window.innerHeight}px`}
      maxWidth="auto"
      backgroundColor="#FFFFFF"
      display="flex"
      pl="0"
      pr="0"
      flexDirection="column"
      justifyContent="flex-start"
      overflowX="hidden"
    >
      <>
      
      </>
      {(tokenInvite || codeInvite ) && (
        <InvalidRegisterTokenSupportDialog
          isOpen={showDialog}
          onClose={onClose}
          cancelRef={cancelRef}
        />
      )} 
    {isCheckedUser &&  <TopRegister handler={setIsChangedSwitcherLang} showProgress={getShowProgress()}/>}
      <Divider />
      <Flex height={setSize()} justify="center" align="center">
       <RegisterContext.Provider value={slug}>
         <Box
            height="100%"
            minW={{ base: "unset", lg: "1020px" }}
            w={{ base: "100%", xl: "55%" }}
            display="flex"
            alignItems="center"
          >
            {isCheckedUser && <Switch>
              {routes.map((item: any, idx: number) => (
                <Route
                  exact={true}
                  key={item.path}
                  path={item.path}
                  component={item.component}
                />
              ))}
              <Redirect
                from="/"
                to={`${base}/${tokenInvite || codeInvite ? internalRoutes.ACCEPT : internalRoutes.EMPLOYER}${slug}`}
              />
            </Switch>}
          </Box>
        </RegisterContext.Provider>
        <Box
          w="45%"
          height="100%"
          display={display}
          bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)"
        ></Box>
      </Flex>
    </Container> 
  );
}

export default RegisterNew;
