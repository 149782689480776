import React, {useContext, useEffect, useState } from 'react';
import { Container, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { TopRegister } from '../../components/topRegister';
import { Box, Flex } from '@chakra-ui/react';
import { Redirect, Route, Switch } from 'react-router';
import { Divider, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../app/routes';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { RegisterContext } from '../../app/navigation';
import InviteMembers from './InviteMembers';
import InviteSpouse from './InviteSpouse';
import InviteChild from './InviteChild';



function FamilyRegister() {
    const {t, i18n} = useTranslation();

    const { state, appService }: AppContextInterface = useContext<AppContextInterface>(AppContext);
    const query: any = new URLSearchParams(window.location.search);

    const display = useBreakpointValue({base: "none", lg: 'block'});
    const [isLargerThan1560] = useMediaQuery("(min-width: 1560px)");
    const [isLessMdSize] = useMediaQuery("(max-width: 768px)");
    const base = ROUTE_PATH.FAMILY_REGISTER;
    const currentStep = window.location.pathname.split('/').pop();
    const slug = window.location.search; 

    let lang = query.get('lang'); 
 

    const routes = [
        {path: `${base}/1`, component: InviteMembers },
        {path: `${base}/2`, component: InviteSpouse },
        {path: `${base}/3`, component: InviteChild },
    ];

    useEffect(() => {
        if(i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
    })

    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    const setSizeWrap = () => {
        const step1Resize = window.innerWidth >= 1024 && window.innerHeight >= 650;
        const step3Resize = currentStep === '3' && window.innerWidth >= 1560 && window.innerHeight >= 650;
        if(isLessMdSize || step1Resize){
            return "100vh"
        }
        // if(step2Resize ){
        //     return "100vh"
        // }
        return '100%';
    }

    const setSize = () => {
        const step1Resize = (currentStep === '1' || currentStep === "4") && window.innerWidth >= 768 && window.innerHeight <= 830;
        const step7Resize = currentStep === '8' && window.innerWidth >= 768 && window.innerHeight <= 830;
 
        if (currentStep === '6' && isLargerThan1560) {
            return "100%"
        }
        return "100%"
    }


    return (
        <Container
        position='relative'
        width="100vw"
        // height={{base: '100%', lg: "100vh"}}
        height={setSizeWrap()}
        maxWidth='auto'
        backgroundColor="#FFFFFF"
        display="flex"
        pl="0"
        pr="0"
        flexDirection="column"
        justifyContent="flex-start"
        overflowX="hidden"
        // alignItems="center"
        >
            <TopRegister  showProgress={false}/>
            <Divider />
            <Flex height={setSize()}  justify="center" align="center">
                <RegisterContext.Provider value={slug}>
                 <Box height='100%' minW={{base: "unset", lg: "1020px" }}  w={{base: '100%', xl: '55%'}}  display="flex" alignItems="center" py={{base: currentStep === "1" ? "40px" : "unset"}}>
                    <Switch>
                      {routes.map((item: any, idx: number) => <Route 
                        exact={true} 
                        key={item.path}
                        path={item.path} 
                        component={item.component}/>)} 
                         <Redirect from="/" to={`${base}/1${slug}`} />
                     </Switch> 
                    </Box>
                   </RegisterContext.Provider>
                   <Box 
                    w="45%" 
                    height="100%"   
                    display={display}
                    bgGradient="linear(to-b, #40B89C, #60CBB2, #9CF0D0)" 
                    >
                 </Box>
             
            </Flex>
       </Container>
    )
}

export default FamilyRegister
