import { Button } from "@chakra-ui/button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, HStack } from "@chakra-ui/layout";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { text } from "./textAccept";
import { text_fr } from "./textAccepFr";
import { RegisterContext } from "../../app/navigation";
import { ROUTE_PATH } from "../../app/routes";
import { useMediaQuery } from "@chakra-ui/react";
import { useEnterClickListener } from "../../utils/hooks";

function AcceptTerms() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const slug = useContext(RegisterContext);
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const [isLessThan370] = useMediaQuery("(max-width: 370px)");

  const decline = () => {
    sessionStorage.removeItem("register");
    history.push(ROUTE_PATH.HOME);
  };

  const nextStep = () => {
    const isHasCode = sessionStorage.getItem("register");
    if (isHasCode) {
      history.push(`/register/2${slug}`);
    }
  };

  useEnterClickListener(nextStep); 
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      minH={"100%"}
    >
      <Flex
        height="100%"
        px={{ base: "16px", md: "40px", "2xl": "80px" }}
        pb={{ base: "0px", md: "60px", lg: "unset" }}
        direction="column"
        justify={"flex-start"}
      >
        <Box
          h="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Flex
            h="100%"
            width="100%"
            direction="column"
            justify="center"
            position="relative"
          >
            <Box
              position="absolute"
              h="100%"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Box
                px={{ base: "16px", md: "unset" }}
                display="flex"
                alignItems={{ base: "center", md: "end" }}
                pt="20px"
                pb={{ base: "unset", md: "18px" }}
              >
                <Text
                  fontWeight="700"
                  fontSize={{ base: "32px", md: "40px" }}
                  color="#304358"
                >
                  {" "}
                  {t("createPwdAgreement.agreementLinkTitleNew")}
                </Text>
              </Box>
              <Box height="75%" w="100%" overflowY="scroll" p="10px">
                <Text>{i18n?.language === "en" ? text : text_fr}</Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
       <Flex width={{ base: "100%"  }} pr={{md: "40px", "2xl": "80px"}} position="absolute" bottom={{ base: "0px", md: "20px"}} justifyContent="flex-end">
         
        <Button
          onClick={decline}
          width={{ base: "50%", md: "unset" }}
          fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
          color="#304358"
          fontWeight="700"
          fontFamily="Mulish"
          border="1px"
          borderColor="#FFDB00"
          backgroundColor="#FFF19A"
          borderRadius={{ base: "0", md: "10pt" }}
          textTransform="none"
          variant="solid"
        >
          {t("new_register.btn_decline")}
        </Button>
        <Button
          onClick={nextStep}
          rightIcon={<ArrowForwardIcon />}
          fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
          ms={{ base: "0px", md: "24px" }}
          width={{ base: "50%", md: "unset" }}
          textTransform="none"
          fontFamily="Mulish"
          color="#304358"
          fontWeight="700"
          borderRadius={{ base: "0", md: "10pt" }}
          backgroundColor="#FFDB00"
          variant="solid"
        >
          {t("new_register.btn_accept")}
        </Button>
      </Flex> 
    </Box>
  );
}

export default AcceptTerms;
