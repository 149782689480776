import { useChannel, useEvent } from "@harelpls/use-pusher";
import { Channel } from "pusher-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useUpdateEffect } from "react-use";

export function useDefaultLangFromUrl(i18n: any) {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if ( query.has('lang') && ['en', 'fr'].includes(query.get('lang') || 'fr')) {
            i18n.changeLanguage(query.get('lang') || 'fr');
        }
    },[]);
}

export function useLanguageObserver(i18n: any, language: string, handler: Function ) {
    useEffect(() => {
        if(i18n.language && i18n.language !== language) {
            let lang = i18n?.language;
            if(lang === "en") handler("en");
            if(lang === "fr") handler("fr");
        }
      });
}

export function useRefreshErrorMessage(condition: boolean = true, handler: Function) {
    const {i18n} = useTranslation();
    useUpdateEffect(() => {
        if(condition) {
            handler(); 
        }
    }, [i18n.language])
}

export function useDeployAppListener(isAuth: boolean) {
    const [isReload, setIsReload] = useState(false); 
    const channelName = "presence-allhealth.clinic";  
    const channel: Channel | undefined = useChannel(channelName);
  
    useEvent(
      channel,
      'App\\Events\\Broadcast\\DeployFinishedEvent',
        (data: any) => {    
          if(data?.source === "backend" || data?.source === "patient") {
              setIsReload(true); 
          }
      }
    ); 

    return {
        isReload
    }
}

export function useEnterClickListener(handlerClick:Function) {
    function listenForEnter(e: any) {
        if (e.keyCode === 13 && !e.shiftKey) {
          handlerClick();
          e.preventDefault();
          return false;
        }
      }

    useEffect(() => {
        window.addEventListener("keydown", listenForEnter); 
      return () => {
        window.removeEventListener("keydown", listenForEnter); 
      }
    }, [handlerClick]); 
} 

