import { Input } from '@chakra-ui/input'
import { Box } from '@chakra-ui/layout'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from "@chakra-ui/react"
import React from 'react'

function TextField(props: any) {
    return (
        <Box width="100%">
          <FormControl  width="100%">
             {props?.label && <FormLabel 
              fontSize="12px"
              fontFamily="Mulish"
              color={!props.error ? "#304358" : "#F44970"}
             >{props.label}</FormLabel>}
             <Input
               border="1px"
               h={{base: "46px", md: "56px"}}
               borderColor="#A3B2B1"
               borderRadius="16px"  
               width="100%" 
               isInvalid={props.error}
               errorBorderColor="#F44970"
               _hover={{
                borderColor: !props.error ? "#304358" : "#F44970",
               }}
               _focus={{
                borderColor: !props.error ? "#304358" : "#F44970",
              }}
               {...props}/> 
            {props?.helpertext && props.error && <FormHelperText mb={{ base: "24px", md: "unset" }} color="#F44970" fontSize="12px" >{props?.helpertext}</FormHelperText>}
           </FormControl>
        </Box>
    )
}

export default TextField
