import Pusher from "pusher-js";
import React, { createContext, FC, MutableRefObject, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { AppContextInterface } from "../dto/app-context.dto";
import { AppService } from "./app";
import { MessageService } from "./message";
import { combinedReducers } from "./root.reducer";
import { SessionService } from "./session";

//-------------------------------------------
// Context
//-------------------------------------------



export const AppContext = createContext<AppContextInterface | any>({});


export const AppProvider: FC<{ children: any }> = ({ children }) => {
    const [ rootReducer, rootInitialState ] = combinedReducers;
    const [state, dispatch] = useReducer(rootReducer, rootInitialState);
    const value: AppContextInterface = { 
        state, 
        appService: new AppService(dispatch),
        sessionService: new SessionService(dispatch), 
        messageService: new MessageService(dispatch),
    };
    return (
        <AppContext.Provider value={value}>{children}</AppContext.Provider>
    );
}