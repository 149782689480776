import moment from "moment";
import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    email_address: Yup.string().email().required("This field is required"),
    first_name: Yup.string().required("This field is required").max(200),
    last_name: Yup.string().required("Field is required").max(200),
    is_old: Yup.boolean().required('Field is required'),
  });

export const ValidationRegisterChild = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("Field is required").max(200),
    hcn: Yup.string(),
    day: Yup.number().max(31),
    month: Yup.number().max(12),
    year: Yup.date(),
    birth_day: Yup.number().max(31).required("Field is required"),
    birth_month: Yup.number().max(12).required("Field is required"),
    birth_year: Yup.date()
        .max(moment().format("YYYY"), "Future date not allowed")
        .required("Field is required"),
    gender: Yup.string().required("Field is required"),
    is_old: Yup.boolean().required('Field is required'),
});

export const ValidationRegisterChildUpdated = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("Field is required").max(200),
    hcn: Yup.string().required("Field is required"),
    day: Yup.number().max(31).required("Field is required"),
    month: Yup.number().max(12).required("Field is required"),
    year: Yup.date().required("Field is required"),
    birth_day: Yup.number().max(31).required("Field is required"),
    birth_month: Yup.number().max(12).required("Field is required"),
    birth_year: Yup.date()
        .max(moment().format("YYYY"), "Future date not allowed")
        .required("Field is required"),
    gender: Yup.string().required("Field is required"),
    is_old: Yup.boolean().required('Field is required'),
});

export const initialValues = {
    email_address: "",
    first_name: "",
    last_name: "",
    hcn: "",
    month: "",
    year: "",
    day: "",
    birth_day: "",
    birth_month: "",
    birth_year: "",
    gender: "",
    is_old: "",
};