import { Box, Flex, Text } from '@chakra-ui/layout'
import { useContext, useEffect, useRef, useState } from 'react'
import TextField from '../../components/textField'
import RegisterLaoyut from './RegisterLaoyut'
import {Formik} from 'formik'
import * as Yup from 'yup';
import { HStack, VStack, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import InputDropdown from '../../components/inputDropdown'
import { handlerLocaleStorage } from '../../utils/helpers'
import { useHistory } from 'react-router'
import { RegisterContext } from '../../app/navigation'
import CheckboxField from '../../components/checkboxField'
import GoogleMapComponent from '../../components/googleMapComponets'
import { AppContextInterface } from '../../app/dto/app-context.dto'
import { AppContext } from '../../app/modules'
import { useRegisterFlowContext } from '.'


function Step3(props: any) {

  const formRef = useRef<any>(); 
  const history = useHistory();
  const {t} = useTranslation();
  const resultLocation = sessionStorage.getItem("location"); 
  let locationStorage = JSON.parse(`${resultLocation}`);
  const [location, setLocation] = useState<any>(locationStorage?.step_3 ? locationStorage.step_3 : {}); 
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)")

   const ValidationSchema = Yup.object().shape({ 
      last_name: Yup.string()
      .max(200)
      .required('Field is required'),
      first_name: Yup.string()
      .max(200)
      .required('Field is required'),
      phone_number: Yup.string()
      .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/),
      fax_number: Yup.string()
      .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/),
      address: Yup.string(),
      email_address: Yup.string()
      .email(),
     has_fmd: Yup.boolean()
      .required('Field is required'), 
    });

    const ValidationSchemaTest = Yup.object().shape({ 
      last_name: Yup.string()
      .max(200),
      first_name: Yup.string()
      .max(200),
      phone_number: Yup.string()
      .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/),
      fax_number: Yup.string()
      .matches(/^(?=.*?[1-9])[0-9()-_ ]+$/),
      address: Yup.string(),
      email_address: Yup.string()
      .email(),
      has_fmd: Yup.boolean()
      .required('Field is required'),  
    });


      
    let registerValues = JSON.parse(sessionStorage.getItem('register') || '{}');
    const initialValuesBase = {
      last_name: "",
      first_name: "",
      phone_number: "",
      fax_number: "",
      address: "",
      email_address:"",
      has_fmd: true
    };

    let initialValues = registerValues['step_5'] ? registerValues['step_5']  : initialValuesBase; 
    
    const [value, setValues] = useState({fmd_address: ""}); 

    let has_fmd = initialValues.has_fmd;

    const slug = useContext(RegisterContext); 

    const optionsRadio = [t(`new_register.i_dont_have_fyamily_doctor`)];
    const [isChecked, setIsChecked] = useState(has_fmd ? false : true); 
    const [isHideSection, setIsHideSection] = useState(isChecked); 
    const {setIsHideContent} = useRegisterFlowContext(); 

    useEffect(() => {
      setIsHideContent(isChecked);
    }, []);
   
    const handler = (e: any) => {

    let value = e.target.checked; 
      if(value) {
        setIsChecked(true)
        setIsHideSection(true); 
        setIsHideContent(true);
        formRef?.current?.setFieldValue(`has_fmd`, false); 
        setValues({fmd_address: ""});
        let newMapConfig = JSON.parse(`${sessionStorage.getItem("name_map")}`);
        if(newMapConfig?.fmd_address) {
          delete newMapConfig.fmd_address; 
          sessionStorage.setItem("name_map", JSON.stringify(newMapConfig));
        }
       
      } else {
        setIsChecked(false); 
        setIsHideSection(false); 
        setIsHideContent(false); 
        formRef?.current?.setFieldValue(`has_fmd`, true); 
      } 
    }

    const saveData = async () => {
      locationStorage.step_3 = location; 
      sessionStorage.setItem("location", JSON.stringify(locationStorage)); 
      formRef?.current?.handleSubmit(); 
    }

    let result = JSON.parse(sessionStorage.getItem("name_map") || '{}');

    const handlerGoogleMap = (values: any) => {
      setValues({fmd_address: values.formatted_address || ""}); 
      let newMapConfig = JSON.parse(`${sessionStorage.getItem("name_map")}`);
      newMapConfig.fmd_address = values.formatted_address; 
      sessionStorage.setItem("name_map", JSON.stringify(newMapConfig));
    }
    
    return (
       <RegisterLaoyut step={5} handlerSubmit={saveData} title_btn={t('new_register.next_question')} goBack={() => history.push(`/register/${4}${slug}`)}>
           <Box pt={{base: '40px'}}>
             <Text color="#40B89C" fontWeight="700">{t(`new_register.step_3_medical_profile`)}</Text>
             <Text fontWeight="700" fontSize={{base: "32px", "2xl": "40px"}}  color="#304358">{t('new_register.current_step', {step1: 1, step2: 3})} {t(`new_register.your_family_doctor`)}
             <Text color="#D3D9E0" as="span"> {`(${t(`new_register.step_2_subtitle_remark`)})`}</Text> 
             </Text>
           </Box>
  
           <Formik
            initialValues={initialValues}
            innerRef={formRef}
            enableReinitialize={true}
            validationSchema={!isHideSection ? ValidationSchema : ValidationSchemaTest}
            onSubmit={(values) => {
              const result = {
                last_name: values.last_name,
                first_name: values.first_name,
                phone_number: values.phone_number,
                fax_number: values.fax_number,
                address: value.fmd_address,
                email_address: values.email_address,
                has_fmd: values.has_fmd
              }
              handlerLocaleStorage(result, 'step_5'); 
              history.push(`/register/${6}${slug}`)

            }} >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
          <form onSubmit={handleSubmit}>

            <VStack spacing={{base: 'unset', md:"20px"}} mt={{base:"32px", '2xl': "48px" }} width="100%" mb={{base: "52px", lg: "50px"}}>
               <Flex w="100%">
                 <CheckboxField
                    label=""
                    isHelperText
                    options={optionsRadio} 
                    error={errors.has_fmd && touched.has_fmd}
                    name="has_fmd" 
                    isChecked={isChecked}
                    onChange={(e: any) => handler(e)}
                    value={optionsRadio}/>
                </Flex>6

             {!isHideSection && <><HStack width="100%" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                <TextField 
                  mb={{base: '24px', md:'unset'}}
                  label={t(`new_register.firstName`)}
                  name="first_name"
                  error={errors.first_name && touched.first_name}
                  onChange={handleChange}
                  value={values.first_name}
                  placeholder={t(`new_register.firstName`)}/>
                <TextField 
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t(`new_register.lastName`)} 
                  error={errors.last_name && touched.last_name}
                  onChange={handleChange}
                  value={values.last_name}
                  name="last_name"
                  label={t(`new_register.lastName`)}/>
              </HStack>

              <HStack width="100%" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                <InputDropdown
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t(`new_register.cellularNumber`)} 
                  name="phone_number"
                  error={errors.phone_number && touched.phone_number}
                  onChange={handleChange}
                  value={values.phone_number}
                  label={t(`new_register.cellularNumber`)}/>  
               <TextField 
                  mb={{base: '24px', md:'unset'}}
                  label={t(`new_register.fax_number`)}
                  name="fax_number"
                  error={errors.fax_number && touched.fax_number}
                  onChange={handleChange}
                  value={values.fax_number}
                  placeholder={t(`new_register.fax_number`)}/>
              </HStack>

              <HStack width="100%" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>

                <Box w={{base: "100%", lg: "50%"}} pr={{ md: "10px", lg: "17px" }}>
                  <TextField 
                    mb={{base: '24px', md:'unset'}}
                    placeholder={t(`new_register.emailAddress`)} 
                    name="email_address"
                    error={errors.email_address && touched.email_address}
                    onChange={handleChange}
                    value={values.email_address}
                    label={t(`new_register.emailAddress`)}/>
                  </Box>  
    
        
              </HStack>

              <HStack width="100%" display="flex" flexDirection="column">
                  <Box w="100%" h={{base: "200px", md:"250px", "2xl": "460px" }}   position="relative" mt={{base: '25px', md: "unset"}} mb={{base: '40px', md: "unset"}}>
                    <GoogleMapComponent 
                      isEstablishment={true}
                      setLocation={setLocation}
                      location={location}
                      label={t('new_register.fmd_map_label')}
                      name_map={result.fmd_address} 
                      handler={handlerGoogleMap}/>
                  </Box>
                </HStack>
              </>}
             </VStack> 

         </form>
         )}
        </Formik>  
       </RegisterLaoyut>

    )
}

export default Step3