import { useEffect } from "react";
import { AppService } from "../../app/modules/app";
import { ROUTE_PATH } from "../../app/routes";

type PasswordResetTokenValidatorType = {
    appService: AppService;
    token?: string;
    history: any
};

export function usePasswordResetTokenValidator( { appService, token, history }: PasswordResetTokenValidatorType ){
    useEffect(() => {
        appService.checkResetToken(token || '')
            .catch((e: any) => {
                let error = 'errors.misc';
                if ( e.response?.status === 401 )
                    error = 'errors.auth';
                else if ( e.response?.status === 422 )
                    error = 'errors.token';  
                appService.error(error);
                // appService.error(e?.response?.data?.message);
                history.push(ROUTE_PATH.FORGOT_PASSWORD);
            });
    },[]);
}



type FormHandlerType = {
    appService: AppService;
    token?: string;
    history: any
};

export function useFormHandler({ appService, token, history }:FormHandlerType) {
    const onResetPasswordFormSubmit = async ( values: any ) => {
        const reset: boolean =  await appService.resetPassword(values, token);
        if ( reset === true ) {
            appService.success('success.pwdReset');
            history.push(ROUTE_PATH.HOME);
        }
    };

    return [onResetPasswordFormSubmit];
}