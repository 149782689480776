import { PhoneIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import { Box, Text } from '@chakra-ui/layout'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Image,
  } from "@chakra-ui/react"
import React from 'react'
import canada_flag from '../assets/icons/canada.svg'; 

function InputDropdown(props: any) {
    return (
        <Box width="100%" h="100%">
          <FormControl  width="100%">
             {props?.label && <FormLabel 
              fontSize="12px"
              fontFamily="Mulish"
              color={!props.error ? "#304358" : "#F44970"}
             >{props.label}</FormLabel>}
            <InputGroup alignItems="center">
              <InputLeftElement
               pointerEvents="none"
               top={{base: "8px", md: "unset"}}
               left="1rem"
               //left='8px'
               //children={<PhoneIcon color="gray.300" />}
               children={
               <>
                 <Image boxSize="24px" alt="" src={canada_flag}/>
                 <Text   
                   fontSize="16px"
                   fontFamily="Mulish"
                   ps="8px"
                   color="#A3B2B1">+1</Text>
                </>}
              />
              <Input 
                border="1px"
                borderColor="#A3B2B1"
                borderRadius="16px"  
                width="100%"   
                isInvalid={props.error}
                errorBorderColor="#F44970"
                height="56px"
                pl="4rem"
                type="tel" 
                _hover={{
                  borderColor: !props.error ? "#304358" : "#F44970",
                 }}
                 _focus={{
                  borderColor: !props.error ? "#304358" : "#F44970",
                }}
                {...props}/>
            </InputGroup>
            {props?.helperText && props.showHelperText && <FormHelperText mb={{ base: "24px", md: "unset" }} color={!props.error ? "#304358" : "#F44970"} fontSize="12px">{props?.helperText}</FormHelperText>}
           </FormControl>
        </Box>
    )
}

export default InputDropdown; 
