import React from 'react'
import { Box, HStack } from "@chakra-ui/layout"
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from "@chakra-ui/react"
import { useRadio, useRadioGroup } from "@chakra-ui/radio"

function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getCheckboxProps()
    //@ts-ignore
    let isChecked = input.checked; 
 
    const setColor=()=> {
      if(props?.error?.accept || props.error) {
        return "#F44970"; 
      }
      if(props.color && !props.error) {
        return props.color; 
      }
      return "#A3B2B1"; 
    }
    return (
      <Box as="label">
        <input {...input}/>
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          w={props.w ? props.w : "32px"}
          h={props.h ? props.h : "32px"}
          borderRadius="8px"
          borderColor={setColor()}
          display="flex"
          justifyContent="center"
          alignItems="center"
          _checked={{
            bg: props.color ? props.color : "white",
            color: "white",
            borderColor: props.color ? props.color : "#40B89C",
            
          }}
          _focus={{
            borderColor: props.color ? props.color : "#40B89C",
          }}
         
        >
          <Box 
            display={isChecked ? "block" : "none"}
            style={{
               backgroundColor: "#40B89C",
               borderRadius: '4px',
               width: "60%",
               height: "60%",
               content: '""',
          }}>
          {props.children}
          </Box>
           
        </Box>
      </Box>
    )
  }
  

  function RadioFields(props: any) {

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: props.name,
      defaultValue: props.defaultValue,
      value: props.value,
      onChange:(value) => props.handler(value),
    })

    
    const group = getRootProps()
 

    return (
    <FormControl width="100%">
       {props?.label && <FormLabel 
         fontSize="12px"
         fontFamily="Mulish"
         color={!props.error ? "#304358" : "#F44970"}
        >{props.label}</FormLabel>}
        <HStack {...group}>
          {props.options.map((value: any, idx: number) => {
           const radio = getRadioProps({ value })
  
           return (
               <React.Fragment key={idx} >
                <RadioCard onChange={()=> console.log("test", value)} {...radio} {...props}></RadioCard>
                 {props?.isHelperText && <FormHelperText 
                   color={props.defaultValue === value ? "#304358": "#A3B2B1"}
                   fontSize="12px">
                       {!props.custom ? value : props.custom()}
                 </FormHelperText>}
               </React.Fragment>
            )
            })}
        </HStack>
      </FormControl>
    )
  }
  
  export default RadioFields
