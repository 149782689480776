import {
  Container,
  Text,
  Heading,
  Box,
  List,
  ListItem,
} from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { ROUTE_PATH } from "../../app/routes";
import BannerReloadPage from "../../components/bannerReload";
import { HeaderLogo } from "../../components/header.logo";
import { CalendarIconCustom, CreditCardIconCustom, DocumentPlusIconCustom, LogoutIconCustom, ShoppingCartIconCustom, UserPlusIconCustom } from "../../components/icons";
import { changeLanguage } from "../../utils/helpers";
import { useDeployAppListener } from "../../utils/hooks";

export const ChatType: FC<any> = () => {
  const { t, i18n } = useTranslation();
  const { state, appService, messageService, sessionService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const [language, setLanguage] = useState<string>(t("lang") || "fr");


  const appointmentLink = state.session.episode.patient?.wellness_type === "B2C/D2C" ? "https://tapmedicalrdv.as.me/schedule.php?calendarID=7552706" : ""; 
  const oneTimePriceLink = "https://buy.stripe.com/fZeaHiak88H079C4gl"; 
  const yearlyMembershipLink = "https://buy.stripe.com/cN202Ecsg1ey9hK5ko"; 
  const manageBillingLink = "https://allhealth-billing.tapmedical.ca/p/login/cN24gHgBzaiI3oQ8ww"; 


  const history = useHistory();

  const [sendingType, setSendingType] = useState(false);
  const { isReload } = useDeployAppListener(true);

  const handleChangeLanguage = (lang: "en" | "fr") => {
    const patient = state.session.episode.patient; 
    if(patient) {
      const user = {
        first_name: patient?.first_name,
        last_name: patient?.last_name,
        date_of_birth: patient?.date_of_birth,
        gender: patient?.gender === 2 ? "female" : "male",
        preferred_language: lang, 
      }  
      sessionService.updatePatient(user, state.session.token); 
    } 
    changeLanguage(lang, language, setLanguage, i18n);
  };

  useEffect(() => {
    if (state.session === null) history.push(ROUTE_PATH.HOME);
    messageService.clearMessages();
  }, [state.session]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendType = async (type: "0" | "1") => {
    if (type === "1") {
      history.push(ROUTE_PATH.FAMILY_REGISTER);
      return;
    }
    setSendingType(true);
    const response = await appService.setEpisodeType(
      type,
      state.session?.token
    );
    setSendingType(false);
    if (response) {
      history.push(ROUTE_PATH.CHAT);
    }
  };

  return (
    <>
      {isReload && <BannerReloadPage isAbsolute={false} />}
      <Container
        pt={"5%"}
        //mb="auto"
        width="100%"
        height={state.session?.episode.patient?.is_wellness ? "100%" : `${window.innerHeight}px`}
        backgroundColor="#FFFFFF"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <HeaderLogo
          backLink={ROUTE_PATH.CHAT_TYPE}
          showLanguage={true}
          language={language}
          changeLanguage={handleChangeLanguage}
        />
        <Heading
          fontFamily={"Playfair Display"}
          fontSize="2xl"
          mt="40px"
          ml={"20px"}
        >
          {t("chatTypeScreen.pageTitle", {
            name: state.session?.episode.patient?.first_name,
          })}
        </Heading>
        <Heading
          fontFamily={"Playfair Display"}
          color="#A3B2B1"
          fontSize="lg"
          mt="10px"
          ml={"20px"}
        >
          {t("chatTypeScreen.subTitle")}
        </Heading>

        {sendingType === true ? (
          <Container
            height="100%"
            alignSelf="center"
            mt="30%"
            ml={"20px"}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <Container p={0} display="flex">
              <Spinner />
              <Text fontFamily={"Mulish"} fontSize="md" ml="5px">
                {t("chatTypeScreen.loadingTitle")}
              </Text>
            </Container>
            <Text
              mt={"5px"}
              fontFamily={"Mulish"}
              fontSize="sm"
              color="#A3B2B1"
            >
              {t("chatTypeScreen.loadingSubTitle")}
            </Text>
          </Container>
        ) : (
          <>
          {/*  {state.session?.episode.patient?.is_wellness && <Box
              boxShadow={"0px 0px 40px -20px rgba(0,0,0,0.75)"}
              borderRadius={"20px"}
              w="90%"
              ml={"20px"}
              p={0}
              m={"20px"}
              mt={"50px"}
            >
              <List>
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => {
                    if(appointmentLink) {
                      window.open(appointmentLink)
                    }
                  }}
                >
                 <CalendarIconCustom width="24px" height="24px"/>
                  <Text pl="20px" fontFamily={"Mulish"} fontSize={"md"}>
                    {t("chatTypeScreen.appointmentBtnTitle")}
                  </Text>
                </ListItem>
              </List>
            </Box>}

           {state.session?.episode.patient?.is_wellness && <Box
              boxShadow={"0px 0px 40px -20px rgba(0,0,0,0.75)"}
              borderRadius={"20px"}
              w="90%"
              ml={"20px"}
              p={0}
              m={"20px"}
              mb={"20px"}
            >
              <List>
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => window.open(oneTimePriceLink)}
                >
                  <ShoppingCartIconCustom width="24px" height="24px"/>
                  <Text pl="20px" fontFamily={"Mulish"} fontSize={"md"}>
                    {t("chatTypeScreen.buySingleBtnTitle", { price: 189 })}
                  </Text>
                </ListItem>
                <hr />
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => window.open(yearlyMembershipLink)}
                >
                  <ShoppingCartIconCustom width="24px" height="24px"/>
                  <Text pl="20px" fontFamily={"Mulish"} fontSize={"md"}>
                    {t("chatTypeScreen.buyAnnualBtnTitle", { price: 384 })}
                  </Text>
                </ListItem>
              </List>
            </Box>} */}

            <Box
              boxShadow={"0px 0px 40px -20px rgba(0,0,0,0.75)"}
              borderRadius={"20px"}
              w="90%"
              ml={"20px"}
              p={0}
              m={"20px"}
              //mt={"20px"}
              mt={"50px"}
              mb={"20px"}
            >
              <List>
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => sendType("0")}
                >
                  <DocumentPlusIconCustom height="25px" width="25px"/>  
                  <Text fontFamily={"Mulish"} fontSize={"md"} pl="20px">
                    {t("chatTypeScreen.labelNewVisit")}
                  </Text>
                </ListItem>
                {state.session?.episode?.patient?.is_primary_member === 1 &&  <hr />}
                {state.session?.episode?.patient?.is_primary_member === 1 && (
                  <ListItem
                    p={"20px"}
                    pl={"25px"}
                    display={"flex"}
                    alignItems="center"
                    cursor={"pointer"}
                    onClick={() => sendType("1")}
                  >
                    <UserPlusIconCustom height="25px" width="25px"/>
                    <Text fontFamily={"Mulish"} fontSize={"md"} pl="20px">
                      {t("new_register.add_family_members")}
                    </Text>
                  </ListItem>
                )}
              </List>
            </Box>

            {state.session?.episode.patient?.is_wellness && <Box
              boxShadow={"0px 0px 40px -20px rgba(0,0,0,0.75)"}
              borderRadius={"20px"}
              w="90%"
              ml={"20px"}
              p={0}
              m={"20px"}
            >
              <List>
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => window.open(manageBillingLink)}
                >
                  <CreditCardIconCustom height="24px" width="24px"/>
                  <Text fontFamily={"Mulish"} fontSize={"md"} pl="20px">
                    {t("chatTypeScreen.billingBtnTitle")}
                  </Text>
                </ListItem>
              </List>
            </Box>}

            <Box
              boxShadow={"0px 0px 40px -20px rgba(0,0,0,0.75)"}
              borderRadius={"20px"}
              w="90%"
              ml={"20px"}
              p={0}
              m={"20px"}
            >
              <List>
                <ListItem
                  p={"20px"}
                  pl={"25px"}
                  display={"flex"}
                  alignItems="center"
                  cursor={"pointer"}
                  onClick={() => sessionService.logout()}
                >
                  <LogoutIconCustom height="25px" width="25px"/>
                  <Text fontFamily={"Mulish"} fontSize={"md"} pl="20px">
                    {t("chatTypeScreen.logout")}
                  </Text>
                </ListItem>
              </List>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
