import React, { createContext, useContext, useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import routes, { ROUTE_PATH } from './routes';
import { Route, RouteProps } from '../utils/route';
import { ChakraProvider } from '@chakra-ui/react';
import { AppContext } from './modules';
import { AppContextInterface } from './dto/app-context.dto';
import { useEffectOnce, useIdle, useMedia } from 'react-use';
import { PusherProvider, PusherProviderProps } from '@harelpls/use-pusher';
import { useDefaultLangFromUrl, useDeployAppListener } from '../utils/hooks';
import { useTranslation } from 'react-i18next';
import LogRocket from 'logrocket';
import OneSignal from 'react-onesignal';
// import * as PusherPushNotifications from "@pusher/push-notifications-web";

const query = new URLSearchParams(window.location.search);
export const RegisterContext = createContext('register'); 

export const Navigation: React.FC<any> = () => {

    const history = useHistory();
    const {pathname} = useLocation();
   
    const { t, i18n } = useTranslation();
    const { state, sessionService, appService }: AppContextInterface = useContext<AppContextInterface>(AppContext);
    const idle: boolean = useIdle(600e3, false );
    const [isLogRocketInit, setIsLogRocketInit] = useState(false); 
 
    const [ pusherConfig, setPusherConfig ] = useState<PusherProviderProps>({
        clientKey: '',
        cluster: '',
    });

    const initializeOneSignal = async () => {
        await OneSignal.init({
            appId: state.session?.onesignal?.app_id, 
            safari_web_id: process.env.REACT_APP_SAFARI_WEB_ID,
            notifyButton: {
               enable: true,
               position: 'bottom-right',
               offset: {
                   bottom: '80px',
                   right: '50px'
               },
               text: {
                   'tip.state.unsubscribed': t('onesignal.text_1'),
                   'tip.state.subscribed': t('onesignal.text_2'),
                   'dialog.main.button.subscribe': t('onesignal.text_3'),
                   'dialog.main.button.unsubscribe': t('onesignal.text_4'),
                   'message.prenotify': t('onesignal.prenotify'),
                   'message.action.subscribed': t('onesignal.actionSubscribed'),
                   'message.action.resubscribed': t('onesignal.actionResubscribed'),
                   'message.action.unsubscribed': t('onesignal.actionUnsubscribed'),
               }
           },  
           allowLocalhostAsSecureOrigin: true,
           autoRegister: false,
           promptOptions: {
               actionMessage: t('onesignal.actionMessage'),
               acceptButton: t('onesignal.acceptBtn'), 
               cancelButton: t('onesignal.cancelBtn')
           }  
         }); 
        //@ts-ignore   
        OneSignal.SERVICE_WORKER_PATH = '/src/push/onesignal/OneSignalSDKWorker.js';
        //@ts-ignore  
        OneSignal.SERVICE_WORKER_UPDATER_PATH = '/src/push/onesignal/OneSignalSDKUpdaterWorker.js'
        //@ts-ignore 
        OneSignal.SERVICE_WORKER_PARAM = { scope: '/src/push/onesignal/'};  
       await OneSignal.showSlidedownPrompt();  
       if (state.session.onesignal?.user_hash) {
           OneSignal.setExternalUserId(`${state.session?.episode?.patient?.id}`, `${state.session.onesignal?.user_hash}`); 
       }
       OneSignal.sendTag("patientId", state.session?.episode?.patient?.id);
    }

    useEffect(()=> {
        if(!pathname.includes("register") && !isLogRocketInit) {
            LogRocket.init('1w7kvz/allhealthpoc-pt', {
                network: {
                  isEnabled: false,
                },
              });
              setIsLogRocketInit(true); 
        }
        if(window.location.host.endsWith(".")) {
            window.location.replace("https://" + window.location.host.slice(0, -1));
        }
    }, [pathname]); 


    useEffect(() => {

         if(state.session && !pathname.includes("register")){
            LogRocket.identify(`${state?.session?.episode?.patient?.id}`, {
                name: `${state?.session?.episode?.patient?.name}`,
                email: `${state?.session?.episode?.patient?.email}`,
              });
        }  

        if(!state.session) {
            LogRocket.startNewSession(); 
        }

        if ( state.session && pusherConfig.clientKey === '' ) {
            setPusherConfig({
                clientKey: state.session?.pusher.key,
                cluster: state.session?.pusher.cluster,
                // also sends auth headers to trigger endpoint
                authEndpoint: `${process.env.REACT_APP_API_URI}/api/v3/auth/broadcasting`,
                auth: {
                    headers: { Authorization: `Bearer ${state.session.token}` },
                },
            });
        }
         if(state.session) {    
            initializeOneSignal();     
        } 
        if ( state.session && state.session?.episode?.patient?.language !== t('lang') ) {
            i18n.changeLanguage(state.session?.episode?.patient?.language || 'fr');
        }
    },[state.session]);

     OneSignal.on('subscriptionChange', function (isSubscribed: any) {
		if(isSubscribed && state.session.onesignal.user_hash) {
			OneSignal.setExternalUserId(`${state.session.episode.patient?.id}`, `${state.session.onesignal?.user_hash}`);  
		    OneSignal.sendTag("patientId", state.session.episode.patient?.id);  
		}
	}); 

    useEffect(() => {
        if ( idle === true && state.session ) {
            sessionService.logout();
            setPusherConfig({
                clientKey: '',
                cluster: '',
            });  
            if ( query.has('token') === true )  {
                history.replace(ROUTE_PATH.HOME);
            }
        }
    }, [idle, state.session]);


    //useDefaultLangFromUrl(i18n);

    const [ multiplier, setMultiplier ] = useState(1);

    const _4k = useMedia('(min-width: 2018px)');
    const laptopLarge = useMedia('(min-width: 1440px)');
    const laptopSmall = useMedia('(min-width: 1024px)');
    const tablet = useMedia('(min-width: 768px)');
    
    useEffectOnce(() => {
        if ( _4k === true ) {
            setMultiplier(5);
        }
        else if ( laptopLarge === true ) {
            setMultiplier(5);
        }
        else if ( laptopSmall === true || tablet === true ) {
            setMultiplier(2);
        }
        else {
            setMultiplier(1);
        }
        appService.setMultiplier(multiplier);
    });

    const NavComponent = (
        <ChakraProvider theme={state.app.theme(multiplier)}>
            <Switch>
                { routes(state.session).map( ( r: RouteProps, i ) => <Route key={i} exact={ i === 0 } path={r.path} component={r.component} check={r.check} redirect={r.redirect} /> ) }
            </Switch>
        </ChakraProvider>
    );

    if ( pusherConfig.clientKey === '' ) 
        return NavComponent;
    else 
    return (
        <PusherProvider {...pusherConfig}>
            {NavComponent}
        </PusherProvider>
    );
}
