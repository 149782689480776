import React from 'react';
import { Container, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';

export const RegisterComplete: React.FC<any> = () => {

    const {t} = useTranslation();

    return (
        <Container
            mt={2 * window.devicePixelRatio}
            mb="auto"
            position='relative'
            width="100vw"
            height="100vh"
            maxWidth='auto'
            backgroundColor="#FFFFFF"
            display="flex"
            flexDirection="column"
            paddingBottom="30px"
            paddingTop="20px"
            justifyContent="center"
            alignItems="center"
            overflow="scroll">
            <Text fontFamily={'Playfair Display'} fontSize="lg" mt={3 * window.devicePixelRatio}>
                {t('registerComplete.title')}
            </Text>
        </Container>
    );
}