import { Input, InputGroup } from '@chakra-ui/input'
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/layout'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    HStack, 
  } from "@chakra-ui/react"
import React, { useRef, useState } from 'react'

function PinFields(props: any) {
    const refDay =  React.useRef<any>(null);
    const refMonth =  React.useRef<any>(null);
    const refYear =  React.useRef<any>(null);
    const listRefs = [refMonth, refDay, refYear];
    const {t} = useTranslation();

    const [values, setValues] = useState({
      month: props.value_month,
      day: props.value_day,
      year: props.value_year
    });

    const handleChange = (e:any) => {
      let num = Number(e.target.dataset.number);
    
      if(!isNaN(e.target.value) && e.target.name !== "year" && e.target.value.length <= 2) {
        setValues({...values, [e.target.name]: e.target.value})
        props.customChange([e.target.name], e.target.value)
        if(e.target.value.length === 2){ 
          listRefs[num + 1].current.focus(); 
        }  
      }
      if(!isNaN(e.target.value) && e.target.name === "year" && e.target.value.length <= 4) {
        setValues({...values, [e.target.name]: e.target.value})
        props.customChange([e.target.name], e.target.value)
        // if(e.target.value.length === 4) {
        //   listRefs[0].current.focus();
        // }
      }
    }


    return (
        <Box width="100%" display='flex' alignItems="end">
          <FormControl  width="100%">
             {props?.label && <FormLabel 
              fontSize="12px"
              fontFamily="Mulish"
              color={!props.error_day && !props.error_month && !props.error_year ? "#304358" : "#F44970"}
             >{props.label}</FormLabel>}
                <HStack width="100%">
                  <Input 
                    type="text"  
                    data-private
                    name="month"
                    value={values.month}
                    onChange={handleChange}
                    borderRadius="16px"              
                    data-number="0"
                    border="1px"
                    borderColor="#A3B2B1"
                    isInvalid={props.error_month}
                    errorBorderColor="#F44970"
                    placeholder={t(`new_register.mm`)}
                    ref={refMonth}
                    _hover={{
                       borderColor: !props.error ? "#304358" : "#F44970",
                      }}
                    _focus={{
                        borderColor: !props.error ? "#304358" : "#F44970",
                    }}
                    w={{base:"60px", md: "63px", lg: "63px"}}
                    h="56px"  />
                  <Input 
                    type="text"  
                    data-private
                    name="day"
                    value={values.day}
                    placeholder={t(`new_register.dd`)}
                    data-number="1"
                    borderRadius="16px"
                    border="1px"
                    isInvalid={props.error_day}
                    errorBorderColor="#F44970"
                    borderColor="#A3B2B1"
                    onChange={handleChange}
                    _hover={{
                       borderColor: !props.error ? "#304358" : "#F44970",
                      }}
                      _focus={{
                        borderColor: !props.error ? "#304358" : "#F44970",
                    }}
                    ref={refDay}
                    w={{base:"56px", md: "63px", lg: "60px"}}
                    h="56px"  />
                  <Input 
                    type="text"  
                    name="year"
                    data-private
                    data-number="2"
                    border="1px"
                    borderColor="#A3B2B1"
                    placeholder={t(`new_register.yyyy`)}
                    value={values.year}
                    onChange={handleChange}
                    isInvalid={props.error_year}
                    errorBorderColor="#F44970"
                    borderRadius="16px"
                    _hover={{
                      borderColor: !props.error ? "#304358" : "#F44970",
                      }}
                   _focus={{
                     borderColor: !props.error ? "#304358" : "#F44970",
                    }}
                    px="14px"
                    ref={refYear}
                    w={{base:"72px", md: "73px", lg: "73px"}}
                    h="56px"  />
                  </HStack>
            {props?.helperText && <FormHelperText>{props?.helperText}</FormHelperText>}
           </FormControl>
        </Box>
    )
}

export default PinFields