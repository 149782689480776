import { useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { reader } from "./helpers";

export function useUploadFilesHandler(multiple: boolean = true, msgContainerBottomRef: any = null) {
  const [file, setFile] = useState<Array<any> | null>(null);
  const [listFilesUrl, setListFilesUrl] = useState<any>({})
  const toast = useToast();
  const { t } = useTranslation();

  const notifyForFiles = (title: string, description: string) => {
    return toast({
      title,
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (file !== null) {
      file.map((file) => {
        reader(file).then((data: any) => {
          file.data_url = data;
          setListFilesUrl({...listFilesUrl, [file.name]: data})
        });
      });
      if(msgContainerBottomRef) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [file]);

  const handlerLoadFile = (e: any, dragFile: any = null) => {
    let files = !file ? [] : file;
    let arrFiles: Array<any> = [];
    //const newFiles = e.target.files;
    const newFiles = dragFile !== null ? dragFile : e.target.files; 
    Object.values(newFiles).forEach((file: any) => {
      if (file.size < 10000000) {
        return arrFiles.push(file);
      } else {
        e.target.value = "";
        notifyForFiles(
          t("errors.file_validation_title", {
            file_name: file.name.slice(0, 10),
          }),
          t("errors.file_validation_text")
        );
      }
    });
    if(!multiple && e.target.value === "") {
      setFile(null);
      return;
    }
    let validFilesArr = arrFiles.slice();
    if (arrFiles.length + files.length > 5) {
      notifyForFiles(t("errors.title_files"), t("errors.text_files"));
      let limit = 5 - files.length;
      validFilesArr = arrFiles.slice(0, limit);
    }
    if(multiple) {
        setFile([...files, ...validFilesArr]);
    } else {
        setFile([...validFilesArr]); 
    }
  };

  return {
    handlerLoadFile,
    file,
    setFile,
    listFilesUrl,
    setListFilesUrl
  };
}
