import { RouteProps } from "../utils/route";

//------------------------------------
//------------- Component ------------
//------------------------------------

import {
  LoginScreen,
  _404Page,
  ChatScreen,
  ForgotPassword,
  ResetLinkSentScreen,
  Register,
  RegisterComplete,
  CreateNewPassword,
  ChatType,
  CreatePwdAgreement,
  CreatePassword,
} from "../screens";
import { SessionType } from "./modules/session";
import RegisterNew from "../screens/RegisterNew";
import LoginNew from "../screens/LoginNew";
import ForgotPasswordNew from "../screens/ForgotPasswordNew";
import ResetLinkSentNew from "../screens/ForgotPasswordNew/ResetLinkSentNew";
import CreatePasswordNew from "../screens/CreatePasswordNew";
import AcceptTerms from "../screens/AcceptTerms";
import RessetPasswordNew from "../screens/CreatePasswordNew/ResetPasswordNew";
import FamilyRegister from "../screens/FamilyRegister";
import { SuccessRegister } from "../screens/SuccessRegister";
import AcceptTermsOld from "../screens/AcceptTerms/AcceptTermsOld";

//------------------------------------

//------------------------------------
//--------- Route Constants ----------
//------------------------------------
export const ROUTE_PATH = {
  HOME: "/",
  CHAT_TYPE: "/chat-type",
  CHAT: "/chat",
  FORGOT_PASSWORD: "/forgot-password-old",
  RESET_LINK_SENT: "/reset-pwd-email-sent-old",
  CREATE_NEW_PWD: "/password-reset-old/:token",
  CREATE_PWD_AGREEMENT: "/create-password",
  CREATE_PASSWORD: "/first-password-old",
  CREATE_PASSWORD_NEW: "/first-password",
  RESET_PASSWORD: "/password-reset/:token",
  REGISTER: "/register-old",
  REGISTER_NEW: "/register",
  FORGOT_PASSWORD_NEW: "/forgot-password",
  RESET_LINK_SENT_NEW: "/reset-pwd-email-sent",
  LOGIN_NEW: "/login-new",
  LOGIN_OLD: "/login-old",
  REGISTER_COMPLETE: "/register-finish",
  ACCEPT_TERMS: "/accept-terms",
  FAMILY_REGISTER: "/family",
  SUCCESS_REGISTER: "/success_register",
};
//------------------------------------

export const internalRoutes = {
  ACCEPT: "accept_terms",
  EMPLOYER: "employer"
}

const query = new URLSearchParams(window.location.search);

const routes: (session: SessionType) => Array<RouteProps> = (
  session: SessionType
) => [
  // first route is exact.
  {
    exact: true,
    path: ROUTE_PATH.HOME,
    component: LoginNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    exact: true,
    path: ROUTE_PATH.LOGIN_OLD,
    component: LoginScreen,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.CHAT,
    component: ChatScreen,
    check: session !== null || query.has("token") === true,
    redirect: ROUTE_PATH.HOME,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    component: ForgotPassword,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.RESET_LINK_SENT,
    component: ResetLinkSentScreen,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.CREATE_NEW_PWD,
    component: CreateNewPassword,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.CHAT_TYPE,
    component: ChatType,
    check: session !== null,
    redirect: ROUTE_PATH.HOME,
  },
  {
    path: ROUTE_PATH.CREATE_PWD_AGREEMENT,
    component: CreatePasswordNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.CREATE_PASSWORD,
    component: CreatePassword,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.CREATE_PASSWORD_NEW,
    component: CreatePasswordNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    component: RessetPasswordNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.REGISTER,
    component: Register,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.LOGIN_NEW,
    component: LoginNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD_NEW,
    component: ForgotPasswordNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.RESET_LINK_SENT_NEW,
    component: ResetLinkSentNew,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.REGISTER_NEW,
    component: RegisterNew,
    check: session === null,
    redirect: ROUTE_PATH.SUCCESS_REGISTER,
  },
  {
    path: ROUTE_PATH.SUCCESS_REGISTER,
    component: SuccessRegister,
    check: session !== null,
    redirect: ROUTE_PATH.HOME,
  },
  {
    path: ROUTE_PATH.FAMILY_REGISTER,
    component: FamilyRegister,
    check: session !== null,
    redirect: ROUTE_PATH.HOME,
  },
  {
    path: ROUTE_PATH.ACCEPT_TERMS,
    component: AcceptTermsOld,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  {
    path: ROUTE_PATH.REGISTER_COMPLETE,
    component: RegisterComplete,
    check: session === null,
    redirect: ROUTE_PATH.CHAT_TYPE,
  },
  // no path is default 404 page.
  {
    component: _404Page,
  },
];

export default routes;
