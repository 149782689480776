import { Box, Text } from "@chakra-ui/layout";
import { useContext, useEffect, useRef, useState } from "react";
import TextField from "../../components/textField";
import RegisterLaoyut from "./RegisterLaoyut";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router";
import { handlerLocaleStorage } from "../../utils/helpers";
import { RegisterContext } from "../../app/navigation";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { internalRoutes } from "../../app/routes";
import { useRefreshErrorMessage } from "../../utils/hooks";


function EmployerCoverage(props: any) {
  const { appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const { t, i18n } = useTranslation();
  const [codeError, setCodeError] = useState(false); 
  const [errorText, setErrorText] = useState(""); 

  const ValidationSchema = Yup.object().shape({
    code: Yup.string()
      .trim()
      .matches(/^[A-Za-z0-9]*$/, t("validation_code.invalid"))
      .max(6, t("validation_code.alphaNumeric"))
      .min(6, t("validation_code.alphaNumeric"))
      .required(t("validation_code.required")),
  });

  let registerValues = JSON.parse(sessionStorage.getItem("register") || "{}");
  let initialValuesBase = {
    code: "",
  };
  let initialValues = registerValues["step_1"]
    ? registerValues["step_1"]
    : initialValuesBase;

  const formRef = useRef<any>();
  const history = useHistory();
  const slug = useContext(RegisterContext);
  const [isLoading, setIsLoading] = useState(false); 

  const saveData = async () => {
    if(formRef?.current.values?.code) {
      formRef?.current.setFieldValue("code", formRef?.current.values?.code.trim());
    }
    formRef?.current?.handleSubmit();
  };

 useRefreshErrorMessage((!!errorText || formRef?.current?.errors?.code) ? true : false, saveData); 


  return (
    <RegisterLaoyut 
      isLoad={isLoading}
      step={internalRoutes.EMPLOYER} 
      handlerSubmit={saveData}>
      <Box pt={{ base: "0px"}}>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
        >
          {t(`new_register.employer_title`)}
        </Text>
        <Text fontSize="16px" color="#8194AA">
          {t(`new_register.employer_text`)}
        </Text>
      </Box>

      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={ValidationSchema}
        onSubmit={async (values) => {
          setIsLoading(true); 
          let result: any = await appService.checkEmployerCode(values.code);
          setIsLoading(false); 
          if (result) {
            let data = {
              code: values.code,
              employer_name: result.data.data.name,
            };
            handlerLocaleStorage(data, "step_1");
            history.push(`/register/accept_terms${slug}`);
          } else {
            setCodeError(true); 
            setErrorText(t("validation_code.invalid")); 
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, touched, values, setFieldValue, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <VStack
              spacing={{ base: "unset", md: "20px" }}
              mt={{ base: "32px"}}
              mb={{ lg: "40px", "2xl": "120px" }}
              width="100%"
            >
              <HStack
                width="100%"
                h={{lg: "105px"}}
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={{ md: "20px", lg: "34px" }}
                wrap={{ base: "wrap", md: "nowrap" }}
              >
                <Box
                  w={{ base: "100%", md: "50%" }}
                  pr={{ md: "10px", lg: "17px" }}
                >
                  <TextField
                    mb={{ base: "unset" }}
                    error={errors.code ? (errors.code && touched.code) : codeError}
                    onChange={(e: any) => {
                      setFieldValue("code", e.target.value); 
                      if(codeError) {
                        setCodeError(false); 
                        setErrorText(""); 
                      }
                    }}
                    onBlur={(e:any) => {
                      const val = (e.target.value || '').replace(/\s+/gi, ' ');
                      setFieldValue("code", val.trim());
                      handleBlur(e);
                    }}
                    value={values.code}
                    name="code"
                    helpertext={errorText ? errorText : errors.code}
                    label={t(`new_register.employer_label`)}
                  />
                </Box>
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </RegisterLaoyut>
  );
}

export default EmployerCoverage;
