import React, { FC } from 'react';
import { Redirect, Route as BrowserRoute } from 'react-router-dom';

export type RouteProps = {
    path?: string | undefined;
    component: FC;
    check?: boolean;
    redirect?: string;
    exact?: any;
};

export const Route: FC<RouteProps> = ({ path, check, component, redirect, exact }: RouteProps) => {
    if ( check === true )
        return ( <BrowserRoute exact={exact} path={path} component={component} /> );
    return <Redirect to={ redirect || '/' } />
}
