import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import {
    Container,
    Text,
    Button,
    Link,
    Breadcrumb,
    Image,
    useDisclosure,
} from '@chakra-ui/react';
import { RepeatIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { AhInput } from '../../components/input';
import { SupportDialog } from '../../components/support.dialog';

import { initialValue } from './initial-value.form';
import { DisplayingErrorMessagesSchema } from './validation.form';

import { Formik, Form } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '../../app/routes';
import { HeaderLogo } from '../../components/header.logo';
import { AppService } from '../../app/modules/app';
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';

export const ForgotPassword: FC<any> = () => {
    const { t } = useTranslation();
    const { state, appService }: AppContextInterface = useContext<AppContextInterface>(AppContext);
    const [key, setKey] = useState();
    const history = useHistory();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const captchaRef: any = useRef(null);

    useEffect(() => {
        refreshCaptcha();
    }, []);

    const refreshCaptcha = () => appService.captcha().then(
        (rs :any) => {
            if ( captchaRef.current && rs?.data ) {
                captchaRef.current.src = rs?.data?.img;
                setKey(rs?.data?.key);
            }
        }
    );

    const onSubmit = async (values: any) => {
        const sent: boolean = await appService.sendResetLink( values, key );
        if ( sent === true )
            history.push(ROUTE_PATH.RESET_LINK_SENT)
        refreshCaptcha();
    }


    return (
        <>
            <SupportDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} />
            <Container
                mt={'5%'}
                mb="auto"
                width="100%"
                height="100%"
                backgroundColor="#FFFFFF"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="scroll">
            <HeaderLogo backLink={ROUTE_PATH.HOME} showLanguage={false} />
            <Text fontFamily={'Playfair Display'} fontWeight="bold" fontSize="2xl" mt="10%" ml={5}>
                {t('forgotPasswordScreen.pageTitle')}
            </Text>
            <Text fontFamily={'Playfair Display'} ml={5} fontSize="lg" mt={5}>
                {t('forgotPasswordScreen.subTitle')}
            </Text>
            { state.app.isErrored === true && 
                <Container mt={5} mb={5} ml={5} width={'90%'} background={'#FFE2E8'} borderRadius={'13px'}>
                    <Text mt={'8px'} mb={'6px'} color={'#F44970'} textAlign={'center'} fontFamily={'Mulish'} fontSize={'18px'}>
                        { t(state.app.errorMessage || '') }
                    </Text>
                </Container> }
            <Formik
                initialValues={initialValue}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={onSubmit}>
                { ({ errors, touched }) => (
                    <Form style={{ width: '100%' }}>
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} ml={5} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                            {t('forgotPasswordScreen.labelEmailInputText')}
                        </Text>
                        <AhInput error={state.app.isErrored} fontFamily={'Mulish'} name="email" ml={5} width="90%" type="email" placeholder={t('forgotPasswordScreen.labelEmailInputText')} pt={2} pb={2} />
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} fontSize="sm" ml={5} mt={2}  fontFamily={'Mulish'}>
                            {t('forgotPasswordScreen.labelCaptchaInputText')}
                        </Text>
                        <Container ml={5} mt={2} mb={2} display='flex' justifyContent='center'>
                            <Image ref={captchaRef} />
                            <Button background='transparent' onClick={refreshCaptcha}>
                                <RepeatIcon />
                            </Button>
                        </Container>
                        <AhInput error={state.app.isErrored} fontFamily={'Mulish'} name="captcha" ml={5} width="90%" type="text" placeholder={t('forgotPasswordScreen.labelCaptchaInputText')} pt={2} pb={2} />
                        <Button
                            variant="solid"
                            size="md"
                            width="90%"
                            mt={5}
                            rightIcon={<ArrowForwardIcon />}
                            backgroundColor="#FFDB00"
                            borderRadius="10pt"
                            ml={5}
                            fontFamily={'Mulish'}
                            type="submit">
                            {t('forgotPasswordScreen.btnResetPasswordText')}
                        </Button>
                    </Form>
                ) }
            </Formik>
            <Link
                alignSelf="center"
                mt={'80px'}
                textDecoration="underline"
                backgroundColor="#FFDB00"
                fontFamily={'Mulish'}
                pl={2}
                pr={2}
                onClick={onOpen}>
                {t('forgotPasswordScreen.linkForgotCredsText')}
            </Link>
            </Container>
        </>
    );
}
