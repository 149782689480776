import { MessageDataType } from "../../app/dto/message-data.dto";
import { AppService } from "../../app/modules/app";
import { MessageService } from "../../app/modules/message";
import { SessionService, SessionType } from "../../app/modules/session";
import { ROUTE_PATH } from "../../app/routes";

export const checkToken = ( 
    appService: AppService, 
    sessionService: SessionService,
    history: any,
) => {
    const query = new URLSearchParams(window.location.search);
    if ( query.has('token') === true ) {
      sessionService.loginToken(query.get('token') || '')
        .catch((e: any) => {
          let error = 'errors.misc';
          if ( e.response?.status === 401 )
              error = 'errors.authToken';
          appService.error(error);
          history.replace(ROUTE_PATH.HOME)
        });        
    }    
}

export const pingInterval = (
    session: SessionType,
    sessionService: SessionService
) => {
    let intervalFunc: any = null;
    if ( session ) {
        intervalFunc = setInterval( () => {
            sessionService.ping(session?.token)
        }, 60000);
    }
    return intervalFunc;
}

export const chatSync = ( refreshChat: () => void ) => {
    refreshChat();
}

export const reader = (file: File) => {
    return new Promise((resolve: any, reject: any) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        
        if(file){
            fileReader.readAsDataURL(file);
          }
    });
  }


export const addAIMessages = (
    state: any,
    welcomeMessageShowen: boolean,
    messageService: MessageService,
    t: any,
    setWelcomeMessageShowen: Function
) => {
    const alreadyAdded = state.message.messages.find((message: MessageDataType) => message.contents === t('defaultMessages.nPGreet', { first_name: state.session?.user?.first_name }));
   
    if ( welcomeMessageShowen === false && state.session && alreadyAdded === undefined) {
        if ( state.app.episodeType === '0' ) {
            //messageService.addAIMessage(t('defaultMessages.nPGreet', { first_name: state.session?.user?.first_name }));
            //messageService.addAIMessage(t('defaultMessages.nPGreet2'));
        }
        else if ( state.app.episodeType === '1' ) {
            //messageService.addAIMessage(t('defaultMessages.nPGreetCorona', { first_name: state.session?.user?.first_name }));
            //messageService.addAIMessage(t('defaultMessages.nPGreetCorona2'));
        }
        setWelcomeMessageShowen(true);
    }
}

async function createDownloadElementAndClick(blob: Blob, fileName: string) {
    let options = {
        method:"POST",
        body:blob
    };

    await fetch(`https://example.com/upload.php`, options);

    window.open(`https://example.com/download.php?${fileName}`, "_self");
}