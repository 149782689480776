import { Button } from '@chakra-ui/button';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, HStack } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import LogRocket from 'logrocket';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useEffectOnce } from 'react-use';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import LayoutComponent from '../../components/layoutComponent';
import {text} from './textAccept'; 
import {text_fr} from './textAccepFr'; 


function AcceptTermsOld() {

    const { state, appService }: AppContextInterface = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const history = useHistory(); 
    const [isLargerThan1400] = useMediaQuery("(min-width: 1400px)")
    const [isLargerThan1560] = useMediaQuery("(min-width: 1560px)")
    const [isLargerThan760] = useMediaQuery("(min-width: 760px)")

    return (
       <LayoutComponent>
          <Box h="100%" width="100%" display="flex" flexDirection="column" justifyContent="center">
              <Flex h="100%" width="100%"  pb={{md: "60px", lg:'unset'}} direction="column" justify="center" position="relative">
                <Box position="absolute" h="100%" width="100%" px={{base: "0px", md: "40px"}} display="flex" flexDirection="column">
                  <Box px={{base: "16px", md: "unset"}} h={{base:"20%", md: "15%"}} display="flex" alignItems={{base:"center", md: "end"}} pb={{base: "unset", md: "18px"}}>
                    <Text fontWeight="700" fontSize={{base: "32px", md: "40px"}} color="#304358">  {t('createPwdAgreement.agreementLinkTitleNew')}</Text>
                 </Box>
                  <Box height="80%" w="100%" overflowY="scroll" p="10px">
                    <Text>{i18n?.language === "en" ? text : text_fr}</Text>    
                  </Box>
                  <HStack width={{base: "100%", lg: "50%"}} justifyContent={{base: "center", md: "flex-start"}} position={{base: "absolute", md: "static"}} alignItems="center" bottom="0" spacing={{base: "0px", md: "20px", lg: "32px"}}  py={{md: "20px"}}  wrap={{base: "wrap", md: "nowrap"}}>
                     <Button 
                      onClick={()=> history.goBack()}
                      rightIcon={<ArrowForwardIcon />} 
                      fontSize="16px"
                      textTransform="none"
                      fontFamily="Mulish"
                      color="#304358"
                      fontWeight="700"
                      w={{base: "100%", md: "unset"}}
                      borderRadius={{base: "unset", md: "10pt"}}
                      backgroundColor="#FFDB00" 
                      variant="solid">
                         Back
                      </Button>
                </HStack>
              </Box>
            </Flex>
         </Box>   
       </LayoutComponent>
    )
}

export default AcceptTermsOld; 
