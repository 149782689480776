type ValidationRulesProps = {
    condition: boolean;
    label: string;
}

export const validationRules = ( value: string ): Array<ValidationRulesProps> => ([
    {
        condition: value.length < 8,
        label: 'validation.length_2'
    },
    {
        condition: /[A-Z]/.test(value) === false,
        label: 'validation.oneCapCharacter_2'
    },
    {
        condition: /[a-z]/.test(value) === false,
        label: 'validation.oneLowCharacter_2'
    },
    {
        condition: /[0-9]/.test(value) === false,
        label: 'validation.oneNumericCharacter_2'
    },
    {
        condition: /[!-\/:-@[-`{-~]/.test(value) === false,
        label: 'validation.oneSpecialCharacter_2'
    }
]);