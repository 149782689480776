import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, HStack, Text, VStack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/button";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router";
import { useMediaQuery } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../components/textField";
import PasswordField from "../../components/passwordField";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { useUnmount } from "react-use";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../app/routes";
import LayoutLogin from "../../components/layoutLogin";
import { PusherProvider, useEvent, usePusher } from "@harelpls/use-pusher";
import AddToHomeScreenBanner from "../../components/addToHomeScreenBanner";
import { checkIsIOS } from "../../utils/helpers";

type PusherConfig = {
  clientKey: string; 
  cluster: string;
}

export default function LoginNew(props:any) {
  const [ pusherConfig, setPusherConfig ] = useState<PusherConfig>({
    clientKey: "",
    cluster: "", 
  });
  const {appService }: AppContextInterface = useContext(AppContext);

  const useForceUpdate = () => {
    const [, setState] = useState<any>();
    return () => setState({});
  }
  const forceUpdate = useForceUpdate();

  const getConfig = async () => {
    const response:any = await appService.getPusherConfig(); 
    if(response && response?.status === 200) {
        setPusherConfig({clientKey: response?.data?.data?.key, cluster: response?.data?.data?.cluster}); 
        forceUpdate();
    }
  }

  useEffect(() => {
    getConfig(); 
  }, []); 

  return (
    <>
      {!!pusherConfig.clientKey 
        ? <PusherProvider {...pusherConfig}>
            <LoginNewItem {...props}/>
        </PusherProvider> 
        : <LoginNewItem {...props}/> }
    </>
        
  )
}

function LoginNewItem(props: any) {
  const { t, i18n } = useTranslation();
  const { state, sessionService, appService }: AppContextInterface =
    useContext(AppContext);

  const { client }: any = usePusher();
  const [ isReload, setIsReload ] = useState(false); 
  const [ openBanner, setOpenBanner ] = useState(true); 
  let channel = client?.subscribe('tap.global');

  useEvent(channel, 'pusher:subscription_succeeded', () => {
      console.debug('[PUSHER] subscribed to top global channel')
  });
  
  useEvent(channel, 'pusher:subscription_error', () => {
      console.debug('[PUSHER] Error occurred while subscribing to top global channel')
  });

  useEvent(
      channel,
      'App\\Events\\Broadcast\\DeployFinishedEvent',
        (data: any) => {    
          if(data?.source === "backend" || data?.source === "patient") {
              //setIsReload(true); 
              window.location.reload();
          }
      }
  );  

  const ValidationSchema = Yup.object().shape({
    email_address: Yup.string().email().trim().required("Field is required"),
    password: Yup.string().min(5).required("Field is required"),
  });

  let initialValues = {
    email_address: "",
    password: "",
  };

  useUnmount(() => {
    appService.clearError();
  });

  const history = useHistory();

  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

  const handlerClick = async (values: any) => {
    const resp: any | null = await sessionService.login(
      values.email_address.trim(),
      values.password,
      i18n.language
    );
    if (resp?.episode?.patient?.language) {
      i18n.changeLanguage(resp?.episode?.patient?.language);
    }
    if (resp && resp?.first_login) {
      history.replace(ROUTE_PATH.FAMILY_REGISTER);
    }
  };

  function isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  const checkChromeOnSafari = () => {
    if(/CriOS/i.test(window.navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(window.navigator.userAgent)){
        return true;
    }else{
        return false;
    }
  }

  const isShowBanner = () => {
      const isRemind = localStorage.getItem('remind'); 
      if(!isLargerThan480 && (isRemind === 'true' || !isRemind) && !isRunningStandalone() && !checkChromeOnSafari()) {
        return true; 
      } else {
        return false; 
      }
  }

  const cancelRef = useRef();

  return (
    <LayoutLogin isReload={isReload}>
        <AddToHomeScreenBanner isOpen={(openBanner && isShowBanner())} onClose={() => setOpenBanner(false)} cancelRef={cancelRef}/> 
      <Box
        height={state.app.isErrored ? '100vh' : "100%"}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minH={"100%"}
      >
        <Flex
          height="100%"
          pb={{ base: "0px", md: "60px", lg: "unset" }}
          direction="column"
          justify={"flex-start"}
        >
          <Box
            h="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Flex
              pb={{ md: "60px", lg: "unset" }}
              direction="column"
              justify="center"
            >
              <Box px={{ base: "16px", md: "40px" }}>
                <Text
                  fontWeight="700"
                  fontSize={{ base: state.app.isErrored ? "20px" : "24px", md: "40px" }}
                  color="#304358"
                >
                  {" "}
                  {t(`new_register.title_login`)}
                </Text>
              </Box>

              {state.app.successMessage !== null && (
                <VStack
                  px={{ base: "16px", md: "40px" }}
                  spacing={{ base: "unset", md: "20px" }}
                  mt={{ base: "32px", lg: "40px" }}
                  align="start"
                  width="100%"
                >
                  <HStack
                    width={{ base: "100%", lg: "50%" }}
                    justifyContent="flex-start"
                    spacing={{ md: "20px", lg: "34px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                  >
                    <Container
                      width={"100%"}
                      maxWidth={"100%"}
                      ml={"0"}
                      background={"#E3FFF8"}
                      borderRadius={"13px"}
                    >
                      <Text
                        mt={"8px"}
                        mb={"6px"}
                        color={"#60CBB2"}
                        textAlign={"center"}
                        fontFamily={"Mulish"}
                        fontSize={"18px"}
                      >
                        {t(state.app.successMessage || "")}
                      </Text>
                    </Container>
                  </HStack>
                </VStack>
              )}

              {state.app.isErrored === true && (
                <VStack
                  px={{ base: "16px", md: "40px" }}
                  spacing={{ base: "unset", md: "20px" }}
                  mt={{ base: "12px", lg: "40px" }}
                  align="start"
                  width="100%"
                >
                  <HStack
                    width={{ base: "100%", lg: "50%" }}
                    justifyContent="flex-start"
                    spacing={{ md: "20px", lg: "34px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                  >
                    <Container
                      width={"100%"}
                      maxWidth={"100%"}
                      ml={"0"}
                      background={"#FFE2E8"}
                      borderRadius={"13px"}
                    >
                      <Text
                        mt={"8px"}
                        mb={"6px"}
                        color={"#F44970"}
                        textAlign={"center"}
                        fontFamily={"Mulish"}
                        fontSize={"18px"}
                      >
                         { t(state.app.errorMessage || "") }  
                      </Text>
                    </Container>
                  </HStack>
                </VStack>
              )}

              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values) => {
                  handlerClick(values);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                  setTouched,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <VStack
                      px={{ base: "16px", md: "40px" }}
                      spacing={{ base: "unset", md: "20px" }}
                      mt={{ base: "32px", lg: "40px" }}
                      align="start"
                      mb={{ base: "unset", lg: "30px", xl: "50px" }}
                      width="100%"
                    >
                      <HStack
                        width={{ base: "100%", lg: "50%" }}
                        justifyContent="flex-start"
                        spacing={{ md: "20px", lg: "34px" }}
                        wrap={{ base: "wrap", md: "nowrap" }}
                      >
                        <TextField
                          mb={{ base: "16px", md: "unset" }}
                          placeholder={t("loginScreen.labelEmailInputText")}
                          error={errors.email_address && touched.email_address}
                          onChange={handleChange}
                          value={values.email_address}
                          name="email_address"
                          label={t("loginScreen.labelEmailInputText")}
                        />
                      </HStack>

                      <HStack
                        width={{ base: "100%", lg: "50%" }}
                        spacing={{ md: "20px", lg: "34px" }}
                        wrap={{ base: "wrap", md: "nowrap" }}
                      >
                        <PasswordField
                          mb={{ base: "24px", md: "unset" }}
                          placeholder={t("loginScreen.labelPasswordInputText")}
                          name="password"
                          error={errors.password && touched.password}
                          value={values.password}
                          onChange={handleChange}
                          label={t("loginScreen.labelPasswordInputText")}
                        />
                      </HStack>

                      <HStack
                        width={{ base: "100%", lg: "50%" }}
                        justifyContent={{ base: "center", md: "flex-start" }}
                        spacing={{ base: "0px", md: "20px", lg: "32px" }}
                        wrap={{ base: "wrap", md: "nowrap" }}
                        pt={{ base: "12px", md: "32px" }}
                      >
                        {isLargerThan760 && (
                          <Button
                            type="submit"
                            rightIcon={<ArrowForwardIcon />}
                            fontSize="16px"
                            textTransform="none"
                            fontFamily="Mulish"
                            color="#304358"
                            fontWeight="700"
                            borderRadius="10pt"
                            backgroundColor="#FFDB00"
                            variant="solid"
                          >
                            {t("loginScreen.loginBtnText")}
                          </Button>
                        )}

                        <Button
                          fontSize="16px"
                          textTransform="none"
                          onClick={() => history.push("/forgot-password")}
                          textDecoration="underline"
                          fontFamily="Mulish"
                          color="#304358"
                          fontWeight="700"
                          borderRadius="none"
                          px="8px"
                          py="4px"
                          cursor="pointer"
                          height="auto"
                          backgroundColor="#FFF19A"
                          variant="solid"
                        >
                          {t("loginScreen.helpLinkText")}
                        </Button>
                      </HStack>

                      {!isLargerThan760 && (
                        <Flex width={{ base: "100%", md: "unset" }}>
                          <Box
                            w="100%"
                            bottom={{
                              base: state.app.isErrored ? "20px" : "50px",
                              md: "0",
                            }}
                            display="flex"
                            alignItems="center"
                            pl={{ base: "none", "2xl": "80px" }}
                            px={{base: "20px", md: "none"}}
                            justifyContent={{
                              base: "center",
                              md: "flex-start",
                            }}
                          >
                            <Text fontSize="14px" p="30px 0px 32px 0px">
                              {t("loginScreen.question")}{" "}
                              <Link to="/register" style={{ color: "#40B89C" }}>
                                {t("loginScreen.link_register")}
                              </Link>{" "}
                            </Text>
                          </Box>
                        </Flex>
                      )}
                    </VStack>

                    {!isLargerThan760 && (
                      <Button
                        type="submit"
                        rightIcon={<ArrowForwardIcon />}
                        fontSize="16px"
                        textTransform="none"
                        fontFamily="Mulish"
                        height="70px"
                        paddingBottom={"30px"}
                        color="#304358"
                        fontWeight="700"
                        width="100%"
                        position="absolute"
                        bottom="0"
                        borderRadius="none"
                        backgroundColor="#FFDB00"
                        variant="solid"
                      >
                        {t("loginScreen.loginBtnText")}
                      </Button>
                    )}
                  </form>
                )}
              </Formik>
            </Flex>
          </Box>
        </Flex>
        <Flex
          align="center"
          h={{ base: "auto", md: "105px" }}
          justify={{ base: "center", md: "space-between" }}
          borderTop={isLargerThan760 ? "1px solid #D3D9E0" : "unset"}
          direction={{ base: "column", md: "row" }}
        >
          {isLargerThan760 && (
            <Flex width={{ base: "100%", md: "unset" }}>
              <Box
                w="100%"
                bottom={{
                  base: state.app.isErrored ? "20px" : "50px",
                  md: "0",
                }}
                display="flex"
                alignItems="center"
                pl={{ base: "40px", "2xl": "80px" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Text fontSize="16px" p="30px 0px 32px 0px">
                  {t("loginScreen.question")}{" "}
                  <Link to="/register" style={{ color: "#40B89C" }}>
                    {t("loginScreen.link_register")}
                  </Link>{" "}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
    </LayoutLogin>
  );
}
