import { Box, Flex, Text } from "@chakra-ui/layout";
import { useContext, useRef, useState } from "react";
import TextField from "../../components/textField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMediaQuery, HStack, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/button";
import { useHistory } from "react-router";
import { RegisterContext } from "../../app/navigation";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import FamilyRegisterLaoyut from "./FamilyRegisterLayout";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { ROUTE_PATH } from "../../app/routes";
import { useRefreshErrorMessage } from "../../utils/hooks";

function InviteSpouse(props: any) {
  const formRef = useRef<any>();
  const history = useHistory();
  const { t } = useTranslation();
  const [inviteAddress, setInviteAddress] = useState<any>(null);
  const [isLoad, setIsLoad] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [errorText, setErrorText] = useState(""); 
  const { state, appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);

  const [isLessMdSize] = useMediaQuery("(max-width: 768px)");
  const [isLessThan370] = useMediaQuery("(max-width: 400px)");

  const slug = useContext(RegisterContext);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required("Field is required"),
    first_name: Yup.string().required("Field is required").max(200),
    last_name: Yup.string().required("Field is required").max(200),
  });

  let initialValues = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const nextStep = () => {};
  useRefreshErrorMessage(!!errorText, formRef?.current?.handleSubmit);

  return (
    <FamilyRegisterLaoyut
      step={2}
      handlerSubmit={nextStep}
      title_btn={t("new_register.skip_step")}
    >
      {!inviteAddress && <>
        <Flex
          alignItems="center"
          px={{ base: "16px", md: "unset" }}
          height={{ base: "80px", md: "100px", lg: "112px" }}
          pt={{base: "40px", md: "unset"}}
          cursor="pointer"
          onClick={() => history.push(ROUTE_PATH.HOME)}
        >
          <ArrowBackIcon />
          <Text fontSize="16px" fontWeight="bold" ml="10px">
            {t("new_register.home")}
          </Text>
        </Flex>
      <Box
        px={{ base: "16px", md: "unset" }}
        pt={{
          base: inviteAddress ? "80px" : "unset",
          md: inviteAddress ? "100px" : "unset",
          lg: inviteAddress ? "112px" : "unset",
        }}
      >
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
          py="8px"
          lineHeight="48px"
        >
          {t(`new_register.add_spouse_title`)}
        </Text>
      </Box>
      <Box px={{ base: "16px", md: "unset" }}>
        <Text color="#8194AA" fontSize="16px">
          {t("new_register.add_spouse_text")}
        </Text>
      </Box>
        <Box h={{md: "100%"}}>
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validationSchema={ValidationSchema}
            onSubmit={async (values) => {
              const data = {
                ...values,
                relation_type: 1,
              };
              setIsLoad(true); 
              let result = await appService.dependentsInvite(
                state.session?.token,
                data
              );
              setIsLoad(false); 
              if (result) {
                setInviteAddress({...values});
              } else {
                setEmailError(true); 
                setErrorText(t('validation_code.email_error')); 
              }
            }}
          >
            {({ errors, handleChange, handleSubmit, touched, values, setFieldValue }) => (
              <form
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                onSubmit={handleSubmit}
              >
                <VStack
                  spacing={{ base: "unset", md: "20px" }}
                  width="100%"
                  h="100%"
                >
                  <HStack
                    width="100%"
                    pt="32px"
                    px={{ base: "16px", md: "unset" }}
                    spacing={{ md: "20px", lg: "34px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                  >
                     <TextField
                      mb={{ base: "24px", md: "unset" }}
                      placeholder={t(`new_register.firstName`)}
                      name="first_name"
                      error={errors.first_name && touched.first_name}
                      onChange={handleChange}
                      value={values.first_name}
                      label={t(`new_register.firstName`)}
                    />
                    <TextField
                      mb={{ base: "24px", md: "unset" }}
                      placeholder={t(`new_register.lastName`)}
                      name="last_name"
                      data-private
                      onChange={handleChange}
                      error={errors.last_name && touched.last_name}
                      value={values.last_name}
                      label={t(`new_register.lastName`)}
                    />
                  </HStack>
                  <HStack
                    width={"100%"}
                    px={{ base: "16px", md: "unset" }}
                    justifyContent="flex-start"
                    spacing={{ md: "20px", lg: "34px" }}
                  >
                    <Flex w={{base: "100%", lg: "50%"}} pr={{ md: "10px", lg: "17px" }}>
                      <TextField
                        placeholder={t("loginScreen.labelEmailInputText")}
                        value={values.email}
                        mb={{ base: !!errorText ? "unset" : "24px", md: "unset" }}
                        error={errors.email ? (errors.email && touched.email) : emailError}
                        onChange={(e: any) => {
                          setFieldValue("email", e.target.value); 
                          if(emailError) {
                            setEmailError(false); 
                            setErrorText(""); 
                          }
                        }}
                        name="email"
                        helpertext={errorText}
                        label={t("loginScreen.labelEmailInputText")}
                      />
                    </Flex>

                  </HStack>
                  <HStack
                    width="100%"
                    h="100%"
                    justifyContent={{ base: "center", md: "flex-start" }}
                    alignItems={{base: "flex-end", md: "flex-start"}}
                    spacing={{ base: "0px", md: "20px", lg: "32px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                    pt={{ base: "32px" }}
                  >
                    <Button
                      type="submit"
                      rightIcon={<ArrowForwardIcon />}
                      fontSize="16px"
                      textTransform="none"
                      fontFamily="Mulish"
                      color="#304358"
                      fontWeight="700"
                      width={{base: "100%", md: "unset"}}
                      borderRadius={{base: "0px", md: "10pt"}}
                      backgroundColor="#FFDB00"
                      variant="solid"
                    >
                      {t("new_register.send_invitation")}
                    </Button>
                  </HStack>
                </VStack>
              </form>
            )}
          </Formik>
        </Box>
        </>}
      {inviteAddress && (
        <Box h="100%" display="flex" flexDirection="column" justifyContent="center">
          <Text
            color="#304358"
            fontSize="32px"
            fontWeight="800"
            pb="32px">
            <Text as="span" color="#40B89C">{inviteAddress.first_name}</Text>
            {t("new_register.success_invite")}
          </Text>
          <Text
            color="#304358"
            fontSize="20px"
            fontWeight="800"
            pb="8px"
          >
            {t("new_register.invate_was_send")}
            <Text as="span" color="#40B89C">
              {inviteAddress.email}
            </Text>
          </Text>
          <Text color="#8194AA" fontSize="16px" pb="8px">
            <Text as="span" color="#40B89C">{inviteAddress.first_name}</Text>
            {t("new_register.invite_was_send_text")}
          </Text>
          <Flex
            alignItems="center"
            width={{ base: "100%", md: "unset" }}
            pt="32px"
            position={{ base: "absolute", md: "static" }}
            bottom="0"
            left="0"
            right="0"
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
              onClick={() => history.push(ROUTE_PATH.HOME)}
              textTransform="none"
              fontFamily="Mulish"
              color="#304358"
              fontWeight="700"
              width={{ base: "50%", md: "unset" }}
              borderRadius={{ base: "0", md: "10pt" }}
              backgroundColor="#FFDB00"
              variant="solid"
            >
              {t("new_register.done")}
            </Button>
            {!isLessMdSize && (
              <Text color="#8194AA" fontSize="16px" mx="20px">
                {t("new_register.or")}
              </Text>
            )}
            <Button
              fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
              textTransform="none"
              fontFamily="Mulish"
              color="#304358"
              onClick={() => history.push(`/family/${3}${slug}`)}
              fontWeight="700"
              width={{ base: "50%", md: "unset" }}
              borderRadius={{ base: "0", md: "10pt" }}
              backgroundColor="#FFF19A"
              variant="solid"
            >
              {t("new_register.btn_add_child")}
            </Button>
          </Flex>
        </Box>
      )}
    </FamilyRegisterLaoyut>
  );
}

export default InviteSpouse;
