import { Text } from "@chakra-ui/layout";
import React, { useContext, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContextInterface } from "../app/dto/app-context.dto";
import { AppContext } from "../app/modules";
import { Box, Flex } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import moon_icon from "../assets/icons/moon.svg";
import moment from "moment";

type THoliday = {
  name: string,
  date: string, 
}

export const WTTooltip: FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { state }: AppContextInterface = useContext(AppContext);
  const [holiday, setHoliday] = useState<THoliday[]>([]); 

  const holidaysList: THoliday[] = [
    { name: `wttooltip.holiday_8`, date: "2023-04-10" }, 
    { name: `wttooltip.holiday_1`, date: "2023-05-22" }, 
    { name: `wttooltip.holiday_2`, date: "2023-06-26" }, 
    { name: `wttooltip.holiday_3`, date: "2023-07-03" }, 
    { name: `wttooltip.holiday_4`, date: "2023-09-04" }, 
    { name: `wttooltip.holiday_9`, date: "2023-09-30" }, 
    { name: `wttooltip.holiday_5`, date: "2023-10-09" }, 
    { name: `wttooltip.holiday_6`, date: "2023-12-25" }, 
    { name: `wttooltip.holiday_7`, date: "2023-01-01" }, 

    { name: `wttooltip.holiday_8`, date: "2024-03-31" }, 
    { name: `wttooltip.holiday_1`, date: "2024-05-20" }, 
    { name: `wttooltip.holiday_2`, date: "2024-06-24" }, 
    { name: `wttooltip.holiday_3`, date: "2024-07-01" }, 
    { name: `wttooltip.holiday_4`, date: "2024-09-02" }, 
    { name: `wttooltip.holiday_9`, date: "2024-09-30" }, 
    { name: `wttooltip.holiday_5`, date: "2024-10-14" }, 
    { name: `wttooltip.holiday_6`, date: "2024-12-25" }, 
    { name: `wttooltip.holiday_7`, date: "2024-01-01" }, 

    { name: `wttooltip.holiday_8`, date: "2025-04-20" }, 
    { name: `wttooltip.holiday_1`, date: "2025-05-19" }, 
    { name: `wttooltip.holiday_2`, date: "2025-06-24" }, 
    { name: `wttooltip.holiday_3`, date: "2025-07-01" }, 
    { name: `wttooltip.holiday_4`, date: "2025-09-01" }, 
    { name: `wttooltip.holiday_9`, date: "2025-09-30" }, 
    { name: `wttooltip.holiday_5`, date: "2025-10-13" }, 
    { name: `wttooltip.holiday_6`, date: "2025-12-25" }, 
    { name: `wttooltip.holiday_7`, date: "2025-01-01" }, 
  ]; 

  let momentTz = require("moment-timezone");
  const dateTz = +momentTz.tz("America/Toronto").format("d");
  const tzCa = +momentTz.tz("America/Toronto").format("HH");
  const currentDateToronto = momentTz.tz("America/Toronto").format();

  const currentTimeZone = momentTz.tz.guess();
  const dateTzCurrent = +momentTz.tz(currentTimeZone).format("d"); 
  const tzCaCurrent = +momentTz.tz(currentTimeZone).format("HH");

  const diff = +momentTz.tz(currentTimeZone).format('Z').split(':')[0];

  const checkIsHoliday = () => {
    const now = moment(currentDateToronto);
    const arr: THoliday[] = [];
    holidaysList.forEach(item => {
      const end = moment(item.date); 
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
     
      const isToday = moment(item.date).isSame(now, 'day');
      if((days < 7 && days > 0) || isToday) {
        console.log('work')
        arr.push(item);
      }
    }); 
    setHoliday(arr); 

  }

  const colors: any = {
    '0': '#c14f4f',
    '1': '#b2c14f',
    '2': '#4facc1',
    '3': '#4f82c1',
  }

  let workHoursState = false;

  if(diff <= -3 && diff > -8 ) {
    workHoursState = tzCaCurrent >= 20 || tzCaCurrent < 8 || dateTzCurrent === 6 || dateTzCurrent === 0;
  } else {
    workHoursState = tzCa >= 20 || tzCa < 8 || dateTz === 6 || dateTz === 0;
  }
  
  useEffect(() => {
    checkIsHoliday(); 
  }, []);  

  return (
    <>
      <div
        style={{
          width: "100%",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
        }}
      >
        {workHoursState && (
          <Flex
            style={{
              backgroundColor: "#F8B509",
              width: "100%",
            }}
            top={{ base: "76px", lg: "86px" }}
            py={{ base: "10px", lg: "28px", "2xl": "28px" }}
            px={{ base: "7px", md: "20px", lg: "28px", "2xl": "28px" }}
          >
            <Image
              alt="all-health"
              src={moon_icon}
              maxW={{ base: "30px", lg: "51px" }}
            />
            <Box ml={{ base: "10px", lg: "22px" }}>
              <Text
                style={{ fontWeight: "bold" }}
                fontSize={{ base: "11px", md: "14px", lg: "17px" }}
              >
                {" "}
                {t(`wttooltip.textInfo1`)}
              </Text>
              <Text fontSize={{ base: "11px", md: "14px", lg: "17px" }}>
                {t(`wttooltip.textInfo2`)}
              </Text>
            </Box>
          </Flex>
        )}

        {holiday.length ? holiday.map((item, idx) => <Flex
            key={item.date}
            style={{
              backgroundColor: colors[`${idx}`],
              width: "100%",
              boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
              alignItems: "center",
            }}
            top={{ base: "76px", lg: "86px" }}
            py={{ base: "10px", lg: "28px", "2xl": "28px" }}
            px={{ base: "7px", md: "20px", lg: "28px", "2xl": "28px" }}
          >
            <Box ml={{ base: "10px", lg: "22px" }}>
              <b>
                <Text
                  style={{ color: "white" }}
                  fontSize={{ base: "11px", md: "14px", lg: "17px" }}
                >
                  {t(moment(currentDateToronto).isBefore(item.date) ? `wttooltip.textBefore` : `wttooltip.textCurrent`, {name: t(item.name)})}
                </Text>
              </b>
            </Box>
          </Flex>)  : ''}
      </div>
    </>
  );
};
