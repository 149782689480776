import { createStandaloneToast } from '@chakra-ui/react';
import Axios from 'axios';

import Uris from '../../api/uris';
import { SessionActions, SessionService } from '../session';
import { handleError } from '../utils';
import { AppActions } from "./app.action";

const toast = createStandaloneToast();

export class AppService {

    constructor ( 
        private readonly dispatch: any 
    ){}

    async error( errorMessage: string ) {
        this.dispatch({ type: AppActions.APP_ERROR, payload: { errorMessage, isErrored: true } });
    }

    async success( successMessage: string ) {
        this.dispatch({ type: AppActions.APP_SUCCESS, payload: { successMessage } });
    }

    async clearError() {
        this.dispatch({ type: AppActions.APP_ERROR, payload: { errorMessage: null, isErrored: false, successMessage: null } });
    }

    async captcha() {
        try {
            return await Axios(Uris.captcha());
        }
        catch( e: any ) {
            const description = "Error with captcha"; 
            handleError(e, description, toast, this.dispatch);
        }
    }

    async sendResetLink(data: any, key: any) {
        this.clearError();
        try {
            await Axios.post(`${Uris.forgotPwd()}?key=${key}`, data);
            return true;
        }
        catch( e: any ) {
            // let error = 'errors.misc';
            let error = 'errors.captcha';
            if ( e.response?.status === 401 )
                error = 'errors.auth';
            else if ( e.response?.status === 422 )
                error = 'errors.captcha';  
            this.error(error);
            console.error(e.response);
            return false;
        }
    }

    async resetPassword( data: any, token: string | undefined ) {
        try {
            await Axios.post(Uris.resetPwd(), {...data, token});
            return true;
        }
        catch( e: any ) {
            const description = "Reset password error"; 
            handleError(e, description, toast, this.dispatch);
            return false;
        }
    }

    async setPassword( data: any, token: string | undefined, lang: string | undefined ) {
        try {
            await Axios.post(Uris.setPwd(), {...data, token, lang});
            return true;
        }
        catch( e: any ) {
            const description = "Error with  password setup";
            handleError(e, description, toast, this.dispatch);
            return false;
        }
    }

    async setEpisodeType( type: string, token: string | undefined ) {
        try {
            this.dispatch({ type: AppActions.APP_EP_TYPE, payload: type });
            return Axios.post(Uris.setEpisodeType(), { type }, { headers: { Authorization: `Bearer ${token}` } });
        }
        catch( e: any ) {
            const description = "Error with type episode setup"; 
            handleError(e, description, toast, this.dispatch);
        }
    }

    async acceptTos ( token: string ) {
        try {
            await Axios.post(Uris.acceptTos(), { token } );
            return true;
        } catch( e ) {
            const description = "Error with accept tos";
            handleError(e, description, toast, this.dispatch);
            return false;
        }
    }

    async register ( data: any, slug: string = "submit" ) {
        try {
            const response: any = await Axios.post(Uris.register(slug), data ); 
            if(response) {
                this.dispatch({ type: SessionActions.LOGIN, payload: { token: response.data.token, episode: response.data.episode, pusher: response.data.pusher } });
            }
            return true;
        } catch( e ) {
            const description = "Error with register";
            handleError(e, description, toast, this.dispatch);
            return false;
        }
    }

    async checkEmployerCode (code: string) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('code', code);
            return await Axios.post(Uris.checkEmployerCode(), bodyFormData );
        } catch (e) {
            //handleError(e, toast, this.dispatch);
            return false; 
        }
    }

    async checkEmail (email: string) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('email', email);
            const res: any = await Axios.post(Uris.checkEmail(), bodyFormData );
            return res.data?.data?.email_taken; 
        } catch (e) {
            const description = "Check email error"; 
            handleError(e, description, toast, this.dispatch);
            return false; 
        }
    }

    async dependentsInvite( token: string | undefined, data: any  ) {
        try {
            await Axios.post(Uris.dependentsInvite(), data, { headers: { Authorization: `Bearer ${token}` } });
            return true; 
        }
        catch( e: any ) {
            //handleError(e, toast, this.dispatch);
            return false; 
        }
    }

    async dependentsCreate( token: string | undefined, data: any  ) {
        try {
            await Axios.post(Uris.createDependents(), data, { headers: { Authorization: `Bearer ${token}` } });
            return true; 
        }
        catch( e: any ) {
            const description = "Error with  create dependent"; 
            handleError(e, description, toast, this.dispatch);
            return false; 
        }
    }

    async checkResetToken ( token: string ) {
        return await Axios.post( Uris.checkResetPwdToken(), { token });
    }

    async setFirstLogin ( token: string ) {
        return await Axios.patch( Uris.setFirstLogin(), { token });
    }

    async checkSetToken ( token: string ) {
        return await Axios.post( Uris.checkSetPwdToken(), { token });
    }

    async checkRegisterToken ( token: string ) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('token', token);
            return await Axios.post( Uris.checkRegisterToken(), bodyFormData); 
        } catch (e: any) {
            if(e.response?.status === 409) {
                return 409; 
            }
            //handleError(e, toast, this.dispatch);
        }
    }

    async getPusherConfig() {
        try {
            return await Axios.get( Uris.getPusherConfig()); 
        } catch (e: any) {
            return false; 
        }
    }

    async setMultiplier( multiplier: number ) {
        this.dispatch({ type: AppActions.APP_MULTIPLIER, payload: multiplier });
    }
    
    async setIsAccept (value: boolean) {
        this.dispatch({type: AppActions.APP_ACCEPT, payload: value }); 
    }

    async uploadQuestionnaireFile(file: any) {
        this.dispatch({ type: AppActions.APP_UPLOAD_FILE, payload: file }); 
    }

    async clickUploadFile(value: boolean) {
        this.dispatch({ type: AppActions.APP_CLICK_UPLOAD, payload: value }); 
    }

    async clearUploadFile(value: boolean) {
        this.dispatch({ type: AppActions.APP_CLEAR_FILE, payload: value }); 
    }
}  
