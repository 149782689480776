import { SessionType } from "./session.type";
import { SessionActions } from './session.action';
import { sessionInitialState } from './session.state';
import { ActionInterface } from "../utils";

export const sessionReducer = ( state: SessionType | null, action: ActionInterface ) => {
    switch( action.type ) {
        case SessionActions.LOGIN:
            return { ...state, ...action.payload };
        case SessionActions.LOGOUT:
            return sessionInitialState;
        default:
            return state;
    }
}