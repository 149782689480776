import combineReducers from 'react-combine-reducers';
import { appReducer, appInitialState } from './app';
import { sessionReducer, sessionInitialState } from './session';
import { messageReducer, messageInitialState } from './message';

export const combinedReducers = combineReducers({
    app: [ appReducer, appInitialState ],
    session: [ sessionReducer, sessionInitialState ],
    message: [ messageReducer, messageInitialState ],     
});
