import React, { useContext, useEffect, useRef, useState } from 'react';
import { Center, Container, HStack, Text, VStack } from '@chakra-ui/layout';
import { Input, InputGroup } from '@chakra-ui/input';
import { Image } from '@chakra-ui/image';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { useMediaQuery, useDisclosure } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../app/routes';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextField from '../../components/textField';
import logo_icon from '../../assets/icons/logo_icon_register.svg';
import PasswordField from '../../components/passwordField';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import RadioFields from '../../components/radioFields';
import LayoutComponent from '../../components/layoutComponent';
import { InvalidTokenSupportDialog } from '../../components/invalid-token-support.dialog';
import { usePasswordSetTokenValidator,  useFormHandler } from './hooks';
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';
import CheckboxField from '../../components/checkboxField';
import { useRefreshErrorMessage } from '../../utils/hooks';



function CreatePasswordNew() {

    const { t, i18n } = useTranslation();
    const { state, appService }: AppContextInterface = useContext(AppContext);
    const history = useHistory();
    const formRef = useRef<any>();

    const { onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const [ invalidToken, setInvalidToken ] = useState(false);

    const query: any = new URLSearchParams(window.location.search);
    usePasswordSetTokenValidator({ appService, token: query.get('token'), onErrorCallback:
        // redirect user on invalid pwd
       () => setInvalidToken(true)
    });
    
    
    const ValidationSchema = Yup.object().shape({
      password: Yup.string().required('validation.required').matches(/^.*(?=.{8,})(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~]).*)$/, 'validation.pwdInvalid'),
      password_confirmation: Yup.string().required(t("validation.required", {field: "Password"})).oneOf([Yup.ref('password'), null], t("validation.cnfPwdInvalid")),
      accept: Yup.boolean().oneOf([true]).required(),
    });

    let initialValues = {
        password: '',
        password_confirmation: '',
        accept: "",
      }; 
  
    

   const [isLargerThan760] = useMediaQuery("(min-width: 760px)")

  const AcceptLink =(props:any) => {
      return <Text fontSize="12px"
                   fontFamily="Mulish"
                   color={props.error ? "#F44970" : "#304358"}>
        {t('createNewPassword.iAcceptThe')}
        <Link style={{color: "rgba(96, 203, 178, 1)"}} to="/accept-terms"> {t('createNewPassword.termsAndConditions')} </Link>
      </Text>
  }

    const [ onSetPasswordFormSubmit ] = useFormHandler({ appService, token: query.get('token'), history, t });

    useRefreshErrorMessage(!!formRef?.current?.errors?.password_confirmation, formRef?.current?.handleSubmit); 
    
    return (
         <LayoutComponent> 

    {/*         <InvalidTokenSupportDialog defaultSupportMessage={t('invalidTokenSupportDialog.helpLabelCreatePwd')} isOpen={invalidToken} onClose={onClose} cancelRef={cancelRef} />  */}  

            <Box h="100%" width="100%" display="flex" flexDirection="column" justifyContent="center">
              <Flex  pb={{md: "60px", lg:'unset'}} direction="column" justify="center">
                <Box px={{base: "16px", md: "40px"}}>
                  <Text fontWeight="700" fontSize={{base: "32px", md: "40px"}} color="#304358">  {t('createNewPassword.pageTitle')}</Text>
                </Box>

  
              <Formik
                  initialValues={initialValues}
                  innerRef={formRef}
                  validationSchema={ValidationSchema}
                  onSubmit={(values) => { 
                    onSetPasswordFormSubmit(values);                  
                  }} >
                  {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                  setTouched,
                  isSubmitting,
                  touched,
                  values,
                  }) => (
                  <form 
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }} 
                    onSubmit={handleSubmit}>

                  <VStack px={{base: "16px", md: "40px"}} spacing={{base: 'unset', md:"20px"}} mt={{base:"32px", lg: "40px" }} align="start" mb={{ base:"unset", lg: "30px", xl: "50px", '2xl': '250px'}} width="100%">

                    <HStack width={{base: "100%", lg: "50%"}} justifyContent="flex-start" spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                      <PasswordField 
                          mb={{base: '24px', md:'unset'}}
                          placeholder={t('createNewPassword.newPwdInpLabel')}
                          name="password"
                          isDropdown
                          error={errors.password && touched.password} 
                          value={values.password}
                          onChange={handleChange}
                          handler={()=>{}}
                          label={t('createNewPassword.newPwdInpLabel')}/>  
                    </HStack>

                    <HStack width={{base: "100%", lg: "50%"}} spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>

                        <PasswordField 
                          mb={{base: '24px', md:'unset'}}
                          placeholder= {t('createNewPassword.cnfNewPwdInpLabel')}
                          name="password_confirmation"
                          error={errors.password_confirmation && touched.password_confirmation} 
                          value={values.password_confirmation}
                          onChange={handleChange}
                          handler={()=>{}}
                          helperText={errors.password_confirmation}
                          label= {t('createNewPassword.cnfNewPwdInpLabel')}/>  
                    </HStack>

                    <HStack width={{base: "100%", lg: "50%"}} spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                      <Box w={{base: "100%", md: "100%"}} mb={{base: '24px', md:'unset'}}>
                          <CheckboxField
                              label={""}
                              isHelperText
                              options={["accept"]} 
                              name="accept" 
                              error={errors.accept && touched.accept}
                              h="24px"
                              w="24px"
                              custom={() => <AcceptLink error={errors.accept && touched.accept}/>}
                              onChange={(e: any) => {
                                setFieldValue("accept", e.target.checked); 
                              }}/>
                          </Box>
                    </HStack>

                    <HStack width={{base: "100%", lg: "50%"}} justifyContent={{base: "center", md: "flex-start"}} spacing={{base: "0px", md: "20px", lg: "32px"}}  wrap={{base: "wrap", md: "nowrap"}} pt={{base: "32px"}}>
                      {isLargerThan760 && <Button 
                          type="submit"
                          rightIcon={<ArrowForwardIcon />} 
                          fontSize="16px"
                          textTransform="none"
                          fontFamily="Mulish"
                          color="#304358"
                          fontWeight="700"
                          borderRadius="10pt"
                          backgroundColor="#FFDB00" 
                          variant="solid">
                                {t('createNewPassword.setPwdBtn')}
                          </Button>}
                    </HStack>

                  </VStack>  

                  {!isLargerThan760 && <Button 
                      type="submit"
                      rightIcon={<ArrowForwardIcon />} 
                      fontSize="16px"
                      textTransform="none"
                      fontFamily="Mulish"
                      color="#304358"
                      fontWeight="700"
                      width="100%"
                      position="absolute"
                      bottom="0"
                      borderRadius="none"
                      backgroundColor="#FFDB00" 
                      variant="solid">
                          {t('createNewPassword.setPwdBtn')}
                      </Button>}


                  </form>
                  )}
                  </Formik> 
              </Flex>
              </Box> 
           
        </LayoutComponent>
    )
}

export default CreatePasswordNew