export const text_fr: string = `
Tap Medical 
Contrat de licence utilisateur final (CLUF)
Note à l’attention des utilisateurs: ceci est un contrat de licence 
utilisateur final (CLUF) définissant les modalités et conditions qui vous 
autorisent à utiliser le produit logiciel sous licence (tel que précisé ci-
dessous). Veuillez lire attentivement les modalités et les conditions de 
ce Contrat avant d’installer et d’utiliser ce logiciel sous licence. En 
cliquant sur la touche «J’accepte» (s’il y a lieu) et/ou en installant et/ou
en utilisant le produit logiciel sous licence, vous acceptez et convenez 
d’être lié aux modalités et aux conditions de ce Contrat. Si vous 
n’acceptez pas d’être lié aux modalités et aux conditions de ce 
Contrat, n’installez pas ou n’utilisez pas le produit logiciel sous licence.
Si vous avez payé pour une licence, vous avez droit à un 
remboursement pour les frais de cette licence.
1. OCTROI DE LICENCE. 9338-7900 Québec Inc., 4455 
Sherbrooke Ouest, 1er étage, Westmount, Québec, H3Z 1E7. Par 
la présente («Tap» ou «nous») accordons à l’utilisateur, une 
entreprise ou une entité légale (ou encore «Vous») une licence 
limitée et libre de redevance, non-exclusive, non-transférable, 
non sous-licenciable et utilisable conformément aux modalités et
aux conditions définies par ce Contrat, et cela pour la période de 
temps durant laquelle Vous avez droit à une licence («Période de
Licence»): (i) le produit logiciel d’application Tap Medical (le cas 
échéant) sous la forme d’un code, comprenant n’importe quelle 
et toutes les mises à jour et/ou mises à niveau de celui-ci que 
Tap peut apporter à son entière et seule discrétion; et (ii) toute 
documentation («Documentation») qui s’y trouve lié et ci-après 
désignée collectivement sous le terme «Produit logiciel sous 
licence». Vous pouvez utiliser le Produit Logiciel Sous Licence 
pour accéder aux serveurs de Tap («Services») dans le but de 
bilan de santé et planification, de collaboration, d’éducation de 
diagnose de santé anticipatoire et d’approvisionnement de 
documentation que Tap rend disponible à travers ses 
plateformes informatiques, et cela par le biais de: (a) son site 
Web (y compris des sous-domaines, des widgets et des versions 
mobiles); (b) ses Applications, (c) toute information (tels que 
fichiers de données, texte, logiciel, fichiers audio ou autres,  
photographies, vidéos ou tout autre type d’images) rendue 
disponible par Tap et à laquelle Vous pourriez avoir accès sur le 
site Web ou compte de Tap, ou par le biais d’Applications Tap ou 
autres Services («Contenu»); (d) votre compte ou n’importe quel 
autre programme, média ou logiciel conçus pour être activé sur 
un appareil électronique déjà existant ou qui sera développé 
dans le futur, autorisés par Tap de temps à autre et à sa seule et
unique discrétion, et cela à aucune autre fin.
2. RESTRICTIONS D’UTILISATION. En outre, Vous acceptez 
que Vous ne pouvez, ou ne pouvez permettre à un tiers, de: (i) 
sous-licencier, attribuer, transférer, distribuer, promettre, louer 
ou partager Vos droits sous le coup de ce Contrat; (ii) modifier ou
adapter le Produit Logiciel Sous License (à moins et dans la 
mesure que cela soit spécifiquement permis par une loi 
obligatoirement applicable); (iii) démonter, décompiler, effectuer
une ingénierie inverse ou toute autre tentative pour découvrir le 
code source du Produit Logiciel Sous Licence (dans la mesure où 
cela est permis par une loi obligatoirement applicable; (iv) 
utiliser le Produit Logiciel Sous License pour du temps partagé, 
un bureau de service ou n’importe quelle autre utilisation non 
autorisée; (v) avoir plus d’un (1) compte Tap; retirer toute notice 
ou étiquette du propriétaire du Produit Logiciel Sous Licence; ou 
(vi) utiliser le Produit Logiciel Sous Licence à toute fin non 
autorisée par ce Contrat.
3. LES DROITS DU PROPRIÉTAIRE. Vous reconnaissez et 
acceptez que Tap conserve en son propre nom et au nom de ses 
preneurs de licence tous les droits, titres et intérêts liés au 
Produit Logiciel Sous License, y compris mais ne se limitant pas à
n’importe quel et tout droit d’auteur, brevet, secret commercial 
et toute marque déposée, et n’importe quel et tout droit de 
propriété intellectuelle, et Vous ne pouvez obtenir aucun droit de
quelconque nature dans le cadre des droits de propriété 
intellectuelle ou tout autre type de droit en ce qui concerne le 
Produit Logiciel Sous Licence, à l’exception du droit limité de 
licence explicitement exposé dans la Section 1 ci-dessus. 
4. COMMENTAIRES. Par la présente, Vous acceptez et 
reconnaissez que toute idée, suggestion et autre type de 
commentaire que vous pourriez faire en ce concerne le Produit 
Logiciel Sous Licence («Commentaire») pourrait être utilisé par 
Tap et ses preneurs de licence pour améliorer et/ou optimiser la 
fonctionnalité du Produit Logiciel Sous License et/ou de tout 
autre produit appartenant à Tap. En conséquence et par la 
présente Vous accordez à Tap, ainsi qu’à ses preneurs de 
licence, la licence et le droit non-exclusifs, libres de redevance, 
perpétuels, irrévocables et à l’échelle mondiale d’utiliser, 
reproduire, divulguer, sous-licencier (sans restriction), distribuer 
ou modifier tout Commentaire - ou encore de créer des produits 
dérivatifs à partir de tout Commentaire - et tout cela sans 
restriction aucune.
5. SÉCURITÉ ET ACCÈS DES TIERS. Vous acceptez: (i) de 
choisir un mot de passe fort et sécuritaire; (ii) de garder ce mot 
de passe secret et confidentiel; (iii) de ne pas transférer ou faire 
un duplicata d’aucune partie de Votre compte, du contenu de 
Votre compte, du site Web de Tap, du Produit Logiciel Sous 
Licence ou d’aucun autre Service pour une autre personne (par 
exemple des relations ou des groupes) ou sur quelque autre 
plateforme, programme ou système que ce soit (par exemple un 
système informatique nuagique, un disque dur du périphérique 
mobile ou une clé USB). Sauf dans les cas où cela est requis par 
la loi ou exigé pour se conformer au Contrat, Vous convenez de: 
(i) ne permettre à aucun tiers d’utiliser ou accéder à Votre 
compte ou à d’autres bénéfices provenant des Services sans 
l’autorisation préalable et écrite de Tap (que vous devez obtenir 
en utilisant la fonction «Contactez-nous» sur le site Web de Tap);
(ii) ne pas autoriser ou assister toute personne ou entité dans le 
but d’accéder ou chercher à accéder une quelconque portion des
Services par d’autre biais que les navigateurs commerciaux 
(comme Internet Explorer, Mozilla Firefox, Chrome ou Safari) ou 
des applications mobiles autorisées par Tap et, si c’est le cas, qui
Vous ont été fournies; (iii) de ne pas accéder aux Services, au 
contenu de Votre compte, au site Web de Tap, au Produit Logiciel
Sous License en utilisant des moyens automatisés (tels que des 
bots informatiques, des robots, des araignées ou le web 
scraping); et (iv) de ne pas télécharger des virus ou des codes 
malveillants et de ne pas permettre à d’autres de le faire.
6. AUCUNE GARANTIE. Tap n’offre aucune garantie en ce qui a 
trait au Produit Logiciel Sous Licence, lequel Vous est fourni 
gratuitement.
7. LES SERVICES DE SUPPORT. Durant les heures normales 
d’ouverture, Tap Vous offrira des services dans le cadre du 
Produit Logiciel Sous Licence par le biais de courriel ou de tout 
autre support via le centre de support se trouvant sur le site Web
de Tap, soit le www.tapmedical.ca («Services de support»).
8. LES MISES À JOUR AUTOMATIQUES ET LES 
STATISTIQUES D’INSTALLATION. Par la présente, vous 
reconnaissez et acceptez que le Produit Logiciel Sous Licence 
prélèvera automatiquement et dès le moment où sera installé le 
Produit Logiciel Sous Licence certaines informations de votre 
ordinateur, et cela purement à des fins statistiques. En outre, 
Vous reconnaissez et acceptez que le Produit Logiciel Sous 
Licence est configuré pour télécharger automatiquement à partir
des serveurs du site Web de Tap («Téléchargement 
Automatique»): (i) des mises à jour résultant de corrections 
d’erreurs et de l’optimisation des fonctions du Produit Logiciel 
Sous Licence («Mises à jour du Logiciel»); et (ii) des mises à jour 
d’information, ce qui pourrait inclure de l’information spécifique 
à la licence, des  nouvelles et des offres transmises par Tap et 
toute autre information en lien avec le Produit Logiciel Sous 
Licence; («Mises à jour de l’Information»). Vous pouvez 
reconfigurer le Produit Logiciel Sous Licence pour désactiver le 
Téléchargement Automatique en changeant les réglages du 
Produit Logiciel Sous Licence afin que les Mises à jour du Logiciel 
et/ou les Mises à jour de l’Information puissent être téléchargées 
seulement à Votre demande spécifique («Téléchargement du 
Manuel»). En outre, vous reconnaissez et acceptez que le Produit
Logiciel Sous Licence est configuré pour automatiquement 
envoyer de votre ordinateur aux serveurs de Tap de l’information
spécifique à la licence en lien au Produit Logiciel Sous Licence, et
cela à chaque fois que se fait un Téléchargement Automatique 
ou un Téléchargement Manuel de Mises à jour de Logiciel et/ou 
de Mises à jour de l’Information.
Toute information personnelle identifiable (de l’information qui 
peut – soit par elle-même ou en étant ajoutée à d’autres données
se trouvant en notre possession – identifier un utilisateur 
spécifique), y compris toute information sur un patient ou toute 
information médicale Vous concernant est stockée et utilisée par 
nous conformément à notre Politique de Confidentialité. Et tel 
qu’énoncé dans notre Politique de Confidentialité – laquelle est à 
la présente intégrée par référence – Vous pouvez exercer vos 
droits en ce qui concerne cette information. 
9. AVERTISSEMENT. DANS TOUTE LA MESURE DU POSSIBLE 
PERMISE PAR LES LOIS APPLICABLES D’ORDRE PUBLIC, LE 
PRODUIT LOGICIEL SOUS LICENCE (Y COMPRIS LA 
DOCUMENTATION, POUR ÉVITER TOUT DOUTE) ET LES SERVICES 
DE SUPPORT SONT OFFERTS «TEL QUEL» ET TAP ET SES 
PRENEURS DE LICENCE NE DONNENT AUCUNE GARANTIE 
CONCERNANT LE PRODUIT LOGICEL SOUS LICENCE OU LES 
SERVICES MENTIONNÉS OU IMPLICITES, INCLUANT MAIS NE SE 
LIMITANT PAS AUX GARANTIES IMPLICITES DE VALEUR 
MARCHANDE ET CONFORMITÉ POUR UNE UTILISATION 
SPÉCIFIQUE, DE DROIT DE PROPRIÉTÉ ET DE NON-VIOLATION 
DES DROITS DE PROPRIÉTÉ INTELLECTUELLE DE TIERS. SANS 
VOULOIR SE LIMITER AUX GÉNÉRALITÉS PRÉCÉDENTES, TAP ET 
SES PRENEURS DE LICENCE NE GARANTISSENT PAS: (i) QUE 
L’OPÉRATION DU PRODUIT LOGICIEL SOUS LICENCE, OU LES 
SERVICES DE CELUI-CI, SERA EXEMPTE D’ERREUR ET 
ININTERROMPUE; OU (ii) QUE TOUTES LES ERREURS OU 
DÉFECTUOSITÉS DU PRODUIT LOGICIEL SOUS LICENCE SERONT 
CORRIGÉES. SI VOUS ÊTES SUJET À UNE JURIDICTION QUI 
INVALIDENT CES AVERTISSEMENTS, VOUS ACCEPTEZ D’Y ÊTRE 
LIÉ DANS TOUTE LA MESURE DU POSSIBLE PERMISE PAR UNE 
TELLE LOI OU DE TELLES RÉGLEMENTATIONS. EN OUTRE, TAP SE 
RÉSERVE LE DROIT DE CHANGER LES SERVICES DE TEMPS À 
AUTRE, ET CELA À LA SEULE ET UNIQUE DISCRÉTION DE TAP.
MÊME SI LE SITE WEB DE TAP, LE PRODUIT LOGICIEL SOUS 
LICENSE AINSI QUE VOTRE COMPTE ET LE CONTENU DE VOTRE 
COMPTE CONTIENNENT DE L’INFORMATION EN LIEN AVEC VOTRE
ÉTAT DE SANTÉ OU VOTRE HISTORIQUE MÉDICAL, TAP INDIQUE 
EXPRÉSSEMENT QUE TAP NE VOUS (OU À AUCUNE AUTRE 
PERSONNE) FOURNI AUCUN CONSEIL D’ORDRE MÉDICAL, ET 
CONSÉQUEMMENT NI LE SITE WEB DE TAP, LE PRODUIT LOGICIEL
SOUS LICENCE, VOTRE COMPTE OU LES SERVICES ET NI 
QUELQUE CONTENU RESPECTIF QUE CE SOIT DOIT: (i) ÊTRE 
UTILISÉ DANS LE CADRE D’UN AUTODIAGNOSTIC; (ii) 
CONSTITUER UNE INTERPRÉTATION DE L’ÉTAT DE SANTÉ DE 
TOUT PATIENT OU UTILISATEUR; ET (iii) ÊTRE CONSIDÉRÉ COMME
UN PRONOSTIC, UNE OPINION, UN DIAGNOSTIC, UNE 
AFFIRMATION PRÉCISE OU UNE RECOMMANDATION MÉDICALE EN
LIEN AVEC TOUT CAS MÉDICAL QUI DEVRAIT ÊTRE SOUMIS OU 
TOUT TRAITEMENT MÉDICAL QUI DEVRAIT ÊTRE EFFECTUÉ. EN 
CE QUI CONCERNE L’INFORMATION LIÉE AU PATIENT, CELLE-CI 
EST AJOUTÉE INDÉPENDAMMENT PAR VOUS ET, EN RAISON DE 
SA NATURE, N’EST PAS CONTRÔLÉE OU EXAMINÉE PAR TAP; PAR 
LA PRÉSENTE, TAP INDIQUE CONSÉQUEMMENT QUE VOUS ÊTES 
SEUL RESPONSABLE DE TOUTES LES DÉCISIONS PRISES EN 
S’APPUYANT SUR DE TELLES INFORMATIONS OU COMMENTAIRES,
ET AUSSI DE VOS ACTIONS ET VOS OMISSIONS.
10. LIMITATION DE RESPONSABILITÉ. DANS TOUTE LA 
MESURE DU POSSIBLE PERMISE PAR LES LOIS APPLICABLES 
D’ORDRE PUBLIC, EN AUCUN CAS TAP OU SES PRENEURS DE 
LICENCE DEVRONT ÊTRE TENUS RESPONSABLE ENVERS VOUS 
OU TOUT TIERS POUR: (i) TOUT DOMMAGE INDIRECT, SPÉCIAL, 
PUNITIF OU CONSÉQUANT; (ii) TOUT DOMMAGE CAUSÉ À UNE 
PROPRIÉTÉ, TOUTE PERTE OU CORRUPTION DE DONNÉES, PERTE 
D’UTILISATION, PERTE DE PRODUCTIVITÉ, PERTE DE PROFITS, 
D’ÉPARGNES OU DE REVENUS DE TOUTE SORTE (QUE CE SOIT 
DIRECT, INDIRECT OU CONSÉQUANTS), PEU IMPORTE LA 
THÉORIE DE LA RESPONSABILITÉ ET CELA MÊME SI TAP A ÉTÉ 
AVISÉ DE LA POSSIBILITÉ DE TELS DOMMAGES ET LA 
RESPONSABILITÉ DE TAP NE DOIT PAS DE FAÇON GLOBALE 
EXCÉDER LES FRAIS DE LICENCE PAYÉS (SI C’EST LE CAS) POUR 
LE PRODUIT LOGICIEL SOUS LICENCE. SI VOUS ÊTES SUJET À UNE
JURIDICTION QUI INVALIDE LA LIMITATION DE RESPONSABILITÉ 
MENTIONNÉE, OU ENCORE TOUTE PARTIE OU SECTION DE CELLE-
CI, VOUS ACCEPTEZ D’Y ÊTRE LIÉ DANS TOUTE LA MESURE DU 
POSSIBLE PERMISE PAR UNE TELLE LOI OU DE TELLES 
RÉGLEMENTATIONS. 
VOUS ACCEPTEZ DE DÉFENDRE, D’INDEMNISER ET DE DÉGAGER 
DE TOUTE RESPONSABILITÉ TAP, SES COMPAGNIES AFFILIÉES, 
DIRECTEURS, EMPLOYÉS, ADMINISTRATEURS ET TOUT AUTRE 
REPRÉSENTANT OU PARTENAIRE D’AFFAIRES DANS LE CADRE DE
TOUTE ALLÉGATION, EXIGENCE, PROCÉDURE JUDICIAIRE, ACTION
EN JUSTICE, AMENDE, PERTE, BLESSURE, REQUÊTE AINSI QUE 
TOUT TOUT FRAIS ET DOMMAGE (DIRECT OU INDIRECT) DE 
QUELQUE NATURE QUE CE SOIT, CONNU OU INCONNU, 
SURVENANT DE QUELQUE FAÇON QUE CE SOIT À CAUSE D’UNE 
OMISSION DE VOTRE PART ET/OU DE L’UTILISATION OU DU 
MAUVAIS USAGE DU SITE WEB DE TAP, DU PRODUIT LOGICIEL 
SOUS LICENCE, DE VOTRE COMPTE, DU CONTENU OU DES 
SERVICES.
11. MODALITÉS ET RÉSILIATION. Ce Contrat sera en vigueur à 
partir du moment de Votre acceptation de ce Contrat de Licence 
Utilisateur Final (CLUF); toutefois en aucun cas celle-ci deviendra
en vigueur ultérieurement à l’installation du Produit Logiciel Sous
License et à Votre première utilisation d’un service offert par Tap
et, à moins que le Contrat soit prématurément résilié et annulé 
tel qu’établi dans le présent document, celui-ci sera en vigueur 
jusqu’à ce que le Produit Logiciel Sous License soit désinstallé de
votre appareil («Période de License»). Tap est autorisé, et cela à 
sa seule et unique discrétion, de résilier prématurément ce 
Contrat avec pour effet immédiat de suspendre 
l’approvisionnement de Mises à jour de Logiciel et/ou de Mises à 
jour de l’Information, et cela si possible lorsqu’un avis Vous 
serait fait parvenir, si Vous (i) êtes en violation des modalités et 
conditions de ce Contrat; ou si (ii) ne respectez pas le Code de 
Conduite de temps à autre publié par Tap sur son site Web et 
lequel se trouve intégré par référence dans ce Contrat. Vous 
pouvez annuler ou résilier ce Contrat à n’importe quel moment 
en envoyant une notification à Tap. Lors de l’expiration et la 
résiliation de ce Contrat, vous devez immédiatement: (i) cesser 
toute utilisation du Produit Logiciel Sous License; et (ii) détruire 
et supprimer le Produit Logiciel Sous License (y compris toute 
copie de sauvegarde du Produit).
12. VIE PRIVÉE ET CODE DE CONDUITE. En vous soumettant 
à ce Contrat avec Tap, Vous reconnaissez et acceptez que la 
Politique de Confidentialité et le Code de Conduite de Tap, tels 
qu’ils sont mis à jour de temps à autre et sont rendus disponibles
sur le site Web de Tap, soit le www.tapmedical.ca, sont 
applicables à Votre personne.
13. LOGICIEL TIERS. Le Produit Logiciel Sous License peut 
contenir certains logiciels gratuits tiers ou logiciels à source 
ouverte (ou libres), y compris mais ne se limitant pas à ces 
logiciels gratuits ou libres étant de temps à autre listés sur le lien
www.Tap.ca/thirdpartysoftware/ («Logiciels Tiers»). Dans la 
mesure où les modalités et les conditions s’appliquant à certains 
Logiciels Tiers Vous donnent les droits additionnels (à ceux se 
trouvant dans ce Contrat) d’utiliser, reproduire ou modifier de 
tels Logiciels Tiers, de tels droits doivent prévaloir sur les 
modalités et les conditions de ce Contrat en ce qui concerne la 
portion Logiciels Tiers faisant partie du Produit Logiciel Sous 
License.
14. LA PROTECTION DU CONSOMMATEUR. Dans la mesure 
où toute législation obligatoire et applicable visant à protéger le 
consommateur l’exige, certaines modalités et conditions de ce 
Contrat pourraient être ajustées afin de respecter les exigences 
de la loi applicable d’ordre public; toutefois, toutes les autres 
modalités et conditions de ce Contrat ne doivent pas s’en 
trouvées affectées.
15. LE CONTRÔLE D’EXPORTATION. Par la présente, Vous 
reconnaissez que le Produit Logiciel Sous License pourrait être 
sujet aux lois et règlementations sur le contrôle des importation 
et des exportations, incluant mais ne se limitant pas à la « Export
Administration Regulations (EAR) » aux États-Unis et, par la 
présente, Vous convenez de ne pas importer ou exporter, ou de 
ne permettre aucune utilisation du Produit Logiciel Sous Licence 
qui serait en violation des lois et des règlements sur le contrôle 
de l’importation et/ou de l’exportation.
16. DIVERS. Ce Contrat constitue le contrat dans son entier en 
ce qui concerne l’objet de ce Contrat, ce qui annule toute 
proposition, toute entente ou tout accord préalable entre les 
parties aux présentes relatives à l’objet et annule n’importe quel 
droit préalable d’utilisation du Produit Logiciel Sous License et 
des Services que Vous auriez pu obtenir. Vous reconnaissez 
explicitement et acceptez qu’aucune des modalités et des 
conditions de ce Contrat ne puissent devenir nulle et invalide. 
Aucune renonciation ou modification concernant ce Contrat ne 
peut être valide à moins d’être faite par écrit et dûment 
exécutée à cette fin par les deux parties. Si une quelconque 
clause est retenue et jugée contraire à la loi par une cour à la 
juridiction compétente, toutes les autres clauses de ce Contrat 
demeureront pleinement effectives et applicables.
17. LA VERSION ÉLECTRONQIUE DE L’ENTENTE. Vous et Tap
convenez que la valeur juridique et la validité de ce Contrat de 
License Utilisateur Final (CLUF) ne se trouve ni accrue ni 
diminuée en raison du support ou du type de technologie choisie.
Par conséquence, la version électronique sera jugée fidèle et 
fiable dans la mesure où son intégrité peut en être assurée, et 
celle-ci aura la même valeur juridique et validité qu’une copie 
papier et remplira les mêmes fonctions que celle-ci.
18. LA LOI APPLICABLE. Ce Contrat est régi par les lois de la 
Province de Québec, incluant lois canadiennes qui y sont 
applicables, mais exclue les principes de conflit des lois. Ce 
Contrat ne doit pas être régi par la Convention des Nations-Unies
sur les contrats de vente internationale de marchandises (CVIM), 
dont l’application est par la présente expressément exclue. 
Toute dispute résultant de ce Contrat doit être l’objet de la seule 
et exclusive juridiction des cours de la Province de Québec et du 
district de Montréal; toutefois, Tap peut former des recours ou 
intenter des poursuites afin d’obtenir des mesures injonctives 
devant n’importe quel tribunal compétent. Néanmoins, si Vous 
êtes un consommateur, cette Section 17 ne doit affecter aucun 
droit obligatoire qui Vous permettrait de former un recours dans 
Votre pays de résidence et sous le coup des lois de ce même 
pays.
19. POUR NOUS CONTACTER. Si vous avez des questions au 
sujet du Produit Logiciel Sous License ou du Contrat de License 
Utilisateur Final (CLUF), n’hésitez pas à visiter le site Web de 
Tap, soit le www.tapmedical.ca, pour obtenir les coordonnées 
`; 