import { Box, Button, Flex, position, Text } from "@chakra-ui/react";
import React from "react";

type BannerReloadProps = {
    isAbsolute: boolean; 
}

function BannerReloadPage({isAbsolute}: BannerReloadProps) {
  const handlerReload = () => {
    window.location.href = window.location.href;
  };

  return (
    <div
      style={{
        position: isAbsolute ? "absolute" : "relative",
        zIndex: 10,
        width: "100%",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Flex
        style={{
          backgroundColor: "#d13131",
          width: "100%",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        top={{ base: "76px", lg: "86px" }}
        py={{ base: "10px", lg: "18px", "2xl": "18px" }}
        px={{ base: "7px", md: "15x", lg: "18px", "2xl": "18px" }}
      >
        <Box ml={{ base: "10px", lg: "22px" }}>
          <b>
            <Text
              style={{ color: "white" }}
              fontSize={{ base: "11px", md: "14px", lg: "17px" }}
            >
              A new version of the application is available. Click on the reload
              button to reload the new application
            </Text>
          </b>
        </Box>
        <Box>
          <Button
            backgroundColor="white"
            padding="10px"
            textTransform="none"
            borderRadius="16px"
            cursor="pointer"
            onClick={handlerReload}
          >
            Reload
          </Button>
        </Box>
      </Flex>
    </div>
  );
}

export default BannerReloadPage;
