import Axios from 'axios';
import Uris from '../../api/uris';
import { createStandaloneToast } from "@chakra-ui/react"
import { MessageActions } from './message.action';
import { MessageDataType } from '../../dto/message-data.dto';
import { SessionActions} from '../session';
import { handleError } from '../utils';
import axiosRateLimit, { RateLimitedAxiosInstance } from 'axios-rate-limit';
import { MessageShortType } from '../../dto';

const toast = createStandaloneToast();

export class MessageService {
    
    constructor ( 
        private readonly dispatch: any 
    ){}

    async addAIMessage ( contents: string, id: number = -1 ) {
        this.dispatch({type: MessageActions.ADD_AI_MESSAGE, payload: {contents, id}});
    }

    async addDoctorMessage ( message: MessageDataType ) {
        this.dispatch({type: MessageActions.DOCTOR_MESSAGE, payload: message});
    }

    private urltoFile(url: string, filename: string, mimeType: string){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }

    async sentCount( count: number ) {
        this.dispatch({ type: MessageActions.SENT_COUNT, payload: count });
    }

    async getRefDoc (docId: string, token: string) {
        try {
            const res: any = await Axios.get(Uris.document(docId), { headers: { Authorization: `Bearer ${token}` } })
            return res.data?.data?.link;
        } catch ( e: any ) {
            const description = "Error receiving documents"; 
            handleError(e, description, toast, this.dispatch);
        }
    }

    async sendMessage ( contents: string, token: string, author_id: number, page: number = 1, files?: Array<any>) {
        try {
            const message: MessageDataType = { contents, author: { id: author_id }, created_at: new Date().toISOString() };
            const bodyFormData = new FormData();
            bodyFormData.append('message', contents);
            if (files && files?.length > 0 ) {
                let documentsList: Array<any> =[]; 
                for (const file of files) {
                    bodyFormData.append('document[]', await this.urltoFile( file.data_url, `${file.name}`, file.type || 'image/jpeg' )); 
                    documentsList.push({file_name: file.name, link: file.data_url}); 
                }
                //message.documents = [ documentsList ];                
            }
            let result = await Axios.post(Uris.message(), bodyFormData, { headers: { Authorization: `Bearer ${token}` } });
            message.documents = result.data?.data?.documents; 

            this.dispatch({ type: MessageActions.SENT, payload: message });
            this.dispatch({ type: MessageActions.INCREMENT_SENT_COUNT });
        }
        catch ( e: any ) {
            const description = "Message sending error";
            handleError(e, description, toast, this.dispatch);
        }
    }

    async addMessageToState(message: any) {
        this.dispatch({ type: MessageActions.SENT, payload: message });
    }

    async removeMessageFromState() {
        this.dispatch({ type: MessageActions.REMOVE_MESSAGE }); 
    }

    async setConnectedOn ( message: MessageDataType ) {
        this.dispatch({ type: MessageActions.CONNECTED_TO_DOC, payload: message });
    }

    async markMessage ( message: MessageDataType, token: string ) {
        try {
            await Axios.patch(Uris.markMessage( message.id || 0 ), {}, { headers: { Authorization: `Bearer ${token}` } });
        } catch ( e: any ) {
            if ( e.response?.status === 401 ) 
                this.dispatch({ type: SessionActions.LOGOUT });
            console.error(e);
        }
    }

    async getMessages( token: string, page: number = 1 ) {
        try {
            if (!token)
                throw null;
            const http: RateLimitedAxiosInstance = axiosRateLimit(Axios.create(), { maxRPS: 1 });

            const rs = await http.get(Uris.messageUpdated(), 
            { 
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    page
                }
        });
            this.dispatch({ type: MessageActions.GET_MESSAGES, payload: rs.data });
        }
        catch ( e: any ) {
            if ( e !== null ) {
                const description = "Error receiving messages"; 
                handleError(e, description, toast, this.dispatch);
            }
        }
    }

    async answerQuestionnaire (author_id: number, episode_id: number, question_id: number, answer_id: number, text: string, file: any,  token: string) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append("episode_id", `${episode_id}`); 
            bodyFormData.append("question_id", `${question_id}`); 
            bodyFormData.append("answer_id", `${answer_id}`); 
            bodyFormData.append("text", text); 
            if(file) {
                const document = await this.urltoFile( file[0].data_url, `${file[0].name}`, file[0].type || 'image/jpeg');
                bodyFormData.append("document", document);
            }
            let result = await Axios.post(Uris.answerQuestionnaire(), bodyFormData, { headers: { Authorization: `Bearer ${token}` } });
            if(result?.data?.data?.questionnaire_completed
                ) {
                    this.dispatch({ type: MessageActions.QUESTIONNIRE_COMPLETED, payload: true }); 
            } else if(result?.data?.data) {
                const currentText = result?.data?.data?.text_en ? result?.data?.data?.text_en: result?.data?.data?.text_fr;
                const message: MessageDataType = {
                    author: {
                    display_name: "Virtual Assistant", 
                    first_name: "Virtual", 
                    id: null, 
                    }, 
                    contents: currentText,
                    created_at: new Date().toISOString(),
                    sender: "system",
                    active_question: result?.data?.data
                }; 
                setTimeout(() => this.dispatch({ type: MessageActions.SENT, payload: message }), 200);
                setTimeout(() => this.dispatch({ type: MessageActions.INCREMENT_SENT_COUNT }), 200);
            }
            
            if(result.data?.data?.type === "text") {
                this.dispatch({ type: MessageActions.UPDATE_QUESTIONNAIRE, payload: false}); 
            }
            if(result.data?.data?.outcome === "success") {
                this.dispatch({ type: MessageActions.QUESTIONNIRE_COMPLETED, payload: true }); 
                this.dispatch({ type: MessageActions.UPDATE_QUESTIONNAIRE, payload: false }); 
            }
            return result; 
        } catch (error: any) {
            const description = "Something went wrong"; 
            handleError(error, description, toast, this.dispatch);
            return error; 
        }
    }

    async answerUndo(token: string, episode_id: number) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append("episode_id", `${episode_id}`);  
            
            let result = await Axios.post(Uris.answerUndo(), bodyFormData, { headers: { Authorization: `Bearer ${token}` } });
            if(result?.data?.data) {
                this.dispatch({type: MessageActions.REMOVE_MESSAGE, payload: result.data?.data}); 
            }
            return result; 
        } catch (error: any) {
            const description = "Something went wrong"; 
            handleError(error, description, toast, this.dispatch);
        }
    } 

    async loadMoreMessages( token: string, page: number = 1 ) {
        try {
            const http = axiosRateLimit(Axios.create(), { maxRPS: 1, maxRequests: 1, perMilliseconds: 1000 });
            const rs = await http.get(`${Uris.message()}?page=${page}`, { headers: { Authorization: `Bearer ${token}` } });
            this.dispatch({ type: MessageActions.LOAD_MORE_MSG, payload: rs.data });
        }
        catch ( e: any ) {
            const description = "Load history error"; 
            handleError(e, description, toast, this.dispatch);
        }
    }

    async clearMessages () {
        this.dispatch({ type: MessageActions.CLEAR_ALL });
    }

    updateIsQuestionnaire (value: boolean) {
        this.dispatch({ type: MessageActions.UPDATE_QUESTIONNAIRE, payload: value }); 
    }
}
