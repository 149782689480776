import { Input } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function AhInput(props: any) {
    // this will return field exactly like <Field>{({ field }) => ... }</Field>
    const [field, meta] = useField(props);
    const { t } = useTranslation();
    return (
        <>
            <Input
                {...field}
                {...props}
                errorBorderColor="#E27878"
                variant='outline'
                borderRadius='10pt'
                borderColor='#A3B2B1'
                isInvalid={ (meta.touched === true && meta.error) || props.error === true }
                />
            { meta.touched === true && meta.error !== undefined &&
                <small style={{ marginLeft: '6%', color: '#E27878', display: 'block', width: '90%' }}>
                    {t(meta.error, { field: props.name })}
                </small>
            }
        </>
    );
}