import { Button } from '@chakra-ui/button';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Center, Divider, Flex, Text } from '@chakra-ui/layout'
import { useBreakpointValue, useMediaQuery } from '@chakra-ui/media-query';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom';
import { useRegisterFlowContext } from '.';
import { internalRoutes } from '../../app/routes';
import { useEnterClickListener } from '../../utils/hooks';

function RegisterLaoyut(props: any) {
    const {pathname} = useLocation();
    const history = useHistory(); 
    const {t} = useTranslation();
    const [isLargerThan760] = useMediaQuery("(min-width: 760px)")
    const [isLessThan370] = useMediaQuery("(max-width: 370px)")

    const styleResize = {
        height_bottom: useBreakpointValue({base: "auto", md: `calc(100% - 105px - 90px)`}),
    }

    const {isHideContent} = useRegisterFlowContext();

    const handlerClick = () => {
        props.handlerSubmit(); 
    }

    const handlerBack = () => {
      if(props.goBack) {
        props.goBack(); 
        return
      }
      if(props.handlerLeftBtnClick) {
        props.handlerLeftBtnClick(); 
        return
      }
      history.goBack()
    }

    const setHeight = () => {
        if(window.innerWidth <= 992 && props.step !== internalRoutes.EMPLOYER ) {
            return 'calc(100vh - 144px);'
        }
        return '100%'
    }

    const resizeBox = () => {
        if(props.step === 4 || props.step === 5 || props.step === 6 || props.step === 7) {
          return "none"; 
        } else if(window.innerWidth <= 992 && props.step !== internalRoutes.EMPLOYER) {
          return 'calc(100vh - 144px);'
        } else {
          return '100%'
        }
    }

    useEnterClickListener(handlerClick);

    const title_btn_right = props.title_btn_right ? props.title_btn_right : t(`new_register.next_step`);  
    const title_btn_left = props.title_btn_left ? props.title_btn_left : t(`new_register.previous_step`); 
    return (
       <Box height='100%' width="100%" display="flex" flexDirection="column" justifyContent="space-between" minH={!isHideContent ? setHeight() : resizeBox()}>
         <Flex height='100%'  px={{base: "16px", md: "40px", "2xl": '80px'}} pb={{base: "0px", md: "60px", lg:'unset'}} direction="column" justify={ props.step === internalRoutes.EMPLOYER ? "center" : "flex-start"}>
             {props.children}
         </Flex>
       {/*  {isLargerThan760 && props?.step < 6 && <Divider/>} */}
          {(props?.step < 9 || props.step === internalRoutes.EMPLOYER || props.step === internalRoutes.ACCEPT) &&  <Flex align="center" h={{base: "auto", md: "105px"}} px={{base: "0px", md: "20px", lg: '40px', "2xl": "80px"}} justify={{base: 'center', md:"space-between"}} borderTop={isLargerThan760 ? "1px solid #D3D9E0" : 'unset'} direction={{base: 'column', md: 'row'}}>
           {/*   <Text color="#8194AA" textAlign="center" fontSize="16px" width={{base: '280px', md: 'unset'}} mb={{base: '24px', md: 'unset'}}>
            
                 <Text as="span" color="#40B89C">

                 </Text>
            </Text> */}

            <Flex width={{base: '100%', md: '100%'}} justify="flex-end">
                {(props?.step > 1 || props.step === internalRoutes.ACCEPT ) && <Button
                 onClick={handlerBack}
                 width={{base: '50%', md: 'unset'}}
                 fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px"}}
                 color="#304358"
                 fontWeight="700"
                 fontFamily="Mulish"
                 border="1px"
                 borderColor="#FFDB00"
                 backgroundColor="#FFF19A"
                 borderRadius={{base: "0", md: "10pt"}}
                 textTransform="none"
                 variant="solid">
                   {title_btn_left}
                </Button>}
                <Button
                 onClick={handlerClick}
                 rightIcon={<ArrowForwardIcon />} 
                 fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px"}}
                 ms={{base: "0px", md:"24px"}}
                 width={{base: props?.step === internalRoutes.EMPLOYER ? '100%' : '50%', md: 'unset'}}
                 textTransform="none"
                 fontFamily="Mulish"
                 isLoading={props.isLoad}
                 color="#304358"
                 fontWeight="700"
                 borderRadius={{base: "0", md: "10pt"}}
                 backgroundColor="#FFDB00"
                 variant="solid">
                  {props?.step !== 8 ? title_btn_right : t(`new_register.btnCompleteRegistration`)}
                </Button>

            </Flex>
         </Flex>}
       </Box>

    )
}

export default RegisterLaoyut