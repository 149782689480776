import React, { FC } from 'react';
import {
    Container,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HeaderLogo } from '../../components/header.logo';
import { ROUTE_PATH } from '../../app/routes';
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';

export const ResetLinkSentScreen: FC<any> = () => {

    const { t } = useTranslation();

    return (
        <Container
            mt={'5%'}
            mb="auto"
            width="100%"
            height="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="scroll">
            <HeaderLogo backLink={ROUTE_PATH.HOME} showLanguage={false} />
            <Text fontFamily={'Playfair Display'} fontWeight="bold" fontSize="2xl" mt="80px" ml={5}>
                {t('resetPwdLinkSent.pageTitle')}
            </Text>
            <Text fontFamily={'Mulish'} ml={5} fontSize="md" mt={'10px'}>
                {t('resetPwdLinkSent.text1')}
            </Text>
            <Text fontFamily={'Mulish'} ml={5} fontSize="md" mt={'10px'}>
                {t('resetPwdLinkSent.text2')}
            </Text>
        </Container>
    );
}
