import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Text,
    Button,
    Link,
    Spinner,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link as RLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { AhInput, AhPwdInput } from '../../components/input';
import { initialValue } from './initial-value.form';
import { DisplayingErrorMessagesSchema } from './validation.form';
import { ROUTE_PATH } from '../../app/routes';
import { HeaderLogo } from '../../components/header.logo';
import { VCard } from '../../components/vcard';
import { useEffectOnce } from 'react-use';
import LogRocket from 'logrocket';

export const LoginScreen: React.FC<any> = () => {

    const [ language, setLanguage ] = useState<'en' | 'fr'>('fr');

    const { t, i18n } = useTranslation();

    const { state, sessionService }: AppContextInterface = useContext(AppContext);

    const [ loggingIn, setLoggingIn ] = useState(false);

    const changeLanguage = ( lang: 'en' | 'fr' ) => {
        if ( language !== 'en' && lang === 'en' ) {
            setLanguage('en');
            i18n.changeLanguage('en')
        }
        else if ( language !== 'fr' && lang === 'fr' ) {
            setLanguage('fr');
            i18n.changeLanguage('fr');
        }
    }


    const onLogin = async ( values: any ) => {
        setLoggingIn(true);
        const resp: any | null = await sessionService.login(values.email, values.password, language);
        setLoggingIn(false);
        if ( resp.episode?.patient?.language ) { 
            setLanguage(resp.episode?.patient?.language);
            i18n.changeLanguage(resp.episode?.patient?.language);
        }
    }

    useEffect(() => {
        if(i18n.language && i18n.language !== language) {
            let lang = i18n?.language; 
            if(lang === "en") setLanguage("en");
            if(lang === "fr") setLanguage("fr"); 
        }
    })


    return (
        <Container
            mt={2 * window.devicePixelRatio}
            mb="auto"
            position='relative'
            width="100vw"
            height="100vh"
            maxWidth='auto'
            backgroundColor="#FFFFFF"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            overflow="scroll">
            <HeaderLogo backLink={ROUTE_PATH.HOME} showLanguage={true} language={language} changeLanguage={changeLanguage} />
            <Text fontFamily={'Playfair Display'} fontWeight="bold" fontSize="2xl" mt="10%" ml={5}>
                {t('loginScreen.greetingTitle')}
            </Text>
            <Text fontFamily={'Playfair Display'} ml={5} fontSize="lg" mt={1 * window.devicePixelRatio}>
                {t('loginScreen.pageTitle')}
            </Text>
            { state.app.successMessage !== null && 
                <Container mt={5} mb={5} ml={5} width={'90%'} background={'#E3FFF8'} borderRadius={'13px'}>
                    <Text mt={'8px'} mb={'6px'} color={'#60CBB2'} textAlign={'center'} fontFamily={'Mulish'} fontSize={'18px'}>
                        { t(state.app.successMessage || '') }
                    </Text>
                </Container> }
            { state.app.isErrored === true && 
                <Container mt={5} mb={5} ml={5} width={'90%'} background={'#FFE2E8'} borderRadius={'13px'}>
                    <Text mt={'8px'} mb={'6px'} color={'#F44970'} textAlign={'center'} fontFamily={'Mulish'} fontSize={'18px'}>
                        { t(state.app.errorMessage || '') }
                    </Text>
                </Container> }
            <Formik
                initialValues={initialValue}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={onLogin}>
                { ({ errors, touched }) => (
                    <Form style={{ width: '100%', marginTop: `${state.app.multiplier * 2 * window.devicePixelRatio}px` }}>
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} ml={5} fontSize="sm" mt={2} fontFamily={'Mulish'}>
                            {t('loginScreen.labelEmailInputText')}
                        </Text>
                        <AhInput error={state.app.isErrored} fontFamily={'Mulish'} name="email" ml={5} width="90%" type="email" placeholder={t('loginScreen.labelEmailInputText')} pt={2} pb={2} />
                        <Text color={ state.app.isErrored === true ? '#E27878' : 'primary'} fontSize="sm" ml={5} mt={2}  fontFamily={'Mulish'}>
                            {t('loginScreen.labelPasswordInputText')}
                        </Text>
                        <AhPwdInput error={state.app.isErrored} fontFamily={'Mulish'} name="password" t={t} placeholder={t('loginScreen.labelPasswordInputText')} ml={5} />
                        <Button
                            disabled={loggingIn}
                            width="90%"
                            mt={5}
                            rightIcon={<ArrowForwardIcon />}
                            ml={5}
                            type="submit">
                            {
                                loggingIn === false ?
                                t('loginScreen.loginBtnText'):
                                <Spinner />
                            }
                        </Button>
                    </Form>
                ) }
            </Formik>
            <Link
                alignSelf="center"
                mt={`${state.app.multiplier * 2 * window.devicePixelRatio}rem`}
                textDecoration="underline"
                backgroundColor="#FFDB00"
                fontFamily={'Mulish'}
                pl={2}
                pr={2}>
                <RLink to={ROUTE_PATH.FORGOT_PASSWORD}>
                {t('loginScreen.helpLinkText')}
                </RLink>
            </Link>
            <VCard />
        </Container> 
    );
}
