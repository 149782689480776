import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, VStack } from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import PinFields from "../../components/pinFields";
import RadioFields from "../../components/radioFields";
import TextField from "../../components/textField";

interface ObjectLiteral {
  [key: string]: any;
}

type RegisterChildProps = {
  handleChange: Function;
  errors: ObjectLiteral;
  touched: ObjectLiteral;
  values: ObjectLiteral;
  options: Array<string>;
  radioValue: string;
  setFieldValue: Function;
  saveData: Function; 
};

const RegisterChild = ({
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
  options,
  radioValue,
  saveData,
}: RegisterChildProps) => {
  const { t } = useTranslation();


  return (
    <VStack spacing={{ base: "unset", md: "16px" }} width="100%">
      <HStack
        width="100%"
        px={{base: "16px", md: "unset"}}
        spacing={{ md: "20px", lg: "34px" }}
        wrap={{ base: "wrap", md: "nowrap" }}
      >
        <TextField
          mb={{ base: "24px", md: "unset" }}
          placeholder={t(`new_register.label_child_last_name`)}
          name="last_name"
          data-private
          onChange={handleChange}
          error={errors.last_name && touched.last_name}
          value={values.last_name}
          label={t(`new_register.label_child_last_name`)}
        />
        <TextField
          mb={{ base: "24px", md: "unset" }}
          placeholder={t(`new_register.label_child_first_name`)}
          name="first_name"
          error={errors.first_name && touched.first_name}
          onChange={handleChange}
          value={values.first_name}
          label={t(`new_register.label_child_first_name`)}
        />
      </HStack>

      <HStack
        px={{base: "16px", md: "unset"}}
        width="100%"
        spacing={{ md: "20px", lg: "34px" }}
        wrap={{ base: "wrap", md: "nowrap" }}
      >
        <Box w={{ base: "100%", md: "50%" }} mb={{ base: "24px", md: "unset" }}>
          <PinFields
            customChange={(name: string, value: any) =>
              setFieldValue(`birth_${name}`, value)
             }
            value_day={values.birth_day}
            value_month={values.birth_month}
            value_year={values.birth_year}
            error_day={errors.birth_day && touched.birth_day}
            error_month={errors.birth_month && touched.birth_month}
            error_year={errors.birth_year && touched.birth_year}
            label={t(`new_register.date_of_birth`)}
          />
        </Box>

        <Flex
          w={{ base: "100%", md: "50%" }}
          justify="space-between"
          wrap={{ base: "wrap", md: "nowrap" }}
        >
          <Box w={{ base: "100%", md: "38%", lg: "46%" }}>
            <TextField
              data-private
              mb={{ base: "24px", md: "unset" }}
              label={t(`new_register.medicareNumber`)}
              name="hcn"
              error={errors.hcn && touched.hcn}
              onChange={handleChange}
              value={values.medicare_number}
              placeholder={t(`new_register.medicareNumber`)}
            />
          </Box>
          <Box
            mb={{ base: "24px", md: "unset" }}
            w={{ base: "100%", md: "60%", lg: "50%" }}
            display="flex"
            justifyContent="right"
          >
            <PinFields
              error_day={errors.day && touched.day }
              error_month={errors.month && touched.month }
              error_year={errors.year && touched.year }
              value_day={values.day}
              value_month={values.month}
              value_year={values.year}
              customChange={(name: string, value: any) => {
                setFieldValue(name, value); 
              }}
              label={t(`new_register.medicareExpiration`)}
            />
          </Box>
        </Flex>
      </HStack>

      <HStack
        width="100%"
        px={{base: "16px", md: "unset"}}
        spacing={{ md: "20px", lg: "34px" }}
        wrap={{ base: "wrap", md: "nowrap" }}
      >
        <Box w={{ base: "100%", md: "50%" }} mb={{ base: "24px", md: "unset" }}>
          <RadioFields
            label={t(`new_register.sex_of_birth`)}
            isHelperText
            error={errors.gender && touched.gender}
            options={options}
            name="gender"
            handler={(value: any) => {
              let genderName = value === options[0] ? "female" : "male";
              setFieldValue("gender", genderName);
            }}
            defaultValue={radioValue}
          />
        </Box>
      </HStack>

      <Flex
        w="100%"
        justify={"flex-start"}
        pt={{ lg: "40px", "2xl": "56px" }}
        pb={{base: "0px", md: "20px"}}
      >
        <Button
          onClick={() => saveData()}
          rightIcon={<ArrowForwardIcon />}
          fontSize="16px"
          textTransform="none"
          fontFamily="Mulish"
          color="#304358"
          fontWeight="700"
          width={{base: "100%", md: "unset"}}
          borderRadius={{base: "0px", md: "10pt"}}
          backgroundColor="#FFDB00"
          variant="solid"
        >
          {t(`new_register.add_to_family`)}
        </Button>
      </Flex>
    </VStack>
  );
};

export default RegisterChild;
