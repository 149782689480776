import React, { FC } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, CloseButton, Text } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

export type SupportDialogProps = {
    cancelRef: any;
    onClose: () => void;
    isOpen: boolean;
    isLogin?: boolean; 
};

export const EmailDisklaimerDialog: FC<SupportDialogProps> = ({
    cancelRef, onClose, isOpen, isLogin = false
}: SupportDialogProps) => {

    const { t } = useTranslation();
    const handleClick = () => {
        window.open(`mailto:${t('supportDialog.helpContact')}`, '_blank');
        setTimeout(() => onClose(), 1000);
    }

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered>
            <AlertDialogOverlay opacity={0.5} style={{ backdropFilter: 'blur(10px)', backgroundColor: 'transparent!important' }} />

            <AlertDialogContent borderRadius={'20px'} boxShadow={'0px 4pt 12pt #30435829'} width={'90%'}>
                <AlertDialogHeader textAlign='center' paddingTop={'30px'} fontSize='18px' color='#304358' fontFamily={'Mulish'}>{!isLogin ? t('supportDialog.disclaimer_title') : t('supportDialog.title')}
                <CloseButton
                    alignSelf='flex-start'
                    position='absolute'
                    right={2}
                    top={2}
                    onClick={onClose}
                />
                </AlertDialogHeader>
    
                <AlertDialogBody>
                    {isLogin && <>
                        <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                        {t('supportDialog.helpLabel')}
                    </Text>
                    <br />
                    <Text fontSize="16px" color='#304358' fontWeight={'bold'}>
                        {t('supportDialog.disclaimer_title')}
                    </Text>
                    </>}
                  
                    <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                        {t('supportDialog.disclaimer_text')}
                    </Text>
                    <br />
                    <Text color='#304358' fontFamily={'Mulish'} fontWeight='bold' >
                        {t('supportDialog.helpTitle')}
                    </Text>
                    <Text fontSize="16px" color='#304358' fontFamily={'Mulish'}>
                        {t('supportDialog.helpContact')}
                    </Text>
                </AlertDialogBody>
                <br />
                <AlertDialogFooter p={0}>
                    <Button borderBottomRadius={'20px'} borderTopRadius={0} color={'#304358'} fontFamily={'Mulish'} height='55px' ref={cancelRef} width='100%' onClick={handleClick} leftIcon={<EmailIcon />} >
                        <a href={`mailto:${t('supportDialog.helpContact')}`}>
                        {t('supportDialog.callBtnText')}
                        </a>
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
