import { Box, Flex, Text } from "@chakra-ui/layout";
import { useContext, useEffect, useRef, useState } from "react";
import TextField from "../../components/textField";
import { Formik } from "formik";
import { HStack, VStack, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/button";
import RadioFields from "../../components/radioFields";
import { useHistory } from "react-router";
import { RegisterContext } from "../../app/navigation";
import { ArrowForwardIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import FamilyRegisterLaoyut from "./FamilyRegisterLayout";
import RegisterChild from "./RegisterChild";
import { AppContextInterface } from "../../app/dto/app-context.dto";
import { AppContext } from "../../app/modules";
import { ROUTE_PATH } from "../../app/routes";
import {
  initialValues,
  ValidationRegisterChild,
  ValidationRegisterChildUpdated,
  ValidationSchema,
} from "./Validations";
import { useUpdateEffect } from "react-use";
import { useRefreshErrorMessage } from "../../utils/hooks";

function InviteChild(props: any) {
  const formRef = useRef<any>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state, appService }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const [inviteChildEmail, setInviteChildEmail] = useState("");
  const [inviteAddress, setInviteAddress] = useState<any>(null);
  const [isLoad, setIsLoad] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [errorText, setErrorText] = useState(""); 
  const [isOld, setIsOld] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isData, setIsData] = useState<boolean>(false);
  const [nameChild, setNameChild] = useState("");
  const optionsRadio = [
    t(`registerForm.fdSection.yes`),
    t(`registerForm.fdSection.no`),
  ];

  const [currentValidation, setCurrentValidation] =
    useState<any>(ValidationSchema);
  const [isUpdatedSchema, setIsUpdatedSchema] = useState(false);
  const base = ROUTE_PATH.FAMILY_REGISTER;
  const slug = useContext(RegisterContext);
  const [isLessMdSize] = useMediaQuery("(max-width: 768px)");
  const [isLessThan370] = useMediaQuery("(max-width: 400px)");

  const options = [
    t(`new_register.gender.female`),
    t(`new_register.gender.male`),
  ];
  const [radioValue, setRadioValue] = useState("");

  const nextStep = () => {};

  const handler = (value: any) => {
    if (value === optionsRadio[0]) {
      setIsOld(true);
      setShowForm(false);
      setCurrentValidation(ValidationSchema);
      formRef?.current?.setFieldValue(`is_old`, false); 
    }
    if (value === optionsRadio[1]) {
      setIsOld(false);
      setShowForm(true);
      setCurrentValidation(ValidationRegisterChild);
      formRef?.current?.setFieldValue(`is_old`, true); 
    }
  };

  const createDependents = async (values: any, cb: Function) => {
    const data = {
      preferred_language: i18n.language,
      first_name: values.first_name,
      last_name: values.last_name,
      hcn: values.hcn,
      gender: values.gender === "female" ? "2" : "1",
      hcn_expiry_date: `${values.year}-${values.month}-${values.day}`,
      date_of_birth: `${values.birth_year}-${values.birth_month}-${values.birth_day}`,
      relation_type: 2,
    };
    let result = await appService.dependentsCreate(state.session?.token, data);
    if (result) {
      setIsData(true);
      setNameChild(values.first_name);
      cb();
    }
  };

  const saveData = async () => {
    formRef?.current?.handleSubmit();
  };

  useRefreshErrorMessage(!!errorText, formRef?.current?.handleSubmit);

  let condition = (inviteAddress && isOld) || isData;

  return (
    <FamilyRegisterLaoyut
      step={3}
      handlerSubmit={nextStep}
      title_btn={t("new_register.skip_step")}
    >
     {!condition && <><Box
        pt={{ base: "80px", md: "100px", lg: "112px" }}
        px={{ base: "16px", md: "unset" }}
        position="relative"
      >
          <Flex
            alignItems="center"
            cursor="pointer"
            position="absolute"
            top="40px"
            onClick={() => history.push(ROUTE_PATH.HOME)}
          >
            <ChevronLeftIcon />
            <Text fontSize="16px" fontWeight="bold">
              {t("new_register.home")}
            </Text>
          </Flex>
        <Text
          fontWeight="700"
          fontSize={{ base: "32px", "2xl": "40px" }}
          color="#304358"
          py="8px"
          lineHeight="48px"
        >
          {t(`new_register.add_child_title`)}
        </Text>
      </Box>
      <Box px={{ base: "16px", md: "unset" }}>
        <Text color="#8194AA" fontSize="16px">
          {t("new_register.add_child_text")}
        </Text>
      </Box></>}
      <Box>
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          enableReinitialize
          validationSchema={currentValidation}
          onSubmit={async (values, { resetForm }) => {
            if (isOld) {
              const data = {
                email: values.email_address,
                first_name: values.first_name,
                last_name: values.last_name,
                relation_type: 2,
              };
              setIsLoad(true); 
              let result = await appService.dependentsInvite(
                state.session?.token,
                data
              );
              setIsLoad(false); 
              if (result) {
                setInviteAddress(data);
                resetForm();
              } else {
                setEmailError(true); 
                setErrorText(t('validation_code.email_error')); 
              }
            } else {
              createDependents(values, resetForm);
            }
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onSubmit={handleSubmit}
            >
             
               {!condition && <Flex w="100%" py="34px" px={{ base: "16px", md: "unset" }}>
                  <RadioFields
                    label={t(`new_register.add_child_question`)}
                    isHelperText
                    options={optionsRadio}
                    name="is_old"
                    handler={(value: any) => handler(value)}
                    error={errors.is_old && touched.is_old}
                    defaultValue={isOld}
                  />
                </Flex>}
          
              {showForm && !isData && (
                <RegisterChild
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  saveData={saveData}
                  options={options}
                  radioValue={radioValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}

              {isOld && !inviteAddress && (
                <VStack
                  spacing={{ base: "unset", md: "20px" }}
                 
                  width="100%"
                >
                  <HStack
                    width="100%"
                    px={{ base: "16px", md: "unset" }}
                    spacing={{ md: "20px", lg: "34px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                  >
                     <TextField
                      mb={{ base: "24px", md: "unset" }}
                      placeholder={t(`new_register.firstName`)}
                      name="first_name"
                      error={errors.first_name && touched.first_name}
                      onChange={handleChange}
                      value={values.first_name}
                      label={t(`new_register.firstName`)}
                    />
                    <TextField
                      mb={{ base: "24px", md: "unset" }}
                      placeholder={t(`new_register.lastName`)}
                      name="last_name"
                      data-private
                      onChange={handleChange}
                      error={errors.last_name && touched.last_name}
                      value={values.last_name}
                      label={t(`new_register.lastName`)}
                    />
                  </HStack>
                  <HStack
                    width={"100%"}
                    px={{ base: "16px", md: "unset" }}
                    justifyContent="flex-start"
                    spacing={{ md: "20px", lg: "34px" }}
                  >
                    <Flex w={{base: "100%", lg: "50%"}} pr={{ md: "10px", lg: "17px" }}>
                      <TextField
                        mb={{ base: !!errorText ? "unset" : "24px", md: "unset" }}
                        placeholder={t("loginScreen.labelEmailInputText")}
                        value={values.email_address}
                        error={errors.email_address ? (errors.email_address && touched.email_address) : emailError}
                        onChange={(e: any) => {
                          setFieldValue("email_address", e.target.value); 
                          if(emailError) {
                            setEmailError(false); 
                            setErrorText(""); 
                          }
                        }}
                        helpertext={errorText}
                        name="email_address"
                        label={t("loginScreen.labelEmailInputText")}
                      />
                    </Flex>

                  </HStack>
                  <HStack
                    width="100%"
                    bottom={{base: "0px", md: "unset"}}
                    justifyContent={{ base: "center", md: "flex-start" }}
                    spacing={{ base: "0px", md: "20px", lg: "32px" }}
                    wrap={{ base: "wrap", md: "nowrap" }}
                    pt={{ base: "32px" }}
                  >
                    <Button
                      type="submit"
                      rightIcon={<ArrowForwardIcon />}
                      fontSize="16px"
                      textTransform="none"
                      fontFamily="Mulish"
                      color="#304358"
                      fontWeight="700"
                      width={{base: "100%", md: "unset"}}
                      borderRadius={{base: "0px", md: "10pt"}}
                      backgroundColor="#FFDB00"
                      variant="solid"
                    >
                      {t("new_register.send_invitation")}
                    </Button>
                  </HStack>
                </VStack>
              )}
            </form>
          )}
        </Formik>
      </Box>
      {((inviteAddress && isOld) || isData) && (
        <Box pt={{ base: "80px", md: "100px", lg: "112px" }} px={{ base: "16px", md: "unset" }}>
          <Text
            color="#304358"
            fontSize="32px"
            fontWeight="800"
            pt={32}
            pb="32px">
            <Text as="span" color="#40B89C">{!isData ? inviteAddress?.first_name : nameChild}</Text>
            {isOld ? t("new_register.success_invite") : t("new_register.invite_was_send_text_minor")}
          </Text>
         {!isData && <>
            <Text
              color="#304358"
              fontSize="20px"
              fontWeight="800"
              pb="8px"
            >
              {t("new_register.invate_was_send")}
              <Text as="span" color="#40B89C">
                {inviteAddress?.email}
              </Text>
            </Text>
            <Text color="#8194AA" fontSize="16px" pb="8px">
              <Text as="span" color="#40B89C">{inviteAddress?.first_name}</Text>
              { t("new_register.invite_was_send_text_2") }
            </Text>
          </>}
          <Flex
            alignItems="center"
            width={{ base: "100%", md: "unset" }}
            pt="32px"
            position={{ base: "absolute", md: "static" }}
            bottom="0"
            left="0"
            right="0"
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
              textTransform="none"
              onClick={() => history.push(ROUTE_PATH.HOME)}
              fontFamily="Mulish"
              color="#304358"
              fontWeight="700"
              width={{ base: "50%", md: "unset" }}
              borderRadius={{ base: "0", md: "10pt" }}
              backgroundColor="#FFDB00"
              variant="solid"
            >
              {t("new_register.done")}
            </Button>
            {!isLessMdSize && (
              <Text color="#8194AA" fontSize="16px" mx="20px">
                {t("new_register.or")}
              </Text>
            )}
            <Button
              onClick={() => {
                setInviteAddress(null);
                setIsData(false);
                setIsOld(true);
                setShowForm(false);
                history.push(`${base}/1${slug}`);
              }}
              fontSize={{ base: isLessThan370 ? "12px" : "14px", md: "16px" }}
              textTransform="none"
              fontFamily="Mulish"
              color="#304358"
              fontWeight="700"
              width={{ base: "50%", md: "unset" }}
              borderRadius={{ base: "0", md: "10pt" }}
              backgroundColor="#FFF19A"
              variant="solid"
            >
              {t("new_register.add_child_btn")}
            </Button>
          </Flex>
        </Box>
      )}
    </FamilyRegisterLaoyut>
  );
}

export default InviteChild;
