import React, { FC, useContext, useState, useEffect } from "react";
import {
  Box,
  Circle,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useEffectOnce, useMedia } from "react-use";
import styled from "@emotion/styled";
import logo_icon from "../assets/icons/logo_icon_register.svg";
import logo_icon_fr from "../assets/icons/logo_icon_register_fr.svg";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/media-query";
import { Image } from "@chakra-ui/image";
import { Divider } from "@chakra-ui/react";
import { AppContextInterface } from "../app/dto/app-context.dto";
import { AppContext } from "../app/modules";
import { internalRoutes, ROUTE_PATH } from "../app/routes";
import { useLanguageObserver } from "../utils/hooks";
import { changeLanguage } from "../utils/helpers";

export type TopRegisterProps = {
  backLink?: string;
  showProgress?: boolean;
  handler?: Function; 
};

export const TopRegister: FC<TopRegisterProps> = ({
  backLink,
  showProgress = true,
  handler=()=>{},
}: TopRegisterProps) => {
  const { t, i18n } = useTranslation();
  const listSteps = new Array(4).fill(1);
  const currentStep = window.location.pathname.split("/").pop();
  const history = useHistory();
  const [language, setLanguage] = useState<"en" | "fr">("fr");

  const query: any = new URLSearchParams(window.location.search);

  const [isLargerThan1800] = useMediaQuery("(min-width: 1800px)");
  const [isLargerThan1700] = useMediaQuery("(min-width: 1700px)");
  const [isLargerThan1560] = useMediaQuery("(min-width: 1560px)");
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const display = useBreakpointValue({ base: "none", lg: "flex" });

  const { state }: AppContextInterface =
    useContext<AppContextInterface>(AppContext);
  const stylesProp = {
    size_text: "16px",
    circle_mg: "16px",
    display_hide: useBreakpointValue({ base: "flex", lg: "none" }),
  };

  const handlerClick = () => {
    if (!state.session) {
      window.location.href = "https://tapmedical.ca/en/allhealth";
    } else {
      history.push(ROUTE_PATH.CHAT_TYPE);
    }
  };

  const setProgress = (idx: number) => {
    if (Number(currentStep) <= 2 && idx === 0) {
      return true;
    }
    if (Number(currentStep) > 2 && Number(currentStep) <= 4 && idx <= 1) {
      return true;
    }
    if (Number(currentStep) > 4 && Number(currentStep) <= 7 && idx <= 2) {
      return true;
    }
    if (Number(currentStep) > 7 && Number(currentStep) <= 9 && idx <= 3) {
      return true;
    }
    return false;
  };

  const isShowText = (idx: number) => {
    if (Number(currentStep) <= 2 && idx === 0) {
      return true;
    }
    if (Number(currentStep) > 2 && Number(currentStep) <= 4 && idx === 1) {
      return true;
    }
    if (Number(currentStep) > 4 && Number(currentStep) <= 7 && idx === 2) {
      return true;
    }
    if (Number(currentStep) > 7 && Number(currentStep) <= 9 && idx === 3) {
      return true;
    }
    return false;
  };

  const handleChangeLanguage = (lang: "en" | "fr") => {
    handler(true); 
    changeLanguage(lang, language, setLanguage, i18n);
  };

  useEffectOnce(() => {
    if (!i18n.language) {
      i18n.changeLanguage("fr");
    }
  });

  useLanguageObserver(i18n, language, setLanguage);

  const getConditionProgress = () => {
    if (
      currentStep !== "9" &&
      currentStep !== internalRoutes.ACCEPT &&
      currentStep !== internalRoutes.EMPLOYER
    ) {
      return true;
    }
    return false;
  };

  const sizeWhenShowEmail = showProgress ? isLargerThan1800 : isLargerThan760; 

  return (
    <Container
      maxW="100%"
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      mt={{ base: getConditionProgress() ? "0px" : "24px", lg: "24px" }}
      mb={{ base: showProgress ? "24px" : "5px", lg: "24px" }}
      alignItems="baseline"
      width="100%"
      // p={0}
      px={{ base: "16px", lg: "80px" }}
      justifyContent={"space-between"}
    >
      <Box
        display="flex"
        w={{ base: "100%", lg: "unset" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        px={{ base: "16px", "2xl": "unset" }}
        py={{ base: getConditionProgress() ? "24px" : "0px", lg: "unset" }}
        pl={{ base: "16px", md: "80px", lg: "unset" }}
        //pb={{base: "16px", lg: "unset"}}
      >
        <Image
          alt="all-health"
          onClick={handlerClick}
          style={{ cursor: "pointer" }}
          height={{ base: "19px", md: "auto" }}
          src={`${t("lang") === "en" ? logo_icon : logo_icon_fr}`}
          // src={logo_icon}
        />
        <Box
          display={stylesProp.display_hide}
          justifyContent={{ base: "space-between", md: "unset" }}
          width={{ base: "100%", md: "unset" }}
          mt={{ base: "10px", md: "unset" }}
        >
          <Text
            onClick={() =>
              window.open(
                `mailto:${t(`new_register.top_bar_email_address`)}`,
                "_blank"
              )
            }
            color="#8194AA"
            fontSize={{ base: "14px", md: stylesProp.size_text }}
          >
            {t(`new_register.top_bar_question`)}
          </Text>
          <Text
            ml="5px"
            cursor={"pointer"}
            onClick={() =>
              handleChangeLanguage(language === "en" ? "fr" : "en")
            }
            as="span"
            color="#8194AA"
          >
            {" "}
            | {t("lang") === "en" ? "FR" : "EN"}
          </Text>
        </Box>
      </Box>
      {getConditionProgress() && showProgress && (
        <Divider display={stylesProp.display_hide} />
      )}

      {getConditionProgress() && showProgress && (
        <Flex
          w={{ base: "100%", lg: "unset" }}
          align="center"
          justify={{ base: "center", md: "flex-start", lg: "center" }}
          mt={{ base: "24px", lg: "unset" }}
        >
          <Breadcrumb
            spacing="8px"
            pl={{ md: "80px", lg: "unset" }}
            separator={<ChevronRightIcon w={6} h={6} color="gray.500" />}
          >
            {listSteps.map((item: any, idx: number) => (
              <BreadcrumbItem key={idx}>
                <Flex align="center" wrap="nowrap">
                  {setProgress(idx) ? (
                    <Circle
                      size="24px"
                      me={{ base: "0px", md: stylesProp.circle_mg }}
                      bg="#40B89C"
                      color="#FFFFFF"
                    >
                      {idx + 1}
                    </Circle>
                  ) : (
                    <Circle
                      size="24px"
                      me={{ base: "0px", md: stylesProp.circle_mg }}
                      bg="#FFFFFF"
                      border="1px"
                      borderColor="#40B89C"
                      color="#40B89C"
                    >
                      {idx + 1}
                    </Circle>
                  )}
                  <BreadcrumbLink
                    href="#"
                    _hover={{ textDecoration: "none" }}
                    color="#304358"
                    display={display}
                    fontSize={!isLargerThan1700 ? "14px" : stylesProp.size_text}
                    fontWeight="500"
                  >
                    {isLargerThan1560 || isShowText(idx)
                      ? t(`new_register.step_title_${idx + 1}`)
                      : ""}
                  </BreadcrumbLink>
                </Flex>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Flex>
      )}
      <Box display={display}>
        {sizeWhenShowEmail ? (
          <Text color="#8194AA" fontSize={stylesProp.size_text}>
            {t(`new_register.top_bar_email`)}
            <Text
              onClick={() =>
                window.open(
                  `mailto:${t(`new_register.top_bar_email_address`)}`,
                  "_blank"
                )
              }
              as="span"
              color="#40B89C"
            >
              {" "}
              {t(`new_register.top_bar_email_address`)}
            </Text>
          </Text>
        ) : (
          <Text
            onClick={() =>
              window.open(
                `mailto:${t(`new_register.top_bar_email_address`)}`,
                "_blank"
              )
            }
            color="#8194AA"
            fontSize={stylesProp.size_text}
          >
            {t(`new_register.top_bar_question`)}
          </Text>
        )}
        <Text
          ml="5px"
          cursor={"pointer"}
          onClick={() => handleChangeLanguage(language === "en" ? "fr" : "en")}
          as="span"
          color="#8194AA"
        >
          {" "}
          | {t("lang") === "en" ? "FR" : "EN"}
        </Text>
      </Box>
    </Container>
  );
};
