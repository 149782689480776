import { IconButton, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IMaskInput } from "react-imask";

type MaskPhoneProps = {
  valueMask: string;
  setValueMask: (value: string) => void;
  handler: () => void;
};

const MaskPhone = ({
  valueMask,
  setValueMask,
  handler,
}: MaskPhoneProps) => {
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false); 

  const sendMessage = () => {
    if(!disable) {
      handler(); 
    } else {
      setError(true); 
    }
  };

  return (
    <InputGroup
      style={{
        borderLeft: "1px solid #D5DFDE",
      }}
    >
      <IMaskInput
        mask={[
          { mask: "+{1} 000-000-0000" },
          { mask: "+{1} 000-000-0000-000000000000" },
        ]}
        radix="."
        value={valueMask}
        unmask={true}
        ref={ref}
        inputRef={inputRef}
        //@ts-ignore
        dispatch={(appended: any, dynamicMasked: any) => {
          const number = (dynamicMasked.value + appended).replace(/\D/g, "");
          console.log("value", number);

          if (number.length > 11) {
            return dynamicMasked.compiledMasks[1];
          }

          return dynamicMasked.compiledMasks[0];
        }}
        onAccept={(value: any, mask: any) => {
          if (value.length < 11 && !disable) {
            setDisable(true);
          }
          if (value.length === 11 && disable) {
            setDisable(false);
          }
          if (value.length > 11 && !disable) {
            setDisable(true);
          }

          /* if(error) {
            setError(false); 
          } */

         if (value.length > 11 && !error) {
            setError(true);
          } else if (value.length <= 11 && error) {
            setError(false);
          } 
          setValueMask(value);
        }}
        //@ts-ignore
        onKeyDown={(e: any) => {
          if (e.keyCode === 13 && !e.shiftKey) {
            if(disable) {
              setError(true); 
              return false; 
            }
            handler();
            e.preventDefault();
            return false;
          }
        }}
        //@ts-ignore
        placeholder="+1 000-000-0000"
        style={{
          paddingLeft: "5px",
          outline: "none",
          paddingRight: "5px",
          width: "100%",
          border: error ? "1px solid #d55252" : "",
        }}
      />
      <InputRightElement>
        <IconButton
          padding="12px"
          onClick={sendMessage}
          aria-label="icon"
          icon={
            <img
              alt="send"
              style={{ height: "16px" }}
              src={`${process.env.PUBLIC_URL}/send-icon.svg`}
            />
          }
          size="6"
          display="flex"
          backgroundColor="transparent"
          width={"100%"}
          height={"100%"}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default MaskPhone;
