const uris = {
    patient: () => `${process.env.REACT_APP_API_URI}/api/v3/patient`,
    updatePatient: () => `${process.env.REACT_APP_API_URI}/api/v3/patient/update`,
    checkResetPwdToken: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/check-reset-token`,
    checkSetPwdToken: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/check-set-token`,
    checkRegisterToken: () => `${process.env.REACT_APP_API_URI}/api/v3/join/check/token`,
    register: (slug: string) => `${process.env.REACT_APP_API_URI}/api/v3/join/${slug}`,
    login: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/login`,
    loginToken: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/check-chat-token`,
    forgotPwd: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/forgot-password`,
    resetPwd: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/reset-password`,
    acceptTos: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/accept-tos`,
    setPwd: () => `${process.env.REACT_APP_API_URI}/api/v3/auth/set-password`,
    message: () => `${process.env.REACT_APP_API_URI}/api/v3/messages`,
    messageUpdated:  () => `${process.env.REACT_APP_API_URI}/api/v3/messages/verbose`, 
    answerQuestionnaire: () => `${process.env.REACT_APP_API_URI}/api/v3/questionnaire/answer`, 
    answerUndo: () =>`${process.env.REACT_APP_API_URI}/api/v3/questionnaire/undo`, 
    markMessage: ( id: number ) => `${process.env.REACT_APP_API_URI}/api/v3/messages/mark/${id}`,
    ping: () => `${process.env.REACT_APP_API_URI}/api/v3/ping`,
    document: (documentId: string) => `${process.env.REACT_APP_API_URI}/api/v3/documents/${documentId}`,
    captcha: () => `${process.env.REACT_APP_API_URI}/captcha/api/default`,
    setEpisodeType: () => `${process.env.REACT_APP_API_URI}/api/v3/episode/type`,
    checkEmployerCode: () => `${process.env.REACT_APP_API_URI}/api/v3/join/check/employer`,
    checkEmail: () => `${process.env.REACT_APP_API_URI}/api/v3/join/check/email`,
    dependentsInvite: () => `${process.env.REACT_APP_API_URI}/api/v3/patient/dependents/invite`,
    createDependents: () => `${process.env.REACT_APP_API_URI}/api/v3/patient/dependents/create`,
    setFirstLogin: () => `${process.env.REACT_APP_API_URI}/api/v3/patient/logged-in`,
    getPusherConfig: () => `${process.env.REACT_APP_API_URI}/api/v3/pusher/config`,
}

export default uris;