import { Box, Flex } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";

function FamilyRegisterLaoyut(props: any) {
  const [isLessMdSize] = useMediaQuery("(max-width: 768px)");
  const setHeight = () => {
    if (window.innerWidth <= 992) {
      return "calc(100vh - 144px);";
    }
    return "100%";
  };
  let condition = (props.step === 3 || props.step === 2) && isLessMdSize;

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minH={setHeight()}
    >
      <Flex
        height={{base: "100%", md: "100%"}}
        px={{ base: condition ? "0" : "16px", md: "40px", "2xl": "80px" }}
        pb={{ base: "0px", md: "60px", lg: "unset" }}
        direction="column"
        justify={"flex-start"}
      >
        {props.children}
      </Flex>
    </Box>
  );
}

export default FamilyRegisterLaoyut;
