

const en = {
    translation: {
        lang: 'en',
        defaultMessages: {
             //nPGreet: 'Hello {{first_name}}, I am your virtual assistant. I\'m here to help whenever you have a medical question or need care.',
            nPGreet: 'Welcome to Allhealth! Before we begin, please note that if you are suffering from a medical emergency, you must call 911 or go to an emergency room. We also need to inform you that there are limitations to virtual consultations and that due to these limitations, you may be redirected to be seen in person for a physical assessment. Do you agree to proceed? Please type "yes", and our care navigator will be with you in a minute.',
            nPGreet2: 'How can I help you today?',
            nPGreetCorona: 'Welcome to Allhealth! Before we begin, please note that if you are suffering from a medical emergency, you must call 911 or go to an emergency room. We also need to inform you that there are limitations to virtual consultations and that due to these limitations, you may be redirected to be seen in person for a physical assessment. Do you agree to proceed? Please type "yes", and our care navigator will be with you in a minute.',
            nPGreetCorona2: 'Please describe the symptoms you are currently experiencing.',
            nPThanking: 'Thank you for providing me with this information. Please wait while I connect you with a health care provider.'
        },
        loginScreen: {
            linkEnglish: 'English',
            linkFrench: 'Français',
            greetingTitle: 'Welcome',
            pageTitle: 'LOG IN',
            labelEmailInputText: 'E-mail address',
            labelPasswordInputText: 'Password',
            loginBtnText: 'Log in',
            helpLinkText: 'Forgot password?',
            question: 'Don’t have an account?',
            link_register: 'Click here to Create an account',
        },
        chatScreen: {
            headerTitle: 'Chat with a Care Navigator',
            connectedToNP: 'You\'re connected to {{doctor}} {{role}}',
            chatHistoryLabel: 'Open chat history',
            downloadPDFLabel: 'Download PDF File',
            files_limit: "*Referral document preview (up to 5 files)",
            back_btn: "Back",   
        },
        forgotPasswordScreen: {
            pageTitle: 'Forgot your password?',
            subTitle: 'Tell us your email address, and we’ll get you back on track in no time.',
            labelEmailInputText: 'Email address',
            labelCaptchaInputText: 'Please enter the text below',
            captchaInputTextPlaceholder: 'Please enter the text here',
            btnResetPasswordText: 'Reset password',
            linkForgotCredsText: 'Forgot login info?'
        },
        resetPwdLinkSent: {
            pageTitle: 'Check your email',
            text1: 'If your email address is registered in Allhealth records, you will receive an email.',
            text2: 'Please click on the link to reset your password.',
            login: 'Login'
        },
        createNewPassword: {
            pageTitle: 'Create your password',
            newPwdInpLabel: 'Choose a password (min 8 characters)',
            cnfNewPwdInpLabel: 'Confirm  password',
            resetPwdBtn: 'Reset password',
            setPwdBtn: 'Create password',
            yourPasswordHasBeenReset: 'Your password has been reset',
            resetYourPassword: 'Reset your password',
            iAcceptThe: 'I accept the',
            termsAndConditions: 'terms and conditions'
        },
        chatTypeScreen: {
            pageTitle: 'Hello {{name}}',
            subTitle: 'How can we help you?',
            labelNewVisit: 'Start a new visit / Continue chat',
            labelCovidVisit: 'I\'d like a Coronavirus assessment',
            loadingTitle: 'Please wait...',
            loadingSubTitle: 'We update your type of visit in our database. It may take a little while.',
            appointmentBtnTitle: 'Book an appointment time',
            buySingleBtnTitle: 'Buy a single visit - ${{price}}',
            buyAnnualBtnTitle: 'Buy an annual membership - ${{price}}', 
            billingBtnTitle: 'Manage billing', 
            logout: 'Logout', 
        },
        createPwdAgreement: {
            pageTitle: 'Create your password',
            subTitle: 'In order to create your account, please accept the ',
            agreementLinkTitle: 'terms and condition',
            agreementLinkTitleNew: 'Terms and condition',
            agreementLink: '#',
            pp: '/pp_en.html',
            declineBtnTxt: 'Decline',
            acceptBtnTxt: 'Accept'
        },
        supportDialog: {
            title: 'Retrieve login info ',
            helpLabel: 'Please contact support to retrieve your login info',
            helpTitle: 'Tap Medical Support',
            helpContact: 'support.allhealth@tapmedical.ca',
            callBtnText: 'Email support',
            tooltipText: 'Need help? Email us at:', 
            disclaimer_title: 'Support Email Policy',
            disclaimer_text: 'Please be aware that it is against our policy to include any Protected Health Information (PHI) in support emails. This includes but is not limited to, photographs, descriptions of medical conditions, or any personal data that can identify a person. For security and privacy reasons, any email containing such information will not be responded to. Please limit your support queries to technical and login issues. Thank you for your understanding and cooperation.'
        },
        invalidTokenSupportDialog: {
            title: 'Oops! This link is expired.',
            helpLabel: 'This create password link is no longer valid. Please reset your password again.',
            helpLabelCreatePwd: 'Please contact our support team to receive a new link.',
            helpTitle: 'Tap Medical Support',
            helpText: 'If you already created the password click on the link below.',
            textLink: 'https://allhealth.tapmedical.ca/',
            helpContact: 'support.allhealth@tapmedical.ca',
            callBtnText: 'Email support'
        },
        invalidRegisterTokenSupportDialog: {
            title: 'Oops! This link is expired.',
            helpLabel: 'Please contact our support team to receive a new link.',
            helpTitle: 'Tap Medical Support',
            helpContact: 'support.allhealth@tapmedical.ca',
            callBtnText: 'Email support'
        },
        vCardDialog: {
            title: 'Download your contact card',
            subInfo1: 'AllHealth',
            subInfo2: 'T: 555 0123 456',
            subInfo3: 'support.allhealth@tapmedical.ca'
        },
        errors: {
            misc: 'An unexpected errro occurred!',
            auth: 'These credentials do not match our records.',
            captcha: 'The captch value is invalid',
            token: 'This reset password link is no longer valid. Please reset your password again.',
            authToken: 'Due to security reasons, the link you clicked is only valid for a certain period of time. Please log back in to view your most recent message your health care provider at AllHealth sent you. ',
            title_files: "Too many files.",
            text_files: "Choose up to 5 files maximum.",
            file_validation_title: "The file {{file_name}} is too large.",
            file_validation_text: "The file has to be less than 10 000 kb."
        },
        success: {
            pwdReset: 'Your password has been successfully reset. Please log in.',
            pwdSet: 'Your password has been successfully created. Please log in.'
        },
        registerForm: {
            title: 'Welcome to Allhealth!',
            subTitle1: 'To create your account and verify your identity, we need some information. Please complete the  information below and send it back to us.',
            subTitle2: 'Once your information is verified, we will send you a separate email where you will be able to  create a password.',
            subTitle3: 'High quality, convenient care is just moments away!',
            lastName: 'Last name',
            firstName: 'First Name',
            phoneNumber: 'Phone Number',
            mobileNumber: 'Mobile Number',
            faxNumber: 'Fax number',
            address: 'Address',
            email2: 'Email address ',
            name: 'Name',
            province: 'Province',
            spouseTypes: 'Spouse (H/W/S)*',
            medicareNumber: 'Medicare number',
            medicareExpiration: 'Expiration date',
            dob: 'Date of birth (mm/dd/yyyy)',
            sex: 'Sex at birth (M/F)',
            email14AndOver: 'Email address (if 14 years old or over)',
            submit: 'Submit',
            removeBtn: 'Remove child',
            phone14plus: 'Mobile number (if 14 years old or over)',
            spouseFmd: 'Spouse\'s family doctor (if applicable)',
            spousePharma: 'Spouse\'s preferred Pharmacy (if applicable)',
            childFmd: 'Child\'s family doctor (if applicable)',
            childPharma: 'Child\'s preferred Pharmacy (if applicable)',
            ptRecomendation: 'Please list them',
            allergiesQuestion: 'Do you have any allergies?',
            medicationsQuestion: 'Are you currently taking medications?',
            healthIssuesQuestion: 'Do you have medical conditions?',
            proceduresQuestion: 'Have you ever had surgery?',
            textSpousePharmacy: 'Do you have a spouse\'s preferred pharmacy?',
            textSpouseFamilyDoctor: 'Do you have a spouse\'s family doctor?',
            textSpouse: 'Do you have a spouse?',
            provinces: {
                quebec: 'Quebec',
                ontario: 'Ontario',
                nunavut: 'Nunavut',
                alberta: 'Alberta',
                saskatchewan: 'Saskatchewan',
                manitoba: 'Manitoba',
                new_brunswick: 'New Brunswick',
                british_columbia: 'British Columbia',
                prince_edward_island: 'Prince Edward Island',
                nova_scotia: 'Nova Scotia',
                newfoundland_labrador: 'Newfoundland and Labrador',
                northwest_territories: 'Northwest Territories',
                yukon: 'Yukon',
            },
            gender: {
                male: 'Male',
                female: 'Female'
            },
            ptSection: {
                title: 'Personal Information',
                companyName: 'Company name',
                mobileNumber: 'Mobile number',
                address: 'Address',
                city: 'City',
                postalCode: 'Postal code',
                email: 'Email address (preferred non-corporate email address)',
            },
            fdSection: {
                title: 'Family doctor',
                selectText: 'Do you have a Family Doctor?',
                subTitle: 'Please provide the following information',
                yes: "Yes",
                no: "No",
            },
            pharmacy: {
                title: 'Preferred Pharmacy (if applicable)',
                selectText: 'Do you have a preferred pharmacy?',
            },
            family: {
                title: 'Family Information',
                child: 'Child',
                addChild: 'Add child',
                childInfo: 'Child Info'
            },
        },
        registerComplete: {
            title: 'Thank you for completing the registration process.'
        },
        validation: {
            invalid: 'Invalid {{field}}',
            required: '{{field}} is required',
            mismatch: '{{field}} does not match the confirmation {{field}} field.',
            pwdInvalid: '{{field}} must be atleast 6 characters and contain 1 uppercase, 1 lowercase and 1 numeric character.',
            cnfPwdInvalid: 'Passwords do not match',
            length: '{{field}} must be atleast {{characters}} long.',
            oneCapCharacter: '{{field}} must contain atleast 1 capital character.',
            oneLowCharacter: '{{field}} must contain atleast 1 lowercase character.',
            oneNumericCharacter: '{{field}} must contain atleast 1 numeric character.',
            oneSpecialCharacter: '{{field}} must contain atleast 1 special character.',
            length_2: '8 characters minimum',
            oneCapCharacter_2: '1 capital character',
            oneLowCharacter_2: '1 lowercase character',
            oneNumericCharacter_2: '1 numeric character',
            oneSpecialCharacter_2: '1 special character',
        },
        validation_code: {
            required: "You must enter your employer code.", 
            alphaNumeric: "Your employer code should only contain 6 alpha (A-Z) characters.",
            invalid: "This employer code is invalid", 
            email_error: "An account with this email address already exists",
        },
        new_register: {
          step: 'Step',
          next_step: 'Next',
          previous_step: 'Previous',
          next_question: 'Next question',
          previous_question: 'Previous question',
          current_step: '{{step1}} of {{step2}} - ',
          step_title_1: 'Personal Information',
          step_title_2: 'Location & Pharmacy',
          step_title_3: 'Medical Profile',
          step_title_4: 'Password',
          step_title_5: 'Spouse Family Doctor',
          employer_title: "Accessing Allhealth",
          employer_text: "Your access to Allhealth is provided by your employer. Please check your email and enter your employer’s unique six character “Employer code”.",
          employer_label: "Employer Code",
          your_employer: "Your employer (company name)",
          top_bar_email: 'Got a question? Email us at',
          top_bar_email_2: 'Got a question? Email us',
          top_bar_email_address: 'support.allhealth@tapmedical.ca ',
          top_bar_question: 'Got a question?',
          email_us: 'Email us',
          bottom_text_link: ' Click here to Sign In',
          bottom_question: 'Already have an account?',
          bottom_questionForgotPass: 'Remember your password?',
          label_search: "Address search",
          pharmacy_search: "Pharmacy search",
          title_login: "Welcome to Allhealth!",
          main_page_title: 'Let’s Create Your Account',
          main_page_text: 'In order to create your account and verify your identity, we need some information. Once your information is submitted and verified, we will email you an account creation confirmation.',
          companyName: 'Company name',
          mobileNumber: 'Mobile number',
          address: 'Address',
          address_2: 'Address Line 2 (optional)',
          city: 'City',
          medicareNumber: 'Health card number',
          medicareExpiration: 'Expiration date',
          medicareExpirationBase: 'Expiration date (if applicable)',
          lastName: 'Last name',
          firstName: 'First Name',
          cellularNumber: 'Phone number',
          phoneNumberUser: 'Mobile phone number', 
          phoneNumberErrorText: 'A mobile phone number is required in order to receive text/SMS notifications.', 
          emailAddress: 'Email address ',
          date_of_birth: "Date of birth",
          sex_of_birth: "Sex at birth",
          gender: {
            male: 'Male',
            female: 'Female'
         },
         name_warning: "As written on your government issued ID", 
         step_2_text: 'What is your home address?',
         step_2_subtitle: 'Let us know your preferred pharmacy ',
         step_2_subtitle_remark: 'if applicable',
         step_2_location_pharmacy: 'STEP 2 - Location & Pharmacy',
         text_button_not_doctor: 'I don’t have a family doctor',
         your_Location: 'Your Location',
         your_pharmacy: 'Your Pharmacy',
         pharmacy_label: "Do you have a preferred pharmacy?",
         province: 'Province',
         search: 'Search',
         fax_number: 'Fax number',
         spouse_last_name: 'Spouse Last Name',
         spouse_first_name: 'Spouse First Name',
         step_4_text: 'Let us know the preferred pharmacy of your spouse ',
         optional: 'optional',
         step_5_text_btn_no_doctor: 'No family doctor',     
         step_5_text_btn_same_doctor: 'Same family doctor', 
         step5_password: "STEP 4  - Your Password", 
         step5_password_title: "Create Your Password",
         create_account: 'Create my account',    
         success_create: 'Your account has been created',
         finish_text: 'Thank you for trusting Allhealth with your primary healthcare needs. You can now sign-in and chat with a member of our care team about any health conditions you may be experiencing.',
         finish_text_2: "All family members will receive a separate invitation to create their user account.",
         finish_text_3: "For any questions or concerns, you can contact us at ",
         finish_text_4: "The Allhealth virtual clinic is open ",
         finish_text_5: "Monday to Friday, from 8:00am to 8:00pm.",
         login: 'Login',
         step_3_medical_profile: 'STEP 3 - Medical Profile',
         step_3_health_issues: 'Health History',
         label_medication_list: 'List all your surgeries and medical procedures',
         step_3_surgeries_medical_procedures: 'Surgical History',
         medical_history: 'Medical History',
         your_family_doctor: 'Your Family Doctor',
         fmd_map_label: "Family Doctor or your GMF Clinic",
         i_dont_have_fyamily_doctor: 'I dont have a family doctor',
         list_your_allergies: 'List your allergies',
         allergies_list: 'Allergies',
         medication_list: 'Medications',
         text_input: "Surgical procedures...",
         list_all_your_medications: 'Specify',
         health_issues: 'Medical conditions...',
         list_exisisting_health_issues: 'Specify',
         STEP_4_spouse: 'STEP 4 - Spouse',
         step_4: "STEP {{step}} - Dependants",
         family_members: "Family members",
         add_family_members: "Add Family Members", 
         fmd_members_text_1: "Invite family members under your account. Eligible spouses/partners and children over 18 will receive an email invitation to create their own personal account.",
         fmd_members_text_2: "Please note that if you do not add your family members now, you will need to reach out to our customer service to add dependants after your initial registration.",
         members_card_title_1: "Spouse or partner",
         members_card_title_2: "Child",
         members_card_text_1: "Invite your spouse or partner so they  can benefit from your coverage on their own private account. Only immediate family members can be added.",
         members_card_text_2: "Add your child/children so they can benefit from your coverage.",
         members_btn: "Invite",
         btn_add: "Add",
         skip_step: "Skip this step",
         does_your_spouse_have_preferred_pharmacy: 'Does your spouse have a preferred pharmacy?',
         spouse_pharmacy: 'Spouse Pharmacy',
         spouse_information: 'Spouse Information',
         add_spouse_title: "Invite Your Spouse/Partner",
         add_spouse_text: "Provide your spouse’s/partner’s email address to invite them to create their own account. They will  benefit from your family coverage plan and they can then start their own consultation.",
         success_invite: " was invited to your family",
         invate_was_send: "Your invitation was sent to: ",
         invite_was_send_text: " will receive an email with instructions on how to create their own personal account. ",
         invite_was_send_text_2: " will receive an email with instructions on how to create their own personal account.",
         invite_was_send_text_minor: " was added to your family",
         send_invitation: "Send invitation",
         btn_next_step: "Go to next step",
         btn_go_home: "Go home",
         done: "I’m done",
         home: "Home",
         label_child_first_name: "Child first name",
         label_child_last_name: "Child last name",
         health_card_child: "Health card number",
         btn_add_child: "Add a child",
         or: "Or",
         add_child_title: "Add your child",
         add_child_text: "Children under 18 will be added under the parent’s account. For a child aged 18 years and older, please provide their email address so they can create their own account.",
         add_child_text_2: "Provide your 18 year old and above child’s email address to invite them to create their own account. Your child will  benefit from your family coverage plan and can then start their own consultation.",
         child_invited: "Your child will receive an email with the steps on how to create their own personal account.",
         add_child_question: "Is your child 18 years or older?",
         add_child_btn: "Add another family member", 
         add_to_family: "Add to family",
         child_was_added: "{{name}} was added to your account.",
         your_spouse_will_receive: 'Your spouse will receive their own invitation to sign up at the email address provided.',
         your_spouse_family_doctor: 'Your Spouse’s Family Doctor',
         no_spouse_skip_this_step: 'No spouse? Skip this step',
         my_spouse_family_doctor: 'My spouse doesn’t have a family doctor',
         female: 'female',
         male: 'male',
         mm: 'MM',
         dd: 'DD',
         yyyy: 'YYYY',
         step_2_3_title: 'Allergies and Medications',
         btnCompleteRegistration: 'Register',
         btn_accept: "I accept the terms",
         btn_decline: "I decline",
         btn_success: "Let’s Get Started",
        },
        wttooltip: {
            textInfo1: 'The clinic is currently closed. We are open 8am to 8pm Monday to Friday.',
            textInfo2: 'All requests sent outside of our business hours will be treated in priority during the next business day.',
            textInfo3: 'Please note the clinic will be closed Dec 24, 27 and 31st for the holidays. Happy Holidays!',
            textInfo4: 'Please note, that our clinic will be closed on Friday April 15 and Monday April 18 for Easter.',
            textBefore: "Please note that the clinic will be closed for {{name}}. All requests sent outside of our business hours will be treated in priority during the next business day. ",
            textCurrent: "The clinic is currently closed for {{name}}. We are open 8am to 8pm Monday to Friday. All requests sent outside of our business hours will be treated in priority during the next business day. ",
            holiday_1: "National Patriots day (Victoria Day)",
            holiday_2: "Saint-Jean Baptiste Day",
            holiday_3: "Canada Day",
            holiday_4: "Labour Day",
            holiday_5: "ThanksGiving Day",
            holiday_6: "Christmas Day",
            holiday_7: "New Year's Day",
            holiday_8: "Easter Monday", 
            holiday_9: "National Day for Truth and Reconciliation", 
            clinic_closed: "Sorry, the clinic is currently closed. We are open 8am to 8pm Monday to Friday. All requests sent outside of our business hours will be treated in priority during the next business day."
        }, 
        questionnaire: {
            virtual_assistant: "Virtual Assistant",
            not_upload_file: "No file selected",
            error: "Error",
            click_answer: "Please click on one of the answers below", 
            type_text: "Enter your answer in the text field below",
            something_wrong: "Something went wrong",
            send: "Send", 
            archived_text: "Your encounter has ended. Please don't hesitate to re-connect and we'll be happy to assist you with your next request.", 
        },
        onesignal: {
            actionMessage: "Click “subscribe” to receive the latest notifications from our care team.", 
            acceptBtn: "Subscribe", 
            cancelBtn: "Later", 
            text_1: "Subscribe to notifications",
            text_2: "You're subscribed to notifications", 
            text_3: "SUBSCRIBE",
            text_4: "UNSUBSCRIBE",
            prenotify: "Click to subscribe to notifications",
            actionSubscribed: "Thanks for subscribing!",
            actionResubscribed: "You're subscribed to notifications",
            actionUnsubscribed: "You won't receive notifications again",
            title_banner: "Add App to homescreen",
            text_banner_1: "Install the webApp on your phone to allow push notifications. Tap ", 
            text_banner_1_android: "Install the webApp on your phone to allow push notifications. Tap ",
            text_banner_2: " and then “Add to Home Screen”", 
            text_banner_3: " and then “Install app” / “Add to Home Screen”.", 
            remind_btn: 'Remind me next time',
            cancel_btn: 'Don’t ask me again',
            text_banner_ios_chrome: 'To view notifications, please open this app in the Safari browser.', 
        }
    }
};

export default en;
