export const text:string = `NOTICE TO USER: THIS IS AN END USER LICENSE AGREEMENT (THE "AGREEMENT") WHICH SETS OUT THE TERMS AND CONDITIONS UNDER WHICH YOU ARE ENTITLED TO USE THE LICENSED SOFTWARE PRODUCT (AS DEFINED BELOW). PLEASE READ THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY BEFORE YOU INSTALL AND USE THE LICENSED SOFTWARE PRODUCT. BY CLICKING THE "I ACCEPT" BUTTON (IF ANY) AND/OR BY INSTALLING AND/OR BY USING THE LICENSED SOFTWARE PRODUCT YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT INSTALL OR USE THE LICENSED SOFTWARE PRODUCT. IF YOU HAVE PAID FOR A LICENSE, YOU ARE ENTITLED TO A REFUND OF THE LICENSE FEE.
LICENSE GRANT. 9338-7900 Quebec Inc., 4455 Sherbrooke West, 1st floor, Westmount, Quebec H3Z 1E7 ("Tap", “us”, or “we”) hereby grants you, the end user person, company or legal entity ("You"), a royalty-free, non-exclusive, non-transferable, non-sub licensable and limited license to use, on the terms and conditions set out in this Agreement and for the period of time for which You have obtained a license (the "License Period"): (i) the Tap Medical application software product (as applicable) in object code form, including any and all updates and or upgrades thereof, as Tap, in its sole discretion, may provide; and (ii) any thereto related documentation (the "Documentation"), hereinafter collectively referred to as the "Licensed Software Product". You may use the Licensed Software Product to access Tap's servers for the purpose (“Services”) of healthcare diagnosis and scheduling, collaboration, education, predictive health applications and providing documentation that Tap makes available through its technological platform available via: (a) its Website (including, subdomains, widgets and mobile versions); (b) its Application; (c) any information (such as data files, text, software, audio or other files, photographs, videos or other images) rendered available by Tap to which You may have access on Tap’s Website or account, or via Taps Application or other Services (the “Content”); (d) your account or any other programs, media and software designed to be run on electronic devices, existing now or developed in the future, which Tap may authorize from time to time in its discretion, but for no other purpose. 
2. USE RESTRICTIONS. Further, You agree that You may not, nor permit any third party to: (i) sub-license, assign, transfer, distribute, pledge, lease, rent or share Your rights under this Agreement; (ii) modify or adapt the Licensed Software Product; (iii) disassemble, decompile, reverse engineer or otherwise attempt to discover the source code of the Licensed Software Product (unless to the extent specifically permitted by applicable mandatory law); (iv) use the Licensed Software Product for timeshare, service bureau, or any other unauthorized purposes; (v)have more than one(1) Tap account; remove any proprietary notices or labels on the Licensed Software Product; or (vi) use the Licensed Software Product for any purposes not authorized by this Agreement.
3. PROPRIETARY RIGHTS. You acknowledge and agree that Tap shall retain on behalf of itself and its licensors all rights, title and interest to the Licensed Software Product, including but not limited to any and all copyrights, patents, trademarks, trade secrets and any and all other intellectual property rights, and You acquire no rights of whatever nature to any intellectual property rights or other rights in the Licensed Software Product, except for the limited license right expressly set out in Section 1 above.
4. FEEDBACK. You hereby agree and acknowledge that any ideas, suggestions and other feedback that You may provide relating to the Licensed Software Product ("Feedback"), may be used by Tap and its licensors to improve and/or enhance the functionality of the Licensed Software Product and/or any other Tap products and accordingly You hereby grant Tap and its licensors, a non-exclusive, royalty-free, perpetual, irrevocable, worldwide right and license to use, reproduce, disclose, sub-license (without restrictions), distribute, modify, create derivative works of, and or otherwise exploit any such Feedback without any limitations whatsoever.
5. SECURITY AND THIRD PARTY ACCESS. You agree: (i) to choose a strong and secure password; (ii) to keep said password secure and confidential; (iii) not to transfer or duplicate any part of Your account, of the content of Your account, of Tap’s Website, of the Licensed Software product or of any other Services to any other person (e.g., connections or groups) or on any other platform, program or system (such as a cloud computing system, a mobile device hard drive or a USB key). Except as required by law or in compliance with this Agreement, You agree that: (i) You will not permit any third-party to use or access Your account or otherwise benefit from the Services without Tap’s prior written approval (which You must obtain by using the “Contact Us” feature of Tap’s Website); (ii) You will not access or authorize or assist any person or entity in accessing or attempting to access, any portion of the Services via any means other than by the commercial browser (such as Internet Explorer, Mozilla Firefox, Chrome or Safari) or mobile app that Tap has authorized and, whenever applicable, provided to You; (iii) You will not access the Services, the content of Your account, the Tap Website, the Licensed Software Product using automated means (such as harvesting bots, robots, spiders or scrapers); and (iv) You will not, and will not permit that others upload, viruses or malicious codes.
6. NO WARRANTY. Tap offers no warranties for the Licensed Software Product, which is provided free of charge to You.
7. SUPPORT SERVICES. Tap will, during Tap's normal working hours, provide You with e-mail and/or other support services for the Licensed Software Product through the support centre at the Tap website www.tapmedical.ca (the "Support Services").
8. INSTALLATION STATISTICS AND AUTOMATIC UPDATES. You hereby acknowledge and agree that upon installation of the Licensed Software Product, the Licensed Software Product will automatically collect certain information from Your computer for pure statistical purposes. You further, acknowledge and agree that the Licensed Software Product is configured to automatically download, from Tap's servers ("Automatic Download"): (i) updates as a result of error corrections or enhanced functionality of the Licensed Software Product ("Software Updates"); and (ii) information updates, which may include license specific information, Tap news and offers and any other information relating to the Licensed Software Product; ("Information Updates"). You may reconfigure the Licensed Software Product to disable the Automatic Download by changing the settings of the Licensed Software Product so that the Software Updates and/or the Information Updates will be downloaded only upon Your specific request ("Manual Download"). Further, you acknowledge and agree that the Licensed Software Product is configured to automatically send to Tap's servers, license specific information relating to the Licensed Software Product from Your computer, whenever an Automatic Download or Manual Download of Software Updates and/or Information Updates is made.
Any personally identifiable information (information that can alone, or together with other date in our possession, identify an individual user), including any patient information and medical information about You is stored and used by us according to our Privacy Policy and you can exercise your rights regarding such information as set out in our Privacy Policy, which is hereby incorporated by reference.
9. DISCLAIMER. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE PUBLIC ORDER LAW, THE LICENSED SOFTWARE PRODUCT (INCLUDING FOR THE AVOIDANCE OF ANY DOUBT, THE DOCUMENTATION) AND THE SUPPORT SERVICES ARE PROVIDED "AS IS" AND TAP AND ITS LICENSORS MAKES NO REPRESENTATIONS OR WARRANTIES WHATSOEVER WITH RESPECT TO THE LICENSED SOFTWARE PRODUCT OR THE SERVICES WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT OF THIRD PARTIES' INTELLECTUAL PROPERTY RIGHTS. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, TAP AND ITS LICENSORS MAKES NO REPRESENTATION OR WARRANTY THAT: (i) THE OPERATION OF THE LICENSED SOFTWARE PRODUCT, OR THE SERVICES, WILL BE ERROR-FREE AND UNINTERRUPTED; OR (ii) ALL ERRORS OR DEFECTS IN THE LICENSED SOFTWARE PRODUCT WILL BE CORRECTED. IF YOU ARE SUBJECT TO A JURSIDICTION IN WHICH ANY OF THE ABOVE DISCLAIMERS ARE NOT VALID, YOU AGREE TO BE BOUND BY THE SAME TO THE MAXIMUM EXTENT PERMITTED BY SUCH LAW OR REGULATION. FURTHERMORE, TAP RESERVES ITS RIGHT TO CHANGE THE SERVICES FROM TIME TO TIME IN TAP’S DISCRETION.
EVEN IF THE TAP WEBSITE, THE LICENSED SOFTWARE PRODUCT OR SERVICES, AS WELL AS YOUR ACCOUNT AND THE CONTENT OF YOUR ACCOUNT CONTAIN INFORMATION RELATING TO YOUR MEDICAL OR HEALTH CONDITION, TAP EXPRESSLY POINTS OUT THAT TAP DOES NOT PROVIDE MEDICAL ADVICE TO YOU (OR ANYONE ELSE) AND ACCORDINGLY NEITHER THE TAP WEBSITE, LICENSED SOFTWARE PRODUCT, YOUR ACCOUNT OR SERVICES NOR ANY OF THEIR RESPECTIVE CONTENT SHALL: (I) BE USED FOR SELF-DIAGNOSIS; (II) CONSTITUTE OR BE CONSTRUED AS AN INTERPRETATION OF THE MEDICAL CONDITION OF ANY PATIENT OR USER; AND (III) BE CONSIDERED AS GIVING A PROGNOSTIC, AN OPINION, A DIAGNOSIS, A DEFINITE STATEMENT OR A MEDICAL RECOMMENDATION REGARDING ANY PARTICULAR MEDICAL CASE OR TREATMENT THAT SHOULD BE UNDERGONE. AS TO THE PATIENTS’ INFORMATION, SUCH INFORMATION IS INDEPENDENTLY ADDED BY YOU, AND DUE TO ITS VERY NATURE IS NOT CONTROLLED OR REVIEWED BY TAP; HENCE, TAP HEREBY POINTS OUT THAT YOU ALONE ARE RESPONSIBLE FOR ALL DECISIONS MADE WHEN RELYING ON SUCH NOTES AND FOR YOUR ACTIONS AND OMISSIONS.
10. LIMITATION OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE PUBLIC ORDER LAW, IN NO EVENT SHALL TAP, OR ITS LICENSORS, BE LIABLE TO YOU OR ANY THIRD PARTY: (I) FOR INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES; NOR; (II) FOR DAMAGE TO PROPERTY, LOSS OR CORRUPTION OF DATA, LOSS OF USE, LOSS OF PRODUCTION, LOST PROFITS, SAVINGS OR REVENUES OF ANY KIND (WHETHER DIRECT, INDIRECT OR CONSEQUENTIAL), NO MATTER WHAT THEORY OF LIABILITY EVEN IF TAP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND TAP'S LIABILITY HEREUNDER SHALL NOT, IN AGGREGATE, EXCEED THE LICENSE FEE PAID (IF ANY) FOR THE LICENSED SOFTWARE PRODUCT. IF YOU ARE SUBJECT TO A JURISDICTION IN WHICH THE ABOVE LIMITATION OF LIABILITY OR ANY PART THEREOF IS NOT VALID, YOU AGREE TO BE BOUND BY THE SAME TO THE MAXIMUM EXTENT PERMITTED BY SUCH LAW OR REGULATION.
YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS TAP, ITS AFFILIATED COMPANIES AND ITS DIRECTORS, EMPLOYEES, ADMINISTRATORS AND OTHER REPRESENTATIVES OR BUSINESS PARTNERS FROM ANY AND ALL CLAIMS, DEMANDS, JUDICIAL PROCEDURES, ACTIONS, FINES, FEES, LOSSES, INJURIES, REQUESTS, LOSS, INJURIES AND DAMAGES (DIRECT OR INDIRECT), OF ANY KIND WHATSOEVER, KNOWN OR UNKNOWN, ARISING IN ANY MANNER WHATSOEVER FROM YOUR OMISSION, USE OR MISUSE OF THE TAPWEBSITE, LICENSED SOFTWARE PRODUCT, YOUR ACCOUNT, CONTENT OR SERVICES.
11. TERM AND TERMINATION. This Agreement will enter into force upon Your acceptance of this End User License Agreement, however in no event later than upon installation of the Licensed Software Product or first use by You of a Service offered by Tap and will, unless prematurely terminated as set out herein, remain in force for until the Licensed Software Product is uninstalled from Your device (the “License Period”). Tap is entitled to, at its sole discretion, prematurely terminate this Agreement with immediate effect or suspend the provision of the Software Updates and/or the Information Updates, upon notice to You (if possible), if You (i) are in breach of any terms and conditions of this Agreement; or (ii) do not respect the Code of Conduct published by Tap on our website from time to time and which is incorporated by reference into this Agreement. You may terminate this Agreement at any time upon written notice to Tap. Upon expiration or termination of this Agreement, You shall immediately: (i) cease the use of the Licensed Software Product; and (ii) destroy and erase the Licensed Software Product (including any back-up copy thereof).
12. PRIVACY AND CODE OF CONDUCT. By entering into this Agreement with Tap, You acknowledge and agree that the Privacy Policy and the Code of Conduct of Tap, as updated from time to time and accessible on the Tap website www.tapmedical.ca, are applicable to You.
13. THIRD-PARTY SOFTWARE. The Licensed Software Product may contain certain third-party freeware and open source software, including but not limited to the freeware and open source listed from time to time at www.Tap.ca/thirdpartysoftware/ (the "Third Party Software"). To the extent the terms and conditions for certain Third-Party Software grant You additional rights to use, reproduce or modify such Third-Party Software than the terms and conditions of this Agreement, such rights shall prevail over the terms and conditions of this Agreement for such Third-Party Software portions of the Licensed Software Products.
14. CONSUMER PROTECTION. To the extent any applicable mandatory consumer legislation so requires, certain terms and conditions of this Agreement may be adjusted to fulfill such requirements under public order applicable law, however all other terms and conditions of this Agreement shall remain unaffected.
15. EXPORT CONTROL. You hereby acknowledge that the Licensed Software Product may be subject to import and export control laws and regulations, including but not limited to the U.S. Export Administration Regulations (EAR) and You hereby agree that You will not import or export, or allow any use of the Licensed Software Product which is in violation of such import and/or export control laws and regulations.
16. MISCELLANOUS. This Agreement constitutes the entire agreement concerning the subject matter of this Agreement and it supersedes any and all prior proposals, understandings or agreements between the parties relating to the subject matter hereof as well as any prior rights to use the Licensed Software Product and Services which You may have obtained. You specifically acknowledge and agree that any terms and conditions in any purchase orders that conflict with the terms and conditions of this Agreement are null and void. No waiver or modifications to this Agreement shall be valid unless in writing and duly executed by both parties hereto. If any provision herein is held by a court of competent jurisdiction to be contrary to law, the remaining provisions of this Agreement shall remain in full force and effect.
17. ELECTRONIC VERSION OF AGREEMENT. You and Tap agree that the legal value and the validity of this End User License Agreement is neither increased nor diminished based on the chosen medium or technology. Accordingly the electronic copy will be deemed authentic provided that its integrity can be ensured, and will have the same legal value and validity, and will fulfil the same functions of a paper copy.
18. GOVERNING LAW. This Agreement shall be governed by the laws of the Province of Québec, including the laws of Canada applicable therein, but excluding conflict of laws principles. This Agreement shall not be governed by United Nations Convention on Contracts for the International Sale of Goods (CISG), the application of which is hereby expressly excluded. Any and all disputes arising out of this Agreement shall be subject to the sole and exclusive jurisdiction of the courts of of the Province of Québec, District of Montréal, except that Tap may bring actions for injunctive relief in any competent court of jurisdiction. Notwithstanding the above, if You are a consumer, this Section 17, shall not affect any mandatory right You may have to take action in Your country of residence and under the laws of that country.
19. ENGLISH LANGUAGE. The parties have expressly required that this End User License Agreement and each document incorporated by reference in this Agreement be drafted in the English language. Les parties aux présentes ont expressément exigé que ce contrat et chacune des documents intégrés par référence soient rédigés en langue anglaise.
20. CONTACT INFORMATION. If You have any questions about the Licensed Software Product or this Tap End User License Agreement, the contact details for Tap are specified on the Tap website, www.tapmedical.ca.`

