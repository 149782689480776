import { Box, Text } from '@chakra-ui/layout'
import RegisterLaoyut from './RegisterLaoyut'
import { HStack, useMediaQuery, Image} from "@chakra-ui/react"
import { Button } from '@chakra-ui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import congrats_icon from '../../assets/icons/illus_congrats.svg'
import { ROUTE_PATH } from '../../app/routes';


function Step6(props: any) {

  const history = useHistory();
  const {t} = useTranslation();

    return (
       <RegisterLaoyut step={9}>
          <Box pt={{base: '40px', lg:"50px"}} >
          <Image src={congrats_icon} alt="congrats" mb={{base:"46px", lg:"56px"}}/>
           <Text mb={{base:"16px"}} fontWeight="700" fontSize={{base: "32px", '2xl': "40px"}}  color="#304358">
              {t(`new_register.success_create`)}
           </Text>
          <Text mb={{base:"16px"}}  fontSize="16px" color="#8194AA" width={{base:"100%", "md": "80%", lg:"60%"}}>{t(`new_register.finish_text`)}</Text>
          <Text mb={{base:"16px"}}  fontSize="16px" color="#8194AA" width={{base:"100%", "md": "80%", lg:"60%"}}>{t(`new_register.finish_text_2`)}</Text> 
            <Text mb={{base:"16px"}}  fontSize="16px" color="#8194AA" width={{base:"100%", "md": "80%", lg:"60%"}}>{t(`new_register.finish_text_3`)} <Text as="span"  fontSize="16px" cursor="pointer" onClick={() => window.open(`mailto:${t('invalidTokenSupportDialog.helpContact')}`, '_blank')}  color='#40B89C' fontFamily={'Mulish'}>
               {t('invalidTokenSupportDialog.helpContact')}.
            </Text> </Text> 
          <Text mb={{base:"16px"}}  fontSize="16px" color="#8194AA" width={{base:"100%", "md": "80%", lg:"60%"}}>{t(`new_register.finish_text_4`)}
          <Text as="span" color="#304358">
           {t(`new_register.finish_text_5`)}
           </Text>
          </Text>
         </Box>
         <HStack width="100%"  wrap={{base: "wrap", md: "nowrap"}}>
            <Box display="flex" alignItems="center"  width={{base: "100%", md: "50%"}} pt="15px" pr={{md: "20px", lg:"34px"}} pb={{base: '40px', md: '100px'}}>
            <Button 
                 onClick={()=> history.push(ROUTE_PATH.CHAT_TYPE)}
                 rightIcon={<ArrowForwardIcon />} 
                 fontSize="16px"
                 textTransform="none"
                 fontFamily="Mulish"
                 color="#304358"
                 fontWeight="700"
                 borderRadius="10pt"
                 backgroundColor="#FFDB00" 
                 variant="solid">
                  {t(`new_register.btn_success`)}
                </Button>
            </Box>
          </HStack>

       </RegisterLaoyut>

    )
}

export default Step6