import React, { forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  IconButton,
  Text,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  Image,
  Box,
  Link,
  useToast,
  Tooltip,
  useMediaQuery,
  Textarea,
  Flex,
  Spinner,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory , Link as RLink} from 'react-router-dom';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import { AppContextInterface } from '../../app/dto/app-context.dto';
import { AppContext } from '../../app/modules';
import { Message } from './message';
import { MessageDataType } from '../../app/dto/message-data.dto';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { CopyIcon, CloseIcon } from '@chakra-ui/icons';
import { ROUTE_PATH } from '../../app/routes';
import { checkToken, pingInterval, chatSync, reader, addAIMessages } from './helpers';
import { useChannel, useEvent, usePresenceChannel, useClientTrigger, usePusher} from '@harelpls/use-pusher';
import { PlaceholderMessage } from './placeholder_message';
import { useEffectOnce } from 'react-use';
import { Channel } from 'pusher-js';
import { MessageShortType, User } from '../../app/dto';
import text_file from "../../assets/icons/text-file.svg";
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { AlertSupport } from '../../components/alertSupport';
import BannerReloadPage from '../../components/bannerReload';
import { useDeployAppListener } from '../../utils/hooks';
import DragUploadFile from '../../components/dragUploadFile';
import { checkIsIOS, createMessage } from '../../utils/helpers';
import { useUploadFilesHandler } from './hooks';
import BannerBase from '../../components/bannerBase';
import MaskPhone from '../../components/maskPhone';
import { EmailDisklaimerDialog } from '../../components/emailDisklaimerDialog';


export const ChatScreen: React.FC<any> = () => {
    const { t, i18n  } = useTranslation();

    const history = useHistory();
    const toast = useToast();

    const [isLargerThan400] = useMediaQuery("(min-width: 380px)")
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)")

    const { state, messageService, sessionService, appService }: AppContextInterface = useContext(AppContext);
    
    const [ welcomeMessageShowen, setWelcomeMessageShowen ] = useState(false);
    const [ messageInp, setMessageInp ] = useState<string>('');
    const [isDisable, setIsDisable] = useState(false); 
    const [archivedDisable, setArchivedDisable] = useState(false); 
    const [ waitMessageSent, setWaitMessageSent ] = useState<boolean>(false);
    const [ lightboxOpen, setLightboxOpen ] = useState(false);
    const [ lighboxUrl, setLightboxUrl ] = useState('#');
    const [ loadingMore, setLoadingMore ] = useState<boolean>(false);
    const [ loadingMessages, setLoadingMessages ] = useState<boolean>(false);
    const [ sendingMessage, setSendingMessage ] = useState<boolean>(false);
    const [ showChatHistory, setShowChatHistory] = useState<boolean>(false);
    const [isRenderObserver, setIsRenderObserver] = useState(false); 
    const [isFirstElemHide, setIsFirstElemHide] = useState(false);
    const [ listDoctors, setListDoctors ] = useState<any>('');
    const [openTooltip, setOpenTooltip] = useState(false);
    const [messageReceived, setMessageReceived] = useState(false);
    const [showElem, setShowElem] = useState(false); 
    const [isFirstScroll, setIsFirstScroll] = useState(false); 
    const [isUpload, setIsUpload] = useState(false); 
    const [listMessagesLenght, setListMessagesLength] = useState(state.message.messages.length); 
    const [initialScroll, setInitialScroll] = useState(false);
    const [showMaskedPhoneField, setShowMaskedPhoneField] = useState(false); 
    
    const fileInpRef = useRef<any>(null);
    const fileInpRefQuestionnaire = useRef<any>(null); 
    const messageContainerRef = useRef<any>(null);
    const msgContainerBottomRef = useRef<any>(null);
    const msgContainerTopRef = useRef<any>(null);
    const msgCurrentRef=useRef<any>(null);
    const msgInput = useRef<any>(null);
    const topPlaceholderRef = useRef<any>(null);
    const canSendEventType = useRef(true); 
 
    const { client } = usePusher();

    const [ targetContainer, setTargetContainer ] = useState(msgContainerBottomRef);
    const [ connectedOn, setConnectedOn ] = useState(-1);
    const [firstMessageId, setFirstMessageId] = useState<any>('');

    const isIOS = useMemo(() => checkIsIOS(), []); 
 
    let channel: Channel | undefined = useChannel(`presence-allhealth.patientchat.${state.session?.episode?.patient?.id}`); 

    const {isReload} = useDeployAppListener(true);

    const trigger = useClientTrigger(channel);
  
    useEvent(channel, 'pusher:subscription_succeeded', () => {
      setIsDisable(false); 
      console.debug('[PUSHER] subscribed to patient channel')
    } );

    useEvent(channel, 'pusher:subscription_error', () => { 
       setTimeout(() => {
        channel = client?.subscribe(`presence-allhealth.patientchat.${state.session?.episode?.patient?.id}`); 
        if(channel?.subscribed) {
          setIsDisable(false); 
        }
      }, 2000); 
      setIsDisable(true);         
      console.debug('[PUSHER] Error occurred while subscribing to patient channel')
    } );
    
    useEvent(channel, 'client-message', ( data: any ) => {
      if(document.visibilityState === "visible" && !isFirstElemHide) {
        trigger("client-message-read", data); 
      }
      if ( state.message.connectedOn === null ) {
        messageService.setConnectedOn(data);
      }
        messageService.addDoctorMessage(data);

      if(isFirstElemHide) {
        setMessageReceived(true); 
      } else {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    useEvent(channel, 'client-status-archived', async (data: any) => {
      const message: MessageShortType = {
        author: t("questionnaire.virtual_assistant"),
        contents: t("questionnaire.archived_text"),
        created_at: new Date().toISOString(),
        sender: "system",
      };
      setArchivedDisable(true); 
      await messageService.addMessageToState(message); 
      msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    });

    useEvent(channel, 'App\\AllHealth\\Events\\Broadcast\\MessageReceivedEvent', ( data: any ) => {
        if(data?.author?.id !== state.session?.episode?.patient?.id && !data?.is_questionnaire
          ) {
          const lastMessage = state?.message?.messages[state?.message?.messages.length - 1]; 
          if((lastMessage && lastMessage.documents && lastMessage.documents[0]?.id !== data?.documents[0]?.id) || (lastMessage && !lastMessage.documents) ) {

            messageService.addDoctorMessage(data);
           
            if(isFirstElemHide) {
              setMessageReceived(true); 
            } else {
              setTimeout(() => msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 100);
            }

            messageService.markMessage(data, state.session?.token);
         
  
          }
        }
    });

    useEvent(channel, 'App\\AllHealth\\Events\\Broadcast\\EncounterEventHappenedEvent', (data: any) => {
      if(data?.type === 4) {
        refreshChat(1); 
      }
    });


    useEffect(() => {
        if(client) {
            client.connection.bind('connected', function() {
                console.log('Connection connected');
                setIsDisable(false); 
                refreshChat(1); 
            });

            client.connection.bind('unavailable', function() {
                console.log('Connection unavailable');
                setIsDisable(true); 
                toast({
                  title: `You are no longer connected to pusher channel.`,
                  status: "error",
                  duration: 20000,
                  isClosable: true,
              }); 

            }); 
        }
      }, [client]);

   const { members } = usePresenceChannel(`presence-allhealth.patient.${state.session?.episode?.patient?.id}`);
 
    const [ hcpsInEp, setHcpsInEp ] = useState<any>([]);
    const {file, setFile, handlerLoadFile, listFilesUrl, setListFilesUrl} = useUploadFilesHandler(true, msgContainerBottomRef);

      useEffect(() => {
        messageContainerRef?.current?.addEventListener('touchstart', (event:any) => {
          if (document.activeElement === msgInput.current) {
           //logic of getting messages
          }
             event.stopPropagation();
             //event.preventDefault(); 
             msgInput.current?.blur()
       
        })
        return () => {
          messageContainerRef?.current?.removeEventListener('touchstart', ()=>{
            return true
          })
      }
    }, []);  


    useEffect(() => {
      if (members && loadingMessages === false) {
        const doctors = Object.keys(members)?.filter( (id: string) => id.includes('doctor') ).map( (id: string) => members ? members[id] : undefined );
        // display bot messages.
        if ( doctors.length <= 0 ) {
        /*   addAIMessages(state, welcomeMessageShowen, messageService, t, setWelcomeMessageShowen); */
          if(firstMessageId) {
            msgCurrentRef.current?.scrollIntoView({ behavior: "auto" });
            setFirstMessageId(null)
          } else {
            //msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
          }
          //msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
         setTargetContainer(msgContainerBottomRef);
        }
        else {
          if(firstMessageId) {
            msgCurrentRef.current?.scrollIntoView({ behavior: "auto" });
            setFirstMessageId(null)
          }
          //setWelcomeMessageShowen(true);
          setConnectedOn(state.message.messages[state.message.messages.length - 1]?.id || -1);
        }

     
        if(doctors.length > 0) {
          setListDoctors({doctors})
        } else {
          setListDoctors("");
        }

        setHcpsInEp({ doctors, connectedOn: moment() });
      }
    }, [members, loadingMessages]);

    function onVisible() {
    
        const wereOn: string = sessionStorage.getItem('chatPage') || '1';
        chatSync(() => refreshChat(parseInt(wereOn)));

    }

    const refreshChat = async ( page: number = state.message?.messageMeta?.current_page || 1 ) => {
      setLoadingMessages(true);
      await messageService.getMessages( state.session?.token, page );
      if(page === 1) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" })
      }
      setLoadingMessages(false);
    }

    const setReadStatus = () => {
      const lastMessage:any = state.message.messages[state.message.messages.length - 1]; 
      if(lastMessage && !lastMessage?.seen_at && document.visibilityState === 'visible') {
        lastMessage.isLast = true; 
        trigger("client-message-read", lastMessage); 
      }
    }

    useEffect(() => {
      if(isFirstScroll) {
        setReadStatus();
      }
    }, [isFirstScroll]);  

    useEffect(() => {
      if(!isFirstElemHide && isFirstScroll) {
        setReadStatus();
      }
    }, [isFirstElemHide]); 


    useEffectOnce(() => {

      if(!isFirstScroll) {
        setTimeout(() => {
          setIsFirstScroll(true);
          msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 3000); 
      }

      let blurEvent: any = null;

      document.addEventListener("visibilitychange", function(event) {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            setIsFirstScroll(true);
            msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 3000); 
          const lastVisibilityChange = localStorage.getItem('focusedAway');
          if ( lastVisibilityChange && moment().diff(moment(new Date(Date.parse(lastVisibilityChange))), 'minute') >= 10 ) {
            sessionService.logout();
          } 
          msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
        } else  if (document.visibilityState === 'hidden') {
          setIsFirstScroll(false); 
          if(isIOS) {
            msgInput.current?.blur(); 
          }
          localStorage.setItem('focusedAway', moment().toLocaleString());
          blurEvent = event;
          sessionStorage.setItem('chatPage', state.message.messageMeta?.current_page || 1);
        }
      });
      return () => {
        if ( blurEvent )
          document.removeEventListener('visibilitychange', blurEvent);
      }
    });

    
    useEffect(() => {
      function getTime() {
        return (new Date()).getTime();
      }
    
      let lastInterval = getTime();
      
      function intervalListener() {
          let now = getTime();
          let diff = now - lastInterval;
          let offBy = diff - 1000; 
          lastInterval = now;
      
          if(offBy > 100) { 
            
              //onVisible(); 
              if(state?.message.messages[state?.message?.messages?.length - 1]?.id === null) {
                messageService.clearMessages();
              }
              setArchivedDisable(false); 
              chatSync(() => refreshChat(1));
          }
      }

    let listener = setInterval(intervalListener, 1000);
    
      return () => {
        clearInterval(listener); 
      }
    })


    useEffect(() => {
      setTimeout(() => setShowElem(true), 2000);
      // check token.
      checkToken(appService, sessionService, history); 
      // clear existing chat.
      messageService.clearMessages();
      //setWelcomeMessageShowen(false);
      // fetch ongoing messages.
      chatSync(() => refreshChat());
      
      return () => {
        localStorage.removeItem('focusedAway');
        // pusher?.disconnect();
      }
    },[]);

    useEffect( () => {
      const query = new URLSearchParams(window.location.search);
      if ( query.has('token') === false ){
        state.session === null && history.replace(ROUTE_PATH.HOME);
      }
      else if ( state.session ) {
        refreshChat();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.session] );

    useEffect(() => {
      
      if ( state.message.sentCount === 1 && waitMessageSent === false && hcpsInEp?.doctors?.length <= 0 ) {
        //messageService.addAIMessage(t('defaultMessages.nPThanking'));
        setWaitMessageSent(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.message.sentCount]);

    useEffect(() => {
      if(!showElem) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }

      if(listDoctors?.doctors?.length) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }

      if(state.message?.messages?.length) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[listDoctors?.doctors?.length, showElem ]);

    const openLighbox = ( url: string ) => {
        setLightboxUrl(url);
        setLightboxOpen(true);
    };

   const notifyForFiles = (title: string, description: string) => {
      return toast({
          title,
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
      })
  }


    const sendMessage = async () => {

     let value = messageInp; 
     let files = file ? file : [];
 
      if(!state.message?.questionnaire_completed) {
          const lastMsg = state.message.messages[state.message.messages.length - 1]; 
           if (lastMsg.active_question?.type === "text" && value === "") {
            notifyForFiles("Error", "Please type text");
            return
          }
      }

      if(Boolean(value.replace(/\s/g, '')) || file) {
        setTargetContainer(msgContainerBottomRef);
        setMessageInp('');
        setFile(null);
        setListFilesUrl({});
        msgInput.current?.focus();
        setSendingMessage(true);
        if(!state.message.questionnaire_completed) {
          const messagePush:MessageShortType = createMessage(value, state); 
          const lastMessage = state.message.messages[state.message.messages.length - 1]; 
          const user_id = state.session.episode?.patient?.id; 
          const episode_id = state.session.episode.id; 
          const question_id = lastMessage?.active_question?.id; 
          const token = state.session.token;
          if(lastMessage.active_question?.type === "text" ){
            await messageService.addMessageToState(messagePush); 
          }
          messageService.updateIsQuestionnaire(true); 
          if(user_id && question_id) {
            const currentFile = file ? file : null; 
            await messageService.answerQuestionnaire(user_id, episode_id, +question_id, 0, value, currentFile, token); 
          }
        } else if (files.length > 0) {
          await messageService.sendMessage(messageInp, state.session?.token, state.session?.episode?.patient?.id || 0, state.message.messageMeta.current_page, files );
          setTimeout(() =>  msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 200); 
        } else {
          const messagePush = createMessage(value, state); 
          trigger("client-message", messagePush); 
          await messageService.addMessageToState(messagePush); 
        }
        setSendingMessage(false);
      } 
    }

    const handleRefClick = () => {
      if(file && file?.length >= 5) {
        setOpenTooltip(true);
        setTimeout(() => setOpenTooltip(false), 2000);
        return false;
      }
      fileInpRef.current?.click()
    }

    const deleteChooseFile = (name: string) => {
      if(file?.length === 1) {
        setFile(null);
        setListFilesUrl({});
      } else if(file) {
        let arrFiles = file?.filter(file => file.name !== name);
        setFile(arrFiles);
      }
    }

    const handlerLoadMessages = () => {
      setShowChatHistory(true);
    }

    const observer: any = useRef()
    const lastElementRef = useCallback(node => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && entries.length === 1) {
            handlerLoadMessages();
        } else if (entries[0].isIntersecting) {

      } else {
          setShowChatHistory(false); 
      }
      })
      if (node) {
        observer.current.observe(node); 
      } 
      setIsRenderObserver(true); 
    }, []); 

    const observerFirstElem: any = useRef()
    const firstElementRef = useCallback(node => {
      if (observerFirstElem.current) observerFirstElem.current.disconnect()
      observerFirstElem.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
            setIsFirstElemHide(false); 
            setMessageReceived(false); 
        } else {
          setIsFirstElemHide(true); 
      }
      })
      if (node) {
        observerFirstElem.current.observe(node); 
      } 
    }, [showElem]); 

    const validateLastMessage = (listMessages: MessageDataType[]) => {
      const lastMessage = listMessages[listMessages.length - 1]; 
      if(lastMessage && lastMessage.active_question?.id === 34 && !showMaskedPhoneField) {
        setShowMaskedPhoneField(true); 
      }
  
      if(lastMessage && (!lastMessage.active_question || lastMessage.active_question?.id !== 34) && showMaskedPhoneField) {
        setShowMaskedPhoneField(false); 
      }
    }


    useEffect(() => {
      setListMessagesLength(state.message.messages.length);
      validateLastMessage(state.message.messages); 
       if(initialScroll) {
        setTimeout(() =>  msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 200); 
        setInitialScroll(false); 
       }else if (!state.message.encounter?.questionnaire_completed && listMessagesLenght <= state.message.messages.length) {
        msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" }); 
      } else if (!state.message.encounter?.questionnaire_completed && listMessagesLenght > state.message.messages.length) {
        setTimeout(() =>  msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 200); 
      }  
    }, [state.message.messages.length, initialScroll]); 

    useLayoutEffect(() => {
      if(state.app.clickUploadFile) {
        fileInpRefQuestionnaire.current.click(); 
        appService.clickUploadFile(false); 
      }
    }, [state.app.clickUploadFile]); 

    useEffect(() => {
      if(state.app.clearFile) {
        setFile(null); 
        setListFilesUrl({});
        appService.clearUploadFile(false); 
      }
    }, [state.app.clearFile]); 

    useEffect(() => {
      if(state.message.isQuestionnaire) {
        appService.uploadQuestionnaireFile(file); 
      }
    }, [file]); 

    const handlerType = (e:any) => {
      let typingType = "type"; 
      if(e.keyCode === 8) {
        typingType = "delete"; 
      }
      if(canSendEventType.current) {
          trigger("client-type-message", typingType); 
          canSendEventType.current = false;
          setTimeout(function() {
            canSendEventType.current = true;
          }, 1000);
        } 
    }


    const handleScroll = () => {
      msgContainerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setMessageReceived(false); 
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null); 

    const handleUploadDocument = (file: any) => {
      handlerLoadFile(null, file); 
      setIsUpload(false); 
    }

    const uploadFileOver = (e:any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsUpload(true); 
    }

    const uploadFileLeave = () => { 
        setIsUpload(false); 
    }

    
    return (
        <>
        {isReload && <BannerReloadPage isAbsolute={true}/>}
        {
          lightboxOpen === true &&
          <Lightbox
            mainSrc={lighboxUrl}
            onCloseRequest={() => setLightboxOpen(false)}
          />
        }
         {isDisable && <Box position="absolute" zIndex={100} top="50%" left="50%">
              <Spinner/>
            </Box>} 
        <Container
          backgroundColor="#FFFFFF"
          width="100vw"
          position={"relative"}
          style={{ filter:  `blur(${isDisable ? 4 : 0}px)` }}
          height={Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}
          display="flex"
          flexDirection="column"
          padding={0}
        > 
         {(state.message.messages.length && !state.message.clinic_open) ? <BannerBase isAbsolute={false} text={t('wttooltip.clinic_closed')}/> : ""}  
         
          <Container
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mb="2%"
            mt="1%"
          >
            <Container
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <RLink to={ROUTE_PATH.CHAT_TYPE}>
                <img height='25px' width='90px' alt="all-health" src={`${process.env.PUBLIC_URL}/${t('lang') === 'en' ? 'all-health.svg' : 'all-health-fr.svg'}`} />
              </RLink>
              <Text fontFamily={'Playfair Display'} fontSize="18px" fontWeight="bold" mt="5px" color="#304358">
                {t('chatScreen.headerTitle')}
              </Text> 
            </Container>
            <EmailDisklaimerDialog cancelRef={cancelRef} onClose={onClose} isOpen={isOpen} />

            <QuestionOutlineIcon fontSize="20px" onClick={onOpen} marginRight="20px"/>

            <CloseIcon cursor='pointer' onClick={() => history.push(ROUTE_PATH.CHAT_TYPE)} />
          </Container>
          <Divider borderColor="blackAlpha.500" />
          {
            showChatHistory && state.message.messageMeta?.current_page < state.message.messageMeta?.last_page &&
            <Container onClick={() => {
              if (loadingMore === false && 
                  state.message.messageMeta.current_page < state.message.messageMeta.last_page ) {
                  setFirstMessageId(state.message?.messages[0]?.id);  
                  setLoadingMessages(true);
                  setLoadingMore(true);
                  setShowChatHistory(false); 
                  topPlaceholderRef.current?.scrollIntoView({ behavior: 'smooth' });
                  messageService.loadMoreMessages(state.session?.token, state.message.messageMeta.current_page + 1)
                  .then(() => {
                    setLoadingMore(false);
                    setLoadingMessages(false);
                  });
                  setTargetContainer(msgContainerTopRef);
              } 
            }} backgroundColor='#EFF5F3' display='flex' justifyContent='center'>
              <Link fontFamily={'Mulish'} textDecoration={'underline'} fontSize={'14px'} paddingTop={'7px'} paddingBottom={'11px'}>
                {t('chatScreen.chatHistoryLabel')}
              </Link>
            </Container>}
          <Container flexGrow={1} display="flex" flexDirection="column" overflow="scroll" p={0} pt="5%" pb="1%" ref={messageContainerRef}>
            {!!state.message.messages.length && <div ref={lastElementRef}></div>}
            {!state.message.messages.length && loadingMessages === true && 
              <Box mb="30px">
                <div ref={topPlaceholderRef}></div>
                <PlaceholderMessage key={1} belongsToUser={true} />
                <PlaceholderMessage key={2} belongsToUser={false} />
                <PlaceholderMessage key={3} belongsToUser={true} />
                <PlaceholderMessage key={4} belongsToUser={true} />
                <PlaceholderMessage key={5} belongsToUser={false} />
                <PlaceholderMessage key={6} belongsToUser={true} />
                <PlaceholderMessage key={7} belongsToUser={false}/>
              </Box> }
              {
              <>
               { state.message?.messages.map( ( msg: MessageDataType, idx: number ) => (
                  <Box ref={msg.id === firstMessageId ? msgCurrentRef : state.message?.messages.length - 1 === idx ? firstElementRef : null} key={msg.id ? msg.id : msg.created_at}>
                    <Message isLastMessage={state.message?.messages.length - 1 === idx} onDocClick={openLighbox} message={msg} belongsToUser={msg.author?.id !== null && msg.author?.id === state.session?.episode?.patient?.id} setLoadingMessages={setLoadingMessages} setInitialScroll={setInitialScroll}/>
                  </Box>
                ))} 
     
                 { sendingMessage === true && <PlaceholderMessage belongsToUser={true} /> }
              </>}
             {!state.message.isQuestionnaire && listDoctors?.doctors?.length && <Box display="flex" flexGrow={1} justifyContent="center" alignItems="flex-end">
                {listDoctors?.doctors?.length ? listDoctors?.doctors?.map((doctor: User) => (
                  <Text key={doctor.id} fontFamily={'Mulish'} textAlign="center" fontSize="10pt" color="#A3B2B1">
                    { moment().format(`${t('lang') !== "fr" ?  'hh:mm  A' : 'HH:mm'}`) } - {t('chatScreen.connectedToNP', {doctor: doctor.display_name, role: doctor?.role === "healthcare-provider" ? "" : ", Admin"})}
                  </Text>
                )) : ""}  
              </Box>}

            <div ref={msgContainerBottomRef}></div> 
           {isFirstElemHide && isFirstScroll && <Box position="absolute" justifyContent="center" display="flex" zIndex={10} bottom="50px" w="100%" color="gray">
              <Button onClick={handleScroll} backgroundColor={messageReceived ? "red" :  "blue"} color="white" fontSize="14px" verticalAlign={"center"} textTransform="none" cursor="pointer" h="30px">{messageReceived ? "New message" : "Latest messages"}</Button>
            </Box>}
          </Container>
          {!state.message.isQuestionnaire && file && file.length > 0 &&
            <Container>
              {/* Logic for clear all choosing files */}
{/*               <Flex justify="flex-end">
                <Text
                    fontSize={'xs'}
                    onClick={() => {
                      setFile(null);
                      setListFilesUrl({});
                      fileInpRef.current.value="";
                    }}
                    cursor="pointer"
                    fontFamily={'Mulish'}>
                      Cancel all
                    </Text>
              </Flex> */}
              <Container display="flex">
              <hr />
               {
                file?.map((file: any) =>  file?.type.startsWith('image')
                ? <Flex key={file.name} marginRight="10px" justify="center" width="75px" height={"75px"} position="relative">
                    <Box
                     onClick={()=> deleteChooseFile(file.name)}
                      position="absolute"
                      right="-10px"
                      cursor="pointer">
                          <CloseIcon boxSize="15px"/>
                      </Box>
                    <Image objectFit="contain" onClick={() => openLighbox(file?.data_url)} src={file?.data_url} height={'75px'} padding={'5px'} />
                </Flex>
                : <Flex key={file.name} marginRight="10px"  justify="center" width="75px" height={"75px"} padding={"5px"} position="relative">
                    <Box
                      onClick={()=> deleteChooseFile(file.name)}
                      position="absolute"
                      top="0"
                      right="-5px"
                      cursor="pointer">
                        <CloseIcon boxSize="15px"/>
                      </Box>
                    <Image height="100%" src={text_file} alt="" objectFit="contain"/>
                    <Flex objectFit="contain" display={{base: isLargerThan400 ? "flex" : "none", md: "flex"}} position="absolute" bottom="3px" width="50px" height="30px" backgroundColor="#000" opacity="0.7" justify="center" align="center" direction="column">
                       <Text lineHeight="0.7" fontSize="10px" color="white">{`${file.name.slice(0, 5)}...`}</Text>
                       <Text fontSize="10px" color="white">{`...${file.type.slice(-5)}`}</Text>
                    </Flex>
                </Flex> )
               }
              </Container>
              <Text fontSize={'xs'} fontFamily={'Mulish'}>{t("chatScreen.files_limit")}</Text>
            </Container> }
            {state.message.isQuestionnaire &&  <input
                onChange={(e: any) => handlerLoadFile(e)}
                type={"file"}
                multiple={false}
                ref={fileInpRefQuestionnaire} 
                hidden
                onClick={(e: any) => {
                  e.target.value = "";
                }}
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
              /> }
          {!state.message.isQuestionnaire && !archivedDisable && <Container 
             padding={0} 
             display="flex" 
             border="1px solid #D5DFDE">
            <IconButton
              aria-label="icon"
              borderRadius="none"
              icon={
                <div>
                  <input onChange={(e: any) => handlerLoadFile(e)}
                    type={'file'}
                    multiple={state.message.questionnaire_completed}
                    ref={fileInpRef}
                    hidden
                    onClick={(e: any) => {
                      e.target.value = "";
                    }}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*" />
             <Tooltip isOpen={openTooltip} closeDelay={500} label={t('errors.title_files')} placement='top-start'>   </Tooltip>
             <img onClick={() => fileInpRef.current && handleRefClick()} alt="camera" style={{ height: '16px' }} src={`${process.env.PUBLIC_URL}/camera.svg`}/>
                </div>}
              size="md"
              display="flex"
              backgroundColor={"transparent"}
            />
           {!showMaskedPhoneField && <InputGroup style={{
                borderLeft: "1px solid #D5DFDE"
            }}
                >
             <TextareaAutosize
                  data-private
                  value={messageInp}
                  disabled={state.message.isQuestionnaire || isDisable}
                  onDragOver={uploadFileOver}
                  onChange={ ( e: any ) => setMessageInp(e.target.value) }
                  onKeyUp={handlerType}
                  style={{
                      width: "100%",
                      borderTop: "none",
                      borderBottom: "none",
                      borderRight: "none",
                      fontFamily: 'Mulish',
                      borderRadius: '0',
                      paddingLeft: "1rem",
                      paddingBottom: isLargerThan768 ? '0px' : '25px',
                      paddingTop: isLargerThan768 ? '0px' : '5px',
                      paddingRight: "2.5rem",
                      resize: "none",
                      alignSelf: "center",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                  onKeyDown={(e: any) => {
                      if(e.keyCode === 13 && !e.shiftKey) {
                          sendMessage();
                          e.preventDefault();
                          return false;
                      }

                  }}
                  maxRows={6}
                  ref={msgInput}
                  
                /> 
              <InputRightElement >
                <IconButton padding="12px"
                    disabled={ sendingMessage === true || loadingMessages === true || loadingMore === true || isDisable || state.message.isQuestionnaire }
                    onClick={sendMessage}
                    aria-label="icon"
                    icon={<img alt="send" style={{ height: '16px' }} src={`${process.env.PUBLIC_URL}/send-icon.svg`} />}
                    size="6"
                    display="flex"
                    backgroundColor="transparent"
                    width={"100%"}
                    height={"100%"}
                />
              </InputRightElement>
              {isUpload && isLargerThan768 && <DragUploadFile handlerLeave={uploadFileLeave} handlerUpload={handleUploadDocument}/>}
            </InputGroup>}
            {showMaskedPhoneField && <MaskPhone
                   valueMask={messageInp}
                   setValueMask={setMessageInp}
                   handler={sendMessage}/>} 
           
          </Container>}
        </Container>
      </>
    );
}
