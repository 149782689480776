import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng, 
  getZipCode,
  getDetails,
} from 'use-places-autocomplete';
import { Box, HStack } from '@chakra-ui/layout';
import SearchInput from './searchInput';

type GoogleMapProps = {
  isEstablishment?: boolean;
  name_map: string;
  handler: Function; 
  error?: boolean; 
  label?: string; 
  setLocation: Function; 
  location?: any; 
}

const containerStyle = {
  width: '100%',
  height: '75%',
  marginTop: "15px",
};

const center = {
  lat:  45.424722,
  lng: -75.695000,
};

const options = {
  disableDefaultUI: true,
}; 

const libraries = ["places"];

function GoogleMapComponent(props: GoogleMapProps) {
  const {t, i18n} = useTranslation();
  const [testCenter, setTestCenter] = useState(props.location?.hasOwnProperty("lat") ? props.location : center); 
  const [zoom, setZoom] = useState(5); 
  const [markers, setMarkers] = useState<any>({...props.location}); 
  const [map, setMap] = React.useState(null)
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: "AIzaSyDPPsDRiD-4AgK6YFWgIDH6_GKZrEX1xm0",
    //language: `${i18n.language}`,
    libraries,
  } as any); 

  const mapRef = React.useRef(); 
  const onMapLoad = React.useCallback(({map}) => {
    mapRef.current = map; 
  }, []); 

  const panTo  = React.useCallback(({lat, lng}) => {
     //@ts-ignore
     mapRef.current?.panTo({lat, lng}); 
     //@ts-ignore
     mapRef.current?.setZoom(14); 
     setTestCenter({lat: lat, lng: lng})
     setZoom(10);

  }, []); 

  const handleSetLocation = (value: any) => {
    setMarkers({...value}); 
    props.setLocation({...value}); 
  }

 if(!isLoaded) return <div>"Loading maps..."</div>

  return (
    <>
      <Search 
        error={props.error} 
        isEstablishment={props.isEstablishment}
        panTo={panTo} 
        label={props.label}
        name={props.name_map}
        setMarker={handleSetLocation}
        handler={props.handler}/>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={testCenter}
        zoom={zoom}
        onLoad={onMapLoad}
        options={options}
      >
        {markers.lat && <Marker position={{lat: markers.lat, lng: markers.lng}}/>}
      </GoogleMap>
  </>)
}

const Search = (props:any) => {
  let currentType = props.isEstablishment ? ['establishment'] : ['address']; 
  const {ready, value, clearCache, suggestions: {
    status,
    data,
  }, setValue, clearSuggestions} = usePlacesAutocomplete({
    requestOptions: {   
      types: currentType,
    },
  })
  const handlerSearch = async (address: any) => {
    setValue(address, false); 
    clearSuggestions(); 
    clearCache(); 


    try {
      const results = await getGeocode({address}); 
      const {lat, lng} = await getLatLng(results[0]); 
      props.setMarker({
        lat: lat,
        lng: lng,
      })
     // const res = await getZipCode(address, false)

      const parameter = {
      placeId: results[0].place_id, 
      fields: ["name", "formatted_address", "address_components", "international_phone_number", "formatted_phone_number", "types", "website"]
    }; 
     const result = await getDetails(parameter); 
      props.handler(result); 
      props.panTo({lat: lat, lng: lng})
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (value: any) => {
    setValue(value); 
  }


  const {t} = useTranslation(); 
  return (
    <Box width="100%"  display="flex" flexDirection="column">
     <Box width={{base: "100%"}} >
       <SearchInput 
          mb={{base: '24px', md:'unset'}}
          placeholder={t(`new_register.search`)} 
          noIcon
          defaultValue={props.name}
          error={props.error}
          handleType={handleChange}
          handler={handlerSearch}
          options={data.map(({description}) => description)}
          label={props.label}/>    
    </Box>
   </Box> 
  )
}

export default GoogleMapComponent

