import React, { FC, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/breadcrumb';
import { Container } from '@chakra-ui/layout';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffectOnce, useMedia } from 'react-use';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import { AlertSupport } from './alertSupport';
import { EmailDisklaimerDialog } from './emailDisklaimerDialog';

export type HeaderLogoProps = {
    showLanguage: boolean;
    language?: string;
    changeLanguage?: Function;
    backLink?: string;
};

export const HeaderLogo: FC<HeaderLogoProps> = ({ language, changeLanguage, showLanguage, backLink }: HeaderLogoProps) => {

    const {t} = useTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null)

    const [ multiplier, setMultiplier ] = useState(1);

    const _4k = useMedia('(min-width: 2018px)');
    const laptopLarge = useMedia('(min-width: 1440px)');
    const laptopSmall = useMedia('(min-width: 1024px)');
    const tablet = useMedia('(min-width: 768px)');
    const [isLargerThan760] = useMediaQuery("(min-width: 760px)")
    
    useEffectOnce(() => {
        if ( _4k === true ) {
            setMultiplier(10);
        }
        else if ( laptopLarge === true ) {
            setMultiplier(5);
        }
        else if ( laptopSmall === true || tablet === true ) {
            setMultiplier(3.5);
        }
        else {
            setMultiplier(2.5);
        }
    });
    
    let logo: any = <img alt="all-health" width={`${multiplier * 15 * window.devicePixelRatio}rem`} height={`${multiplier * 15 * window.devicePixelRatio}px`} style={{ cursor: 'pointer' }} src={`${process.env.PUBLIC_URL}/${t('lang') === 'en' ? 'all-health.svg' : 'all-health-fr.svg'}`} />;
    if ( backLink ) {
        logo = <Link to={backLink}>{logo}</Link>
    }


    return (
        <Container maxW='auto' display="flex" width="100%" justifyContent="space-between" p={0}>
            <EmailDisklaimerDialog cancelRef={cancelRef} onClose={onClose} isOpen={isOpen} />
            { logo }
            {
            showLanguage === true &&    
            <Breadcrumb alignSelf="end">
              <QuestionOutlineIcon fontSize="18px" _hover={{color: '#454343'}} onClick={onOpen} cursor="pointer" marginRight="20px"/>
                <BreadcrumbItem isCurrentPage={language === 'en'}>
                    <BreadcrumbLink href="#" fontFamily={'Mulish'} onClick={ () => changeLanguage && changeLanguage('en') }> { t('loginScreen.linkEnglish') }</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage={language === 'fr'}>
                    <BreadcrumbLink href="#" fontFamily={'Mulish'} onClick={ () => changeLanguage && changeLanguage('fr') }>{ t('loginScreen.linkFrench') }</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>}
        </Container>
    );
}
