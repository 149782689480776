const Input = {
    // The styles all Input have in common
    baseStyle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: 'green',
    },
    // Two sizes: sm and md
    // sizes: {
    //   sm: {
    //     fontSize: 6 * window.devicePixelRatio,
    //     padding: 1 * window.devicePixelRatio,
    //   },
    //   md: {
    //     fontSize: 8 * window.devicePixelRatio,
    //     padding: 2 * window.devicePixelRatio,
    //   },
    // },
    // Two variants: outline and solid
    variants: {
      outline: {
        border: "2px solid red",
      },
      solid: {
        bg: "green.500",
        color: "white",
      },
    },
    // The default size and variant values
    defaultProps: {
      size: "md",
      variant: "outline",
      fontFamily: 'Mulish'
    },
  };

  export default Input;