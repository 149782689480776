import { SessionActions, SessionService } from "./session";

export interface ActionInterface {
    type: string;
    payload?: any;
}

export const handleError = ( 
    e: any, 
    description: string,
    toast: any, 
    dispatch: any,
    defaultTitle?: string
) => {
    if ( e.response?.status === 401 || e.response?.status === 409) {
        window.location.reload(); 
    }
    
    let title: string = defaultTitle || 'Error';
    // let description: string = 'An unexpected errro occurred!';
    if ( e.response?.data?.message && defaultTitle === undefined ) {
        title = e.response?.data?.message;
    }
    // if (e.response?.data?.errors ) {
    //     description = Object.values(e.response?.data?.errors)[0]+'';
    // }
    toast({
        title,
        description: description, 
        status: "error",
        duration: 3000,
        isClosable: true,
    });
}

export const fileExtension = function( url: string ) {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
}

export const distinctArray = ( array: Array<any> ) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if(!map.has(item.id)){
            map.set(item.id, true);    // set any value to Map
            result.push({
                ...item
            });
        }
    }
    return result;
}