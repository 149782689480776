const Button = {
    // The styles all button have in common
    baseStyle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "Mulish",
    },
    // Two sizes: sm and md
    // sizes: {
    //   sm: {
    //     fontSize: 6 * window.devicePixelRatio,
    //     padding: 1 * window.devicePixelRatio,
    //   },
    //   md: {
    //     fontSize: 8 * window.devicePixelRatio,
    //     padding: 2 * window.devicePixelRatio,
    //   },
    // },
    // Two variants: outline and solid
    variants: {
      outline: {
        border: "2px solid",
        borderColor: "#FFDB00",
        color: '#304358',
        borderRadius: '10pt',
        fontSize: '13px'
      },
      solid: {
        bg: "#FFDB00",
        color: "#304358",
        borderRadius: "10pt"
      },
    },
    // The default size and variant values
    defaultProps: {
      size: "md",
      variant: "solid",
    },
  };

  export default Button;