import { Box, Center, Divider, Flex, Text } from '@chakra-ui/layout'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '../../components/textField'
import RegisterLaoyut from './RegisterLaoyut'
import {Formik} from 'formik'
import * as Yup from 'yup';
import { Stack, HStack, VStack, PinInput, PinInputField, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import PinFields from '../../components/pinFields'
import RadioFields from '../../components/radioFields'
import InputDropdown from '../../components/inputDropdown'
import SelectComponent from '../../components/select'
import PasswordField from '../../components/passwordField'
import { handlerLocaleStorage } from '../../utils/helpers'
import { useHistory } from 'react-router'
import { RegisterContext } from '../../app/navigation'
import CheckboxField from '../../components/checkboxField'
import TextAreaField from '../../components/textAreaField'
import { useRegisterFlowContext } from '..'



function Step3_3(props: any) {

  const formRef = useRef<any>(); 
  const history = useHistory();
  const {t} = useTranslation();

  const [isHigerThan760] = useMediaQuery("(min-heigth: 700px)");
  const {setIsHideContent, isHideContent} = useRegisterFlowContext();

   const ValidationSchema = Yup.object().shape({ 
      has_past_medical_history: Yup.boolean()
      .required('Field is required'),
      has_past_surgical_history: Yup.boolean()
      .required('Field is required'),
      past_medical_history: Yup.string()
      .max(200),
      past_surgical_history: Yup.string()
      .max(200)
    });
 
    let registerValues = JSON.parse(sessionStorage.getItem('register') || '{}');
    const initialValuesBase = {
        "has_past_medical_history": "",
        "past_medical_history": "",
        "has_past_surgical_history": "",
        "past_surgical_history": "",
    };

    let initialValues = registerValues['step_7'] ? registerValues['step_7']  : initialValuesBase; 
    let has_past_medical_history = initialValues.has_past_medical_history;
    let has_past_surgical_history = initialValues.has_past_surgical_history;


    const slug = useContext(RegisterContext); 

    const optionsRadio = [t(`registerForm.fdSection.yes`), t(`registerForm.fdSection.no`)]; 
    const getPreviusValue = (value: any, options: any) => {
      if(value === true) {
        return options[0]
      }
      if(value === false) {
        return options[1]
      }
      return ""; 
    }

    const setValueHide = (value: any) => {
      if(value === "") {
        return true
      }
      if (value === true) {
        return true
      }
      return false; 
    }


    const [radioValue, setRadioValue] = useState(getPreviusValue(has_past_medical_history, optionsRadio)); 
    const [radioMeds, setRadioMeds] = useState(getPreviusValue(has_past_surgical_history , optionsRadio)); 
    const [isHideSection, setIsHideSection] = useState(setValueHide( has_past_medical_history)); 
    const [isHideMedicale, setIsHideMedicale] = useState(setValueHide( has_past_surgical_history)); 

    const handler = (value:any) => {
        if(value === optionsRadio[0]) {
            setIsHideSection(true); 
            formRef?.current?.setFieldValue(`has_past_medical_history`, true); 
        } 
        if(value === optionsRadio[1]){
            setIsHideSection(false); 
            formRef?.current?.setFieldValue(`has_past_medical_history`, false); 
        }
    }

    const handlerMedicale = (value: any) => {
        if(value === optionsRadio[0]) {
            setIsHideMedicale(true); 
            formRef?.current?.setFieldValue(`has_past_surgical_history`, true); 
        } 
        if(value === optionsRadio[1]){
            setIsHideMedicale(false); 
            formRef?.current?.setFieldValue(`has_past_surgical_history`, false); 
        }
        
    }

    const saveData = async () => {
      formRef?.current?.handleSubmit(); 
    }

    useEffect(() => {
      if(!setValueHide(has_past_medical_history) && !setValueHide(has_past_medical_history)) {
        setIsHideContent(true);
      } else {
        setIsHideContent(false);
      }
    }, []);

    useEffect(() => {

      if(!isHideContent && !isHideMedicale && !isHideSection) {
        setIsHideContent(true); 
      } else if((isHideContent && isHideMedicale) || (isHideContent && isHideSection)) {
        setIsHideContent(false); 
      }
    }, [isHideMedicale, isHideSection]); 
    
    return (
       <RegisterLaoyut step={7} handlerSubmit={saveData} goBack={() => history.push(`/register/${6}${slug}`)}>
           <Box pt={{base: '40px'}}>
             <Text color="#40B89C" fontWeight="700">{t(`new_register.step_3_medical_profile`)}</Text>
             <Text fontWeight="700" fontSize={{base: "32px", "2xl": "40px"}}  color="#304358">{t('new_register.current_step', {step1: 3, step2: 3})} {t(`new_register.medical_history`)}
             </Text>
           </Box>
  
           <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              handlerLocaleStorage(values, 'step_7'); 
              history.push(`/register/${8}${slug}`) 

            }} >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
          <form onSubmit={handleSubmit}>

            <VStack spacing="20px" mt={{base:"32px", '2xl': "48px" }} width="100%" mb={{base: "52px"}}>
                <Flex w="100%">
                  <Text color="#304358" fontSize="20px" fontWeight="700">
                      {t('new_register.step_3_health_issues')}
                  </Text>
                  </Flex>
                <Flex w="100%">
                 <RadioFields 
                    label={t('registerForm.healthIssuesQuestion')}
                    isHelperText
                    options={optionsRadio} 
                    error={errors.has_past_medical_history && touched.has_past_medical_history}
                    name="has_past_medical_history" 
                    handler={(value:any)=> handler(value)}
                    defaultValue={radioValue}/>
              </Flex>

            {isHideSection && <HStack width={{base: "100%"}} display="flex"  justifyContent="flex-start"  spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}}>
                 <Flex w={{base: "100%", lg: "50%"}}>
                 <TextAreaField 
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t('new_register.health_issues')}
                  onChange={handleChange}
                  value={values.past_medical_history}
                  name="past_medical_history"
                  label={t('new_register.list_exisisting_health_issues')}/>
                 </Flex>
              </HStack>}

           
                 <Flex w="100%" >
                  <Text color="#304358" fontSize="20px" fontWeight="700">
                      {t('new_register.step_3_surgeries_medical_procedures')}
                    </Text>
                 </Flex>
                 <Flex w="100%">
                  <RadioFields 
                      label={t('registerForm.proceduresQuestion')}
                      isHelperText
                      options={optionsRadio} 
                      error={errors.has_past_surgical_history && touched.has_past_surgical_history}
                      name="has_past_surgical_history" 
                      handler={(value:any)=> handlerMedicale(value)}
                      defaultValue={radioMeds}/>
                 </Flex>   
      

         { isHideMedicale &&  <HStack width={{base: "100%"}} display="flex"  justifyContent="flex-start"  spacing={{md: "20px", lg: "34px"}} wrap={{base: "wrap", md: "nowrap"}} >
                 <Flex w={{base: "100%", lg: "50%"}}>
                 <TextAreaField 
                  mb={{base: '24px', md:'unset'}}
                  placeholder={t('new_register.text_input')}
                  onChange={handleChange}
                  value={values.past_surgical_history}
                  name="past_surgical_history"
                  label={t('new_register.label_medication_list')} />
                 </Flex>
              </HStack>}
              
             </VStack> 

         </form>
         )}
        </Formik>  
       </RegisterLaoyut>

    )
}

export default Step3_3