import { MessageType } from "./message.type";
import { MessageActions } from "./message.action";
import { ActionInterface } from "../utils";
import { MessageDataType } from "../../dto/message-data.dto";
import { messageInitialState } from "./message.state";
import moment from "moment";
import { MessageShortType } from "../../dto";

export const messageReducer = (state: MessageType, action: ActionInterface) => {
  switch (action.type) {
    case MessageActions.GET_MESSAGES: {
      return getMessageHandler(state, action);
    }
    case MessageActions.LOAD_MORE_MSG: {
      return loadMoreMsgHandler(state, action); 
    }
    case MessageActions.CONNECTED_TO_DOC:
      return { ...state, connectedOn: action.payload };
    case MessageActions.SENT: {
      const msg: MessageDataType = action.payload;
      return { ...state, messages: [...state.messages, msg] };
    }
    case MessageActions.REMOVE_MESSAGE: {
      return removeMessageHandler(state, action); 
    }
    case MessageActions.ADD_AI_MESSAGE: {
      return addAiMsgHandler(state, action); 
    }
    case MessageActions.DOCTOR_MESSAGE:
      return { ...state, messages: [...state?.messages, action.payload] };
    case MessageActions.SENT_COUNT:
      return { ...state, sentCount: action.payload };
    case MessageActions.INCREMENT_SENT_COUNT:
      return { ...state, sentCount: state.sentCount + 1 };
    case MessageActions.CLEAR_ALL:
      return messageInitialState;
    case MessageActions.UPDATE_QUESTIONNAIRE: 
      return { ...state, isQuestionnaire: action.payload };   
    case MessageActions.SET_ENCOUNTER: 
      return { ...state, encounter: action.payload };   
    case MessageActions.QUESTIONNIRE_COMPLETED: 
      return { ...state, questionnaire_completed: action.payload };     
    default:
      return state;
  }
};


const getMessageHandler = (state: MessageType, action: ActionInterface) => {
  const questionnaire_completed = action.payload?.data?.encounter ? action.payload?.data?.encounter.questionnaire_completed : false; 
  const isQuestionnaireStatus = (action.payload.data.active_question && action.payload.data.active_question.type !== "text") ? true : false; 

  if (state.messageMeta?.current_page >= action.payload.meta.current_page) {
    const lengthArr = state?.messages.length - 1;
    const lengthData = action.payload.data?.messages?.length - 1;
    const condition =
      new Date(state?.messages[lengthArr].created_at).getTime() ===
      new Date(action.payload?.data?.messages[lengthData]?.created_at).getTime();
    if (condition) {
      return state;
    } else {
      const index = action.payload.data?.messages.findIndex(
        (item: any) =>
          new Date(item.created_at).getTime() ===
          new Date(state.messages[lengthArr]?.created_at).getTime()
      );
      if (index !== -1) {
        const newMessages = action.payload.data?.messages.slice(index + 1);
        const newList = [...state?.messages, ...newMessages].sort(
          (a: MessageDataType, b: MessageDataType) =>
            moment(a.created_at).diff(moment(b.created_at))
        );
        return {
          ...state,
          messages: newList,
          messageMeta: action.payload.meta,
          isQuestionnaire: isQuestionnaireStatus,
          encounter: action.payload?.data?.encounter, 
          questionnaire_completed, 
          clinic_open: action.payload.data?.clinic_open, 
        };
      }
    }
  }
  // const messages = state?.messages.filter( m => !m.author );
  const newMessages = [...action.payload.data?.messages].sort(
    (a: MessageDataType, b: MessageDataType) =>
      moment(a.created_at).diff(moment(b.created_at))
  );

  if(action.payload.data?.active_question
    ){
      //const text = action.payload.data?.active_question;
      const message: MessageDataType = {
        author: {
          display_name: "Virtual Assistant", 
          first_name: "Virtual", 
          id: null, 
        }, 
        contents: "questionnaire",
        created_at: new Date().toISOString(),
        sender: "system",
        active_question: action.payload.data?.active_question
      };
      newMessages.push(message); 
  }

  return {
    ...state,
    messages: newMessages,
    messageMeta: action.payload.meta,
    isQuestionnaire: isQuestionnaireStatus,
    encounter: action.payload?.data?.encounter, 
    questionnaire_completed, 
    clinic_open: action.payload.data?.clinic_open, 
  };
}

const loadMoreMsgHandler = (state:MessageType, action: ActionInterface) => {
  if (state.messageMeta?.current_page >= action.payload.meta.current_page) {
    return state;
  }
  const newMessages = [...action.payload.data, ...state.messages].sort(
    (a: MessageDataType, b: MessageDataType) =>
      moment(a.created_at).diff(moment(b.created_at))
  );
  return {
    ...state,
    messages: newMessages,
    messageMeta: action.payload.meta,
  };
}

const removeMessageHandler = (state: MessageType, action: ActionInterface) => {
  const listMessages = state.messages.slice(0, state.messages.length - 2); 
  const lastMessage = listMessages[listMessages.length - 1]; 
  listMessages[listMessages.length - 1].active_question = action.payload; 
  const isQuestionnaireStatus = (lastMessage?.active_question && lastMessage?.active_question?.type !== "text") ? true : false; 
  return { ...state, messages: [...listMessages], isQuestionnaire: isQuestionnaireStatus, }; 
}

const addAiMsgHandler  = (state: MessageType, action: ActionInterface) => {
  const message: MessageDataType = {
    contents: action.payload.contents,
    created_at: new Date().toISOString(),
  };
  return { ...state, messages: [...state?.messages, message] };
}
